import * as React from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs, { Dayjs } from 'dayjs'
import ApiService from '../../../services/api.service'

export default function ProjectBidDatePicker({
    project,
    onChange,
}: {
    project: any
    onChange: (value: any) => void
}) {
    const [value, setValue] = React.useState<Dayjs | null>(
        project.bid_date == null ? project.bid_date : dayjs(project.bid_date),
    )

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label="Bid Date"
                    value={value}
                    defaultValue={dayjs(new Date(project.bid_date).toLocaleDateString())}
                    sx={{
                        width: '100%',
                        textAlign: 'left',
                    }}
                    onChange={async (newValue) => {
                        setValue(newValue)
                        if (newValue !== null) {
                            await ApiService.updateProject({
                                id: project.id,
                                name: project.name,
                                address: null,
                                bidDate: newValue.toDate().toLocaleDateString(),
                            })
                            onChange(newValue.toDate().toLocaleDateString())
                        }
                    }}
                />
            </LocalizationProvider>
        </>
    )
}
