import React from 'react'
import { Config } from '../../../../config/config'
import { Button } from '@mui/material'
import { SalesforceUserInfoProps } from './SalesforceUserInfoProps'

function UnlinkSalesforceAccountButton(
    props: SalesforceUserInfoProps = {
        subjectId: undefined,
    },
) {
    return (
        <Button
            variant={'outlined'}
            color={'error'}
            onClick={() => {
                window.localStorage.removeItem('has_sf_integration')
                if (props.subjectId) {
                    window.location.href = `${Config.ApiUrl}/api/salesforce/logout?sub=${props.subjectId}&redirect_uri=${window.location.origin + window.location.pathname}`
                } else {
                    window.location.href = `${Config.ApiUrl}/api/salesforce/logout?redirect_uri=${window.location.origin + window.location.pathname}`
                }
            }}
        >
            Unlink Account
        </Button>
    )
}

export default UnlinkSalesforceAccountButton
