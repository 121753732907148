import * as React from 'react'
import { useEffect } from 'react'
import Paper from '@mui/material/Paper'
import { GridColDef, GridToolbar } from '@mui/x-data-grid'
import { DataGridPro } from '@mui/x-data-grid-pro'
import ApiService from '../../../services/api.service'
import { Box, Divider, Typography } from '@mui/material'

export default function ListSearchFilters() {
    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            minWidth: 50,
            flex: 1,
        },
        {
            field: 'name',
            headerName: 'Name',
            minWidth: 50,
            flex: 1,
        },
    ]

    const [rows, setRows] = React.useState<any[]>([])
    const [cols] = React.useState<GridColDef[]>(columns)

    useEffect(() => {
        async function load() {
            const result = await ApiService.getAllSearchFilters()
            if (result.status === 200) {
                const rowItems = result.data
                setRows(rowItems)
                Object.keys(rowItems[0]).forEach((item) => {
                    const existing = cols.map((item) => item.field)
                    if (existing.indexOf(item) === -1) {
                        cols.push({
                            field: item.toString(),
                            headerName: item.toString(),
                            minWidth: 50,
                            flex: 1,
                        })
                    }
                })
            }
        }

        load()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <Box sx={{ minHeight: 250, mb: 2 }}>
            <Typography color="textPrimary" gutterBottom variant="h4" textAlign={'left'}>
                Search Filters
            </Typography>
            <Divider sx={{ width: '100%', mb: 2 }}></Divider>
            {rows.length > 0 && (
                <Paper sx={{ width: '100%', overflow: 'hidden', marginBottom: 4 }}>
                    <DataGridPro
                        rows={rows}
                        columns={cols}
                        density={'compact'}
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                            },
                        }}
                        pagination
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 5 },
                            },
                        }}
                        pageSizeOptions={[5, 250, 500]}
                    />
                </Paper>
            )}
        </Box>
    )
}
