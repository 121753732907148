import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { Box, Button, Grid, Paper, TextField, Typography } from '@mui/material'
import ApiService from '../../services/api.service'
import { DefaultFormProps } from './DefaultFormProps'

export default function CreateOrganizationForm(props: DefaultFormProps) {
    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('name is required')
            .min(6, 'Name must be at least 6 characters')
            .max(24, 'Name must not exceed 24 characters'),

        // acceptTerms: Yup.bool().oneOf([true], "Accept Terms is required")
    })

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
    })

    const [disabled, setDisabled] = useState(false)

    const onSubmit = (data: any) => {
        setDisabled(true)
        console.log(JSON.stringify(data, null, 2))
        ApiService.createOrganization(data).then((res) => {
            console.log(res.data)
            props.onUpdate()
            setDisabled(false)
            window.location.reload()
        })
    }

    function onKeyPress(e: any) {
        if (e.keyCode === 13) {
            handleSubmit(onSubmit)
        }
    }
    return (
        <Paper>
            <Box px={2} py={2} mb={2}>
                <Typography variant="h6" align="center" margin="dense">
                    Create Organization
                </Typography>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={1} maxWidth={500}>
                        <Grid item xs={12} sm={12} md={12}>
                            <TextField
                                id="name"
                                type="text"
                                label="Name"
                                fullWidth
                                margin="dense"
                                disabled={disabled}
                                autoComplete={'off'}
                                {...register('name')}
                                error={errors.name ? true : false}
                                onKeyDown={onKeyPress}
                            />
                            <Typography variant="inherit" color="textSecondary">
                                {errors.name?.message}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Box mt={3} maxWidth={500}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            disabled={disabled}
                        >
                            Create
                        </Button>
                    </Box>
                </form>
            </Box>
        </Paper>
    )
}
