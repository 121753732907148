import React, { useEffect, useRef, useState } from 'react'
import { Alert, Box, Button, Container, TextField, Typography } from '@mui/material'
import BidDatePicker from '../Edit/BidDatePicker'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import ApiService from '../../../services/api.service'
import { useNavigate } from 'react-router-dom'
import { CreateProjectRequest } from './CreateProjectRequest'
import AddressAutocompleteClass, { AddressAutocompleteClassState } from './AddressAutoComplete'
import { AddressAutocompleteValue } from 'mui-address-autocomplete'
import BreadcrumbsBackButton from '../../Shared/BreadcrumbsBackButton'

const CreateProjectForm: React.FC = () => {
    const navigate = useNavigate()
    const nameInputRef = useRef<HTMLInputElement>(null)

    const [project, setProject] = useState<CreateProjectRequest>({
        name: '',
        bidDate: null,
        address: null,
        addressComponents: null,
    })
    const [bidDateError, setBidDateError] = useState<string | null>(null)
    const [addressError, setAddressError] = useState<string | null>(null)

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target
        setProject((prevProject) => ({
            ...prevProject,
            [name]: value,
        }))
    }

    const handleDateChange = (date: any | null) => {
        setProject((prevProject) => ({
            ...prevProject,
            bidDate: date,
        }))
        console.log('handle date change', date)
        if (date !== null) {
            setBidDateError(null)
        }
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        if (project.bidDate === null) {
            setBidDateError('Bid date is required')
            return
        }
        ApiService.createProject(project).then((x) => {
            console.log(x)
            navigate(`/projects/${x.data.id}/edit`)
        })
    }
    const handleAddressChange = (address: AddressAutocompleteValue | null) => {
        setProject((prevProject) => ({
            ...prevProject,
            address: address?.description,
            addressComponents: address,
        }))
        if (address !== null) {
            setAddressError(null)
        }
    }

    useEffect(() => {
        if (nameInputRef.current) {
            nameInputRef.current.focus()
        }
    }, [])

    return (
        <Container>
            <Typography variant="h5" component="h1" gutterBottom>
                Create a New Project
            </Typography>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
                <TextField
                    label="Project Name"
                    name="name"
                    value={project.name}
                    onChange={handleChange}
                    fullWidth
                    required
                    inputRef={nameInputRef}
                    sx={{ mb: 2 }}
                />
                <BidDatePicker bidDate={project?.bidDate} onChange={handleDateChange} />
                {bidDateError && (
                    <Alert variant="standard" color="error" sx={{ mb: 2 }}>
                        {bidDateError}
                    </Alert>
                )}
                <AddressAutocompleteClass onAddressChange={handleAddressChange} />
                {addressError && (
                    <Alert variant="standard" color="error" sx={{ mb: 2 }}>
                        {addressError}
                    </Alert>
                )}
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ mt: 2, mb: 2 }}
                >
                    Create Project
                </Button>
            </Box>
        </Container>
    )
}

export default withAuthenticationRequired(CreateProjectForm)
