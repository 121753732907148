import React, { Component } from 'react'
import { InputAdornment, TextField } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'

class EditableTextField extends Component<any, any> {
    private readonly initialValue: string = ''
    private readonly debug: boolean = false
    private readonly label: string = ''

    constructor(props: any) {
        super(props)
        this.handleMouseOver = this.handleMouseOver.bind(this)
        this.handleMouseOut = this.handleMouseOut.bind(this)
        this.handleClick = this.handleClick.bind(this)
        this.onKeyDown = this.onKeyDown.bind(this)
        this.initialValue = props.value
        this.debug = props?.debug ?? false
        this.label = props?.label ?? ''
        this.state = {
            value: props.value,
            editMode: false,
            mouseOver: false,
        }
    }

    handleChange = (event: any) => {
        this.setState({ [event.target.name]: event.target.value })
    }

    onKeyDown = (event: any) => {
        if (event.keyCode === 13) {
            this.setState({
                editMode: false,
                mouseOver: false,
            })
            this.props.onChange(event.target.value)
            // put the login here
        }
    }

    handleMouseOver = (event: any) => {
        if (!this.state.mouseOver) {
            this.setState({ mouseOver: true })
        }
    }

    handleMouseOut = (event: any) => {
        // The problem is here!!!
        if (this.state.mouseOver) {
            this.setState({ mouseOver: false })
        }
    }

    handleClick = () => {
        this.setState({
            editMode: true,
            mouseOver: false,
        })
    }

    render() {
        const { value, debug } = this.props

        return (
            <div>
                <TextField
                    name="val"
                    fullWidth
                    variant={'standard'}
                    defaultValue={value}
                    margin="normal"
                    error={this.state.value === '' && this.state.value !== this.initialValue}
                    onChange={this.handleChange}
                    disabled={!this.state.editMode}
                    onMouseEnter={this.handleMouseOver}
                    onMouseLeave={this.handleMouseOut}
                    onKeyDown={this.onKeyDown}
                    onClick={this.handleClick}
                    label={this.label}
                    InputProps={{
                        // classes: {
                        //     disabled: classes.disabled,
                        // },
                        endAdornment: this.state.mouseOver ? (
                            <InputAdornment position="end" sx={{ mb: 2 }}>
                                <IconButton onClick={this.handleClick}>
                                    <EditIcon />
                                </IconButton>
                            </InputAdornment>
                        ) : (
                            ''
                        ),
                    }}
                />

                {debug && (
                    <>
                        <div
                            style={{
                                textAlign: 'left',
                                color: 'red',
                                fontSize: '16px',
                                width: '100%',
                                backgroundColor: '#fdfdd5',
                                padding: '5px',
                                lineHeight: '25px',
                            }}
                        >
                            <ul>
                                <li>Value: {JSON.stringify(this.state.value)}</li>
                                <li>editMode: {JSON.stringify(this.state.editMode)}</li>
                                <li>mouseOver: {JSON.stringify(this.state.mouseOver)}</li>
                            </ul>
                        </div>
                    </>
                )}
            </div>
        )
    }
}

export default EditableTextField
