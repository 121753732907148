import * as React from 'react'
import Container from '@mui/material/Container'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import CreateProjectForm from './CreateProjectForm'
import BreadcrumbsBackButton from '../../Shared/BreadcrumbsBackButton'

function ProjectsCreate() {
    return (
        <>
            <BreadcrumbsBackButton
                currentPageText={'Create New'}
                linkText={'Projects'}
                navigateTo={'/projects'}
            />
            <Container sx={{ pt: 2 }} maxWidth={'md'}>
                <CreateProjectForm></CreateProjectForm>
            </Container>
        </>
    )
}

export default withAuthenticationRequired(ProjectsCreate)
