import React from 'react'
import { Box } from '@mui/material'
import Button from '@mui/material/Button/Button'

function LogoutButton() {
    return (
        <Box sx={{ minWidth: 275, marginBottom: 2 }}>
            <Button fullWidth href={'/logout'} variant="contained" color={'error'}>
                Logout
            </Button>
        </Box>
    )
}

export default LogoutButton
