import React from 'react'
import { Box, Button, Container, Typography } from '@mui/material'

function IntegrationsPage() {
    return (
        <Box sx={{ mt: 2 }}>
            <Typography variant="h4" component="div" sx={{ flexGrow: 1 }}>
                Integrations
            </Typography>
            <Container maxWidth="sm" sx={{ py: '80px' }}>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={function () {
                        console.log('clicked salesforce...')
                    }}
                >
                    Link Salesforce
                </Button>
            </Container>
        </Box>
    )
}

export default IntegrationsPage
