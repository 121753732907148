import React from 'react'
import RefreshSalesforceLoginButton from './Buttons/RefreshSalesforceLoginButton'
import UnlinkSalesforceAccountButton from './Buttons/UnlinkSalesforceAccountButton'
import Stack from '@mui/material/Stack'
import { SalesforceUserInfoProps } from './Buttons/SalesforceUserInfoProps'
import ManageSalesforceIntegrationNavigateButton from './Buttons/ManageSalesforceIntegrationNavigateButton'

export function SalesforceLoginActions(
    props: SalesforceUserInfoProps = {
        subjectId: undefined,
        showManageIntegration: false,
    },
) {
    return (
        <Stack direction="row" spacing={2} mt={2}>
            {props.showManageIntegration && <ManageSalesforceIntegrationNavigateButton />}
            <RefreshSalesforceLoginButton subjectId={props?.subjectId} />
            <UnlinkSalesforceAccountButton subjectId={props?.subjectId} />
        </Stack>
    )
}
