import React, { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import LogoutButton from './LogoutButton'
import ListOrganizationProcesses from '../Organization/ListOrganizationProcesses/ListOrganizationProcesses'
import ApiService from '../../services/api.service'
import { AccountUsage } from '../../services/api/models/account-usage'
import LinearProgressWithLabel from '../Dashboard/LinearProgressWithLabel'
import { Account } from '../../services/api/models/account'
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react'
import SalesforceLoginForm from './SalesforceLoginForm'
import ResetPasswordButton from './ResetPasswordButton'
import { Square } from '../Shared/Layouts/Square'
import { SquareTitle } from '../Shared/Layouts/SquareTitle'
import Loading from '../../utils/Loading'
import Pricing from '../Examples/landing-page/components/Pricing'

function MyAccountPage() {
    const [accountData, setAccountData] = useState<Account>()
    const [accountUsage, setAccountUsage] = useState<AccountUsage>()

    const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0()

    useEffect(() => {
        async function login() {
            if (isLoading) {
                return
            }
            if (isAuthenticated) {
                let token = await getAccessTokenSilently()
                ApiService.saveToken(token)
            }
            const account = await ApiService.getAccount()
            if (account.status === 200) {
                setAccountData(account.data)
            }
            let accountUsageResponse = await ApiService.getAccountUsage()
            setAccountUsage(accountUsageResponse.data)
        }

        login()

        // eslint-disable-next-line
    }, [])

    return (
        <Box
            style={{
                flex: 1,
                paddingTop: '20px',
                margin: '20px',
            }}
        >
            {isLoading ? (
                <>
                    <Loading />
                </>
            ) : (
                <>
                    <Grid container rowSpacing={4} columnSpacing={4}>
                        <Grid item xs={12} md={6}>
                            <Square elevation={3} sx={{ textAlign: 'left' }}>
                                <SquareTitle>My Account</SquareTitle>
                                {accountData ? (
                                    <>
                                        <Box sx={{ mb: 2 }}>
                                            <Typography variant="body1">
                                                <b>Email</b>: {accountData.user.email}
                                            </Typography>
                                            <Typography variant="body1">
                                                <b>Organization</b>: {accountData.organization.name}
                                            </Typography>
                                            <Typography variant="body1">
                                                <b>Role</b>: {accountData.user.authority}
                                            </Typography>
                                        </Box>

                                        <Box sx={{ mb: 2 }}>
                                            <ResetPasswordButton />
                                        </Box>
                                        <LogoutButton />
                                    </>
                                ) : (
                                    <>
                                        <Loading></Loading>
                                    </>
                                )}
                            </Square>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Square elevation={3} sx={{ textAlign: 'left' }}>
                                <SquareTitle>Integrations</SquareTitle>
                                {accountData ? (
                                    <>
                                        <SalesforceLoginForm></SalesforceLoginForm>
                                    </>
                                ) : (
                                    <>
                                        <Loading></Loading>
                                    </>
                                )}
                            </Square>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Square elevation={3} sx={{ textAlign: 'left' }}>
                                <SquareTitle>Manage Subscription</SquareTitle>
                                <Pricing />
                            </Square>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Square elevation={3} sx={{ textAlign: 'left' }}>
                                <SquareTitle>Usage</SquareTitle>
                                {accountUsage ? (
                                    <>
                                        <LinearProgressWithLabel
                                            value={Number.parseFloat(
                                                (
                                                    (accountUsage.mb_used /
                                                        accountUsage.mb_allowed) *
                                                    100
                                                ).toFixed(2),
                                            )}
                                            text={`${accountUsage.mb_used.toFixed(2)} MB / ${accountUsage.mb_allowed.toFixed(0)} MB`}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <Loading></Loading>
                                    </>
                                )}
                            </Square>
                        </Grid>
                        <Grid item xs={12}>
                            <Square elevation={3} sx={{ textAlign: 'left' }}>
                                <SquareTitle>Activities</SquareTitle>
                                {accountUsage ? (
                                    <>
                                        <ListOrganizationProcesses></ListOrganizationProcesses>
                                    </>
                                ) : (
                                    <>
                                        <Loading></Loading>
                                    </>
                                )}
                            </Square>
                        </Grid>
                    </Grid>
                </>
            )}
        </Box>
    )
}

export default withAuthenticationRequired(MyAccountPage)
