import http from '../http-common'

class UploadFilesService {
    updateHeaders() {
        const token = localStorage.getItem('token')
        http.defaults.headers.common = {
            Authorization: `Bearer ${token}`,
            'Content-Type': `application/json`,
        }
    }

    upload(file: any, projectId: string, onUploadProgress: any) {
        console.log(`uploading file to project: ${projectId}`)
        this.updateHeaders()
        let formData = new FormData()
        formData.append('file', file)
        return http.post(`/api/v1/files/upload?projectId=${projectId}`, formData, {
            headers: {
                'content-type': 'multipart/form-data',
            },
            onUploadProgress,
        })
    }

    getFiles() {
        this.updateHeaders()
        return []
        // return http.get("/api/v1/files");
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new UploadFilesService()
