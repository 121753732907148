import * as React from 'react'
import { useEffect, useState } from 'react'
import Paper from '@mui/material/Paper'
import { GridColDef, GridFilterModel, GridLogicOperator, GridToolbar } from '@mui/x-data-grid'
import {
    DataGridProProps,
    gridFilteredSortedRowIdsSelector,
    useGridApiContext,
} from '@mui/x-data-grid-pro'
import { Alert, Box, Divider, Typography } from '@mui/material'
import ApiService from '../../services/api.service'
import { StripedDataGridPro } from '../Dashboard/StripedDataGridPro'
import { AnalyticsKind } from './AnalyticsKind'
import Loading from '../../utils/Loading'
import { ReviewStatus } from '../Dashboard/ReviewStatus'

type Props = {
    kind: AnalyticsKind | undefined
}

// https://mui.com/material-ui/react-table/#data-table
function ListUserReviews(
    props: Props = {
        kind: AnalyticsKind.ByReviewer,
    },
) {
    const [columns] = useState<GridColDef[]>([])

    const [rows, setRows] = React.useState<any[]>([])
    const [loading, setLoading] = useState(false)

    const getTreeDataPath: DataGridProProps['getTreeDataPath'] = (row) => row.hierarchy
    const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
        logicOperator: GridLogicOperator.Or,
        items: [],
    })
    const groupingColDef = {
        minWidth: 400,
    }

    async function load() {
        if (loading) {
            return
        }
        setLoading(true)
        const result = await ApiService.getAnalytics()
        if (result && result.status === 200) {
            const rowItems: any[] = result.data.results

            const myRows: any[] = []
            for (const item of rowItems) {
                let archy: any[] = []
                switch (props.kind) {
                    case AnalyticsKind.ByReviewer:
                        archy = [item.user_email, item.status, item.search_filter_name]
                        break
                    case AnalyticsKind.BySearchFilterName:
                        archy = [item.search_filter_name, item.project_name, item.status]
                        break
                    case AnalyticsKind.BySearchFilterCategory:
                        archy = [
                            item.search_filter_category,
                            item.search_filter_name,
                            item.status,
                            item.project_name,
                        ]
                        break
                }
                const current = {
                    hierarchy: archy,
                    filter_names: item.filter_names,
                    not_pursuing: item,
                    item: item,
                    pursuing: item,
                    total: item.total,
                    id: item.id,
                }
                myRows.push(current)
            }

            setRows(myRows)
        }
        setLoading(false)
    }

    useEffect(() => {
        load()
            .then(() => {
                setLoading(false)
            })
            .catch(console.error)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const SummatedTotals = () => {
        const apiRef = useGridApiContext()
        const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef)

        const filteredSortedRows = filteredSortedRowIds.map((id) => apiRef.current.getRow(id))
        let notPursuingCount = 0
        let pursuingCount = 0

        for (const sortedRow of filteredSortedRows) {
            if (sortedRow?.item?.status) {
                if (sortedRow.item.status === ReviewStatus.NotPursuing) {
                    notPursuingCount += 1
                }
                if (sortedRow.item.status === ReviewStatus.Pursuing) {
                    pursuingCount += 1
                }
            }
        }

        // const totalUnitPrice = filteredSortedRows.reduce(
        //     (sum, row) => sum + row.status === 'pursuing',
        //     0,
        // )
        // const totalFeeRate = filteredSortedRows.reduce(
        //     (sum, row) => sum + row.status === 'not_pursuing',
        //     0,
        // )
        const totalCount = notPursuingCount + pursuingCount

        return (
            <Box sx={{ mb: 2, mt: 2 }}>
                {`Not Pursuing: ${Math.round(notPursuingCount * 100) / 100} | Pursuing: ${
                    Math.round(pursuingCount * 100) / 100
                } | Total #: ${totalCount}`}
            </Box>
        )
    }

    return (
        <Box sx={{ minHeight: 300, mt: 2 }}>
            <Typography variant={'h5'}>
                {props.kind === AnalyticsKind.BySearchFilterCategory && 'By Category'}
                {props.kind === AnalyticsKind.ByReviewer && 'By Reviewer'}
                {props.kind === AnalyticsKind.BySearchFilterName && 'By Filter'}
            </Typography>
            <Divider sx={{ mb: 2 }}></Divider>
            {loading && <Loading></Loading>}
            {!loading && rows.length === 0 && (
                <>
                    <Alert severity="info">
                        <div>
                            No Data found for analytis{' '}
                            <b>
                                {props.kind === AnalyticsKind.BySearchFilterCategory &&
                                    'By Category'}
                                {props.kind === AnalyticsKind.ByReviewer && 'By Reviewer'}
                                {props.kind === AnalyticsKind.BySearchFilterName && 'By Filter'}
                            </b>
                        </div>
                    </Alert>
                </>
            )}
            {rows.length > 0 && (
                <Paper sx={{ width: '100%', overflow: 'hidden', marginBottom: 4 }}>
                    <StripedDataGridPro
                        treeData
                        rows={rows}
                        columns={columns}
                        loading={rows.length === 0}
                        getTreeDataPath={getTreeDataPath}
                        filterModel={filterModel}
                        onFilterModelChange={setFilterModel}
                        defaultGroupingExpansionDepth={1}
                        slots={{
                            toolbar: GridToolbar,
                            footer: SummatedTotals,
                        }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                                csvOptions: { disableToolbarButton: true },
                                printOptions: { disableToolbarButton: true },
                            },
                        }}
                        groupingColDef={groupingColDef}
                        density={'compact'}
                    />
                </Paper>
            )}
        </Box>
    )
}

export default ListUserReviews
