import React, { useEffect, useRef, useState } from 'react'
import Button, { ButtonProps } from '@mui/material/Button'
import useResizeObserver from '@react-hook/resize-observer'

import { styled } from '@mui/material/styles'

const StyledIconWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
        fontSize: '1rem', // Adjust the size of the icon here
    },
})

const useSize = (target: React.RefObject<HTMLElement>) => {
    const [size, setSize] = useState({ width: 0, height: 0 })

    useResizeObserver(target, (entry) => setSize(entry.contentRect))

    return size
}

interface ResponsiveButtonProps extends ButtonProps {
    children: React.ReactNode
}

const ResponsiveButton: React.FC<ResponsiveButtonProps> = (props) => {
    const { children, endIcon, ...rest } = props

    const buttonRef = useRef<HTMLButtonElement>(null)
    const size = useSize(buttonRef)
    const [fontSize, setFontSize] = useState('1rem')

    useEffect(() => {
        if (size.width) {
            const newFontSize = Math.min(size.width / 10, 20)
            setFontSize(`${newFontSize}px`)
        }
    }, [size])

    return (
        <Button
            ref={buttonRef}
            style={{ fontSize }}
            {...rest}
            endIcon={endIcon && <StyledIconWrapper>{endIcon}</StyledIconWrapper>}
        >
            {children}
        </Button>
    )
}

export default ResponsiveButton
