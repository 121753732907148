import * as React from 'react'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'

export default function Terms() {
    return (
        <Container
            id="terms-and-conditions"
            sx={{
                pt: { xs: 4, sm: 12 },
                pb: { xs: 8, sm: 16 },
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: { xs: 3, sm: 6 },
            }}
        >
            <Typography
                component="h2"
                variant="h4"
                color="text.primary"
                sx={{
                    width: { sm: '100%', md: '60%' },
                    textAlign: { sm: 'left', md: 'center' },
                }}
            >
                Terms and Conditions
            </Typography>
            <Box sx={{ width: '100%' }}>
                <Typography variant="body2">
                    You can reach our customer support team by emailing{' '}
                    <Link>support@construct-quick-est.com</Link>. We are here to assist you
                    promptly.
                </Typography>
            </Box>
        </Container>
    )
}
