import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import SalesforceIcon from './Icons/SalesforceIcon'
import { Alert, Button } from '@mui/material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import * as React from 'react'

export default function LinkSalesforceAlert() {
    return (
        <>
            <Alert severity="info">
                <Stack alignItems="center" direction="row" gap={0}>
                    <Typography variant="body1">
                        Link your <b>Salesforce</b>{' '}
                    </Typography>
                    <SalesforceIcon sx={{ mr: 1, ml: 0.5 }} />
                    <Typography variant="body1">
                        {' '}
                        account to create opportunities when pursuing projects
                    </Typography>
                    <Button
                        sx={{ ml: 2 }}
                        endIcon={<OpenInNewIcon />}
                        variant={'outlined'}
                        color={'inherit'}
                        target={'_blank'}
                        href={'/login/salesforce'}
                    >
                        Link Account
                    </Button>
                </Stack>
            </Alert>
        </>
    )
}
