import { styled } from '@mui/material/styles'
import Paper from '@mui/material/Paper'

export const Square = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : theme.palette.background.paper,
    padding: theme.spacing(3),
    textAlign: 'center',
    minHeight: '400px',
    // ...theme.typography.body2,
    // color: theme.palette.text.secondary,
}))
