import * as React from 'react'
import Box from '@mui/material/Box'
import { Container } from '@mui/material'
import ListUserReviews from './ListUserReviews'
import { AnalyticsKind } from './AnalyticsKind'
import { withAuthenticationRequired } from '@auth0/auth0-react'

function AnalyticsPage() {
    return (
        <Box sx={{ p: 2, width: '100%' }}>
            <Container sx={{ mb: 2, mt: 3 }}>
                <ListUserReviews kind={AnalyticsKind.ByReviewer}></ListUserReviews>
                <ListUserReviews kind={AnalyticsKind.BySearchFilterName}></ListUserReviews>
                <ListUserReviews kind={AnalyticsKind.BySearchFilterCategory}></ListUserReviews>
            </Container>
        </Box>
    )
}
export default withAuthenticationRequired(AnalyticsPage)
