import * as React from 'react'
import { useEffect } from 'react'
import Paper from '@mui/material/Paper'
import ApiService from '../../../../services/api.service'
import { GridColDef, GridToolbar } from '@mui/x-data-grid'
import { Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { toLocalDateDateFirst } from '../../../Projects/ToLocalDate'
import { DataGridPro } from '@mui/x-data-grid-pro'
import getReviewStatusButtonColor from '../../../Dashboard/getReviewStatusButtonColor'
import getReviewStatusButtonVariant from '../../../Dashboard/getReviewStatusButtonVariant'
import { ReviewStatusText } from '../../../Dashboard/ReviewStatus'
import ResponsiveButton from '../../../Dashboard/V3/ResponsiveButton' // https://mui.com/material-ui/react-table/#data-table

// https://mui.com/material-ui/react-table/#data-table
export default function SearchFilterReviewList() {
    const navigate = useNavigate()
    const [loading, setLoading] = React.useState<boolean>(true)

    async function load() {
        const result = await ApiService.getAllSearchFilterReviews()
        if (result.status === 200) {
            const rowItems = result.data
            setRows(rowItems)
        }
        setLoading(false)
    }

    const [rows, setRows] = React.useState<any[]>([])
    const columns: GridColDef[] = [
        {
            field: 'project_name',
            headerName: 'Project',
            flex: 1,
        },
        {
            field: 'search_filter_category',
            headerName: 'Category',
            flex: 0.5,
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 140,
            renderCell: (params) => {
                return (
                    <Box
                        sx={{
                            maxWidth: 120,
                        }}
                    >
                        <ResponsiveButton
                            size="small"
                            color={getReviewStatusButtonColor(params.value)}
                            variant={getReviewStatusButtonVariant(params.value)}
                            fullWidth
                        >
                            {ReviewStatusText(params.value)}
                        </ResponsiveButton>
                    </Box>
                )
            },
        },
        {
            field: 'user_email',
            headerName: 'User',
            flex: 1.5,
        },

        {
            field: 'message',
            headerName: 'Message',
            flex: 2,
        },

        // {
        //     field: 'terms',
        //     headerName: 'Terms',
        //     minWidth: 300,
        //     renderCell: (params) => {
        //         return (
        //             <>
        //                 {Array.from(params.row['terms'].map((item: any) => item['name'])).join(
        //                     ', ',
        //                 )}
        //             </>
        //         )
        //     },
        // },
        // {
        //     field: 'category',
        //     headerName: 'Category',
        //     renderCell: (params) => {
        //         return <>{params.row['category']}</>
        //     },
        // },
        // {
        //     field: 'action',
        //     headerName: 'Action',
        //     sortable: false,
        //     minWidth: 300,
        //     renderCell: (params) => {
        //         const onDelete = (e: any) => {
        //             e.stopPropagation() // don't select this row after clicking
        //             ApiService.deleteSearchFilterById(params.row.id).then((res) => {
        //                 console.log(res)
        //                 load()
        //             })
        //         }
        //         const onUse = (e: any) => {
        //             e.stopPropagation() // don't select this row after clicking
        //             navigate(`/search-filters/${params.row.id}/search`)
        //             return
        //         }
        //
        //         const onEdit = (e: any) => {
        //             e.stopPropagation() // don't select this row after clicking
        //             navigate(`/search-filters/${params.row.id}/edit`)
        //             return
        //         }
        //         return (
        //             <Stack direction="row" spacing={2}>
        //                 <Button
        //                     fullWidth
        //                     variant="outlined"
        //                     onClick={onUse}
        //                     size="small"
        //                     color={'success'}
        //                     sx={{
        //                         maxWidth: 100,
        //                     }}
        //                 >
        //                     Search
        //                 </Button>
        //                 <Button
        //                     fullWidth
        //                     variant="outlined"
        //                     onClick={onEdit}
        //                     size="small"
        //                     color={'warning'}
        //                     sx={{
        //                         maxWidth: 100,
        //                     }}
        //                 >
        //                     Edit
        //                 </Button>
        //
        //                 <Button
        //                     fullWidth
        //                     variant="outlined"
        //                     onClick={onDelete}
        //                     size="small"
        //                     color={'error'}
        //                     sx={{
        //                         maxWidth: 100,
        //                     }}
        //                 >
        //                     Delete
        //                 </Button>
        //             </Stack>
        //         )
        //     },
        // },
        {
            field: 'date_updated',
            headerName: 'Updated at',
            flex: 1,
            valueFormatter: (params: any) => {
                return toLocalDateDateFirst(params)
            },
        },
        {
            field: 'id',
            headerName: 'Id',
            hideable: true,
        },
    ]

    useEffect(() => {
        load()
    }, [])
    return (
        <Box sx={{ p: 2 }}>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <DataGridPro
                    rows={rows}
                    columns={columns}
                    density={'compact'}
                    loading={loading}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            csvOptions: { disableToolbarButton: true },
                            printOptions: { disableToolbarButton: true },
                        },
                    }}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 50 },
                        },
                        columns: {
                            columnVisibilityModel: {
                                id: false,
                            },
                        },
                    }}
                    pageSizeOptions={[50, 250, 500]}
                />
            </Paper>
        </Box>
    )
}
