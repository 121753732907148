import * as React from 'react'
import { useEffect, useState } from 'react'
import {
    DataGridPro,
    DataGridProProps,
    GridColDef,
    gridFilteredDescendantCountLookupSelector,
    GridRenderCellParams,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid-pro'
import Box from '@mui/material/Box'
import Button, { ButtonProps } from '@mui/material/Button'
import { GridToolbar } from '@mui/x-data-grid'
import ApiService from '../../../services/api.service'
import { DataRow } from './DataRow'
import { RenderReviewCell } from './RenderReviewCell'
import useAlert from '../../Alerting/useAlert'
import { RenderViewCell } from './RenderViewCell'

function CustomGridTreeDataGroupingCell(props: GridRenderCellParams) {
    const { id, field, rowNode } = props
    const apiRef = useGridApiContext()
    const filteredDescendantCountLookup = useGridSelector(
        apiRef,
        gridFilteredDescendantCountLookupSelector,
    )
    const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0

    const handleClick: ButtonProps['onClick'] = (event) => {
        // window.alert(JSON.stringify(rowNode, null, 2))
        if (rowNode.type !== 'group') {
            return
        }

        apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded)
        apiRef.current.setCellFocus(id, field)
        event.stopPropagation()
    }
    let data: any = {}
    if (filteredDescendantCount) {
        data = JSON.parse(JSON.stringify(rowNode))
    }
    return (
        <Box sx={{ ml: rowNode.depth * 4 }}>
            <div>
                {filteredDescendantCount > 0 ? (
                    <Button onClick={handleClick} tabIndex={-1} size="small">
                        {data.groupingKey} ({filteredDescendantCount})
                    </Button>
                ) : (
                    <span />
                )}
            </div>
        </Box>
    )
}

const getTreeDataPath: DataGridProProps['getTreeDataPath'] = (row) => row.hierarchy

const groupingColDef: DataGridProProps['groupingColDef'] = {
    headerName: 'Project',
    flex: 1.25,
    renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} />,
}

export default function DashboardV3(props: { projectId?: string | undefined }) {
    let { projectId } = props
    const [loading, setLoading] = useState(false)
    const [rows, setRows] = useState<any[]>([])
    const { setAlert } = useAlert()

    const columns: GridColDef<DataRow>[] = [
        {
            field: 'id',
            headerName: 'Id',
            flex: 1,
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 1.2,
            valueGetter: (value: any, row) => {
                try {
                    const hierarchy = row.hierarchy
                    return `${hierarchy[hierarchy.length - 1]}`
                } catch (e) {}
                return ``
            },
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 0.8,
            // renderCell: (params) => <RenderReviewStatusCell {...params} />,
            renderCell: (params) => (
                <RenderReviewCell
                    params={params}
                    callback={function callbackNow(params) {
                        console.log(params)
                        setAlert('Updating review status...', 'info')
                        load().then((res) => {
                            setAlert('Updated review status', 'success')
                        })
                        // setAlert('Updating review status...', 'info')
                        // getNewRows().then((rows) => {
                        //     setRows(rows)
                        //     setAlert('Updated review status', 'success')
                        // })
                    }}
                />
            ),
        },
        {
            field: 'view',
            headerName: 'Preview',
            flex: 0.8,
            // renderCell: (params) => <RenderReviewStatusCell {...params} />,
            renderCell: (params) => (
                <RenderViewCell
                    params={params}
                    onOpen={() => {
                        setLoading(true)
                    }}
                    onClose={() => {
                        setLoading(false)
                    }}
                />
            ),
        },

        {
            field: 'review_date',
            headerName: 'Review Date',
            flex: 1,
            renderCell: (params) => {
                if (params.rowNode.type === 'group' || params?.value?.length === 0) {
                    return <></>
                }
                const review = params.value[params.value.length - 1]
                return <>{review.date_created}</>
            },
        },
        {
            field: 'reviewer',
            headerName: 'Reviewer',
            flex: 1,
            renderCell: (params) => {
                if (params.rowNode.type === 'group' || params?.value?.length === 0) {
                    return <></>
                }
                const review = params.value[params.value.length - 1]
                return <>{review.user_email}</>
            },
        },
        {
            field: 'reviewer_comment',
            headerName: 'Comment',
            flex: 1,
            renderCell: (params) => {
                if (params.rowNode.type === 'group' || params?.value?.length === 0) {
                    return <></>
                }
                const review = params.value[params.value.length - 1]
                return <>{review.message}</>
            },
        },

        {
            field: 'terms',
            headerName: 'Terms',
            flex: 1,
        },
    ]

    async function load() {
        if (loading) {
            return
        }
        setLoading(true)
        const response = await ApiService.getDashboardV3()
        const data: DataRow[] = []
        if (response.status === 200) {
            const results: any[] = response.data
            for (const result of results) {
                const row: DataRow = {
                    date_updated: new Date(),
                    // date_updated: undefined,
                    hierarchy: [
                        result.project_name,
                        result.search_filter_category,
                        result.search_filter_name,
                    ],
                    hits: result.hits,
                    project_id: result.project_id,
                    project_name: result.project_name,
                    search_filter_id: result.search_filter_id,
                    search_filter_name: result.search_filter_name,
                    search_filter_category: result.search_filter_category,
                    terms: result.terms,
                    reviews: result.reviews,
                    review_date: result.reviews,
                    reviewer: result.reviews,
                    reviewer_comment: result.reviews,
                    status: result.reviews,
                    view: result,
                    id: `${result.project_name}/${result.search_filter_category}/${result.search_filter_name}`,
                }
                if (projectId !== undefined && projectId !== null) {
                    if (projectId !== row.project_id) {
                        continue
                    }
                }
                data.push(row)
            }
            data.sort((a: any, b: any) => {
                if (a.date_updated < b.date_updated) {
                    return -1
                }
                if (a.date_updated > b.date_updated) {
                    return 1
                }
                return 0
            }).reverse()
            setRows(data)
        }
        setLoading(false)
    }

    useEffect(() => {
        load().catch(console.error)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div style={{ height: 800, width: '100%' }}>
            <DataGridPro
                treeData
                rows={rows}
                columns={columns}
                getTreeDataPath={getTreeDataPath}
                groupingColDef={groupingColDef}
                loading={loading}
                defaultGroupingExpansionDepth={1}
                slots={{ toolbar: GridToolbar }}
                rowHeight={38}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                    },
                }}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            Id: false,
                        },
                    },
                }}
                columnVisibilityModel={{
                    id: false,
                }}
            />
        </div>
    )
}
