import React, { useEffect, useState } from 'react'
import { Alert, CircularProgress, Container, FormControl, Stack, Typography } from '@mui/material'
import ApiService from '../../../services/api.service'
import { SalesforceMyAccount } from '../../../services/api/models/salesforce-my-account'
import { useAuth0 } from '@auth0/auth0-react'
import LoginWithSalesforceButton from '../../Login/Salesforce/Buttons/LoginWithSalesforceButton'
import AutocompleteField from './AutocompleteField'
import { SalesforceLoginActions } from '../../Login/Salesforce/SalesforceLoginActions'
import JsonData from '../../Shared/JsonData'
import {
    SalesforceObjectTypeMapping,
    SalesforceObjectType,
} from '../../../services/api/models/salesforce_object_type_mapping'
import { AutoCompleteItem } from './AutoCompleteItem'

const ManageSalesforceIntegration: React.FC = () => {
    const [objectTypes, setObjectTypes] = useState<SalesforceObjectType[]>([])
    const [myAccount, setMyAccount] = useState<SalesforceMyAccount | null>(null)
    const [objectTypeStates, setObjectTypeStates] = useState<SalesforceObjectTypeMapping[]>([])
    const [loading, setLoading] = useState<boolean>(true)
    const [updating, setUpdating] = useState<boolean>(false)
    const [serverError, setServerError] = useState<string | null>(null)
    const { isLoading, error, isAuthenticated, user } = useAuth0()

    const fetchData = async () => {
        try {
            setLoading(true)
            const [objectTypesResponse, accountResponse, existingMappings] = await Promise.all([
                ApiService.getSalesforceObjectTypes(),
                ApiService.getSalesforceMyAccount(),
                ApiService.getSalesforceObjectTypeMappings(),
            ])
            setObjectTypeStates(existingMappings.data)
            setObjectTypes(objectTypesResponse.data)
            setMyAccount(accountResponse.data)
        } catch (error) {
            console.error(error)
            setServerError('Failed to fetch data from Salesforce')
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchData().catch(console.error)
    }, [])

    const handleChangeObjectTypeChange = (
        event: React.ChangeEvent<{}> | null,
        value: AutoCompleteItem | null,
        objectType: SalesforceObjectTypeMapping | null,
    ) => {
        if (!objectType) return

        setUpdating(true)
        ApiService.updateSalesforceObjectTypes([
            {
                salesforceObjectName: value?.name,
                databaseObjectType: objectType.database_object_type,
                label: objectType.label,
            },
        ])
            .then((res) => {
                setObjectTypeStates(res.data)
                setLoading(false)
                setUpdating(false)
                ApiService.getSalesforceObjects(objectType.salesforce_object_type, 'mike').then(
                    (res3) => {
                        console.log(res3)
                    },
                )
            })
            .catch((error) => {
                console.error('Failed to update Salesforce object types', error)
                setLoading(false)
                setUpdating(false)
            })
    }

    if (loading || isLoading) {
        return (
            <Container>
                <CircularProgress />
            </Container>
        )
    }

    if (serverError) {
        return (
            <Container>
                <Stack direction="column" spacing={2}>
                    <Alert color="warning">{serverError}</Alert>
                    <LoginWithSalesforceButton />
                </Stack>
            </Container>
        )
    }

    return (
        <Container>
            <FormControl fullWidth margin="normal">
                {myAccount && (
                    <>
                        <Typography>
                            Logged in as <b>{myAccount.email}</b> ({myAccount.name})
                        </Typography>
                        {user && <SalesforceLoginActions subjectId={user.sub} />}
                    </>
                )}
            </FormControl>
            <FormControl fullWidth margin="normal">
                <Stack direction={'column'} spacing={3}>
                    {updating && (
                        <>
                            <CircularProgress></CircularProgress>
                        </>
                    )}
                    {!loading && !updating && (
                        <>
                            {objectTypeStates.map((objType) => {
                                const options = objectTypes.map((item) => ({
                                    name: item.name,
                                    id: item.name,
                                }))

                                return (
                                    <AutocompleteField
                                        key={objType.key}
                                        options={options}
                                        value={objType.value}
                                        onChange={(event, value) =>
                                            handleChangeObjectTypeChange(event, value, objType)
                                        }
                                        label={objType.database_object_type}
                                    />
                                )
                            })}
                        </>
                    )}
                </Stack>
            </FormControl>
        </Container>
    )
}

export default ManageSalesforceIntegration
