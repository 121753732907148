import { GridRenderCellParams } from '@mui/x-data-grid'
import * as React from 'react'
import { Button, CircularProgress } from '@mui/material'
import ApiService from '../../../../services/api.service'

export function RenderSearchResultsCell({
    params,
    callback,
}: {
    params: GridRenderCellParams<any, any[]>
    callback: () => void
}) {
    const [loading, setLoading] = React.useState<boolean>(false)
    if (params.value === undefined || params.value.toString() === '') {
        return <></>
    }
    const ids = (params.value || []).map((item: any) => item.id)
    return (
        <>
            {loading ? (
                <>
                    <CircularProgress size={'1em'} />
                </>
            ) : (
                <>
                    <Button
                        key={`status-button`}
                        size="small"
                        variant={'outlined'}
                        fullWidth
                        onClick={async () => {
                            setLoading(true)
                            ApiService.buildReviewSearchResultsUrl(ids).then((res) => {
                                window.open(res.data['url'], '_blank')
                                setLoading(false)
                            })
                        }}
                    >
                        Download {ids.length} pages
                    </Button>
                </>
            )}
        </>
    )
}
