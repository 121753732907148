import { GridRenderCellParams } from '@mui/x-data-grid'
import { Box } from '@mui/material'
import * as React from 'react'

export function RenderReviewerCell({ params }: { params: GridRenderCellParams<any, any> }) {
    if (params.value === undefined || params.value === null || params.value.toString() === '') {
        return <></>
    }

    return <Box sx={{ fontSize: '10px' }}>{params.value.toString()}</Box>
}
