import * as React from 'react'
import { Portal } from '@mui/material'
import { GridToolbar, GridToolbarQuickFilter } from '@mui/x-data-grid'

export function MyCustomToolbar(props: any) {
    return (
        <React.Fragment>
            <Portal container={() => document.getElementById('filter-panel')!}>
                <GridToolbarQuickFilter />
            </Portal>
            <GridToolbar {...props} />
        </React.Fragment>
    )
}
