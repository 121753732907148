import * as React from 'react'
import { useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import IconButton from '@mui/material/IconButton'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import LinkedInIcon from '@mui/icons-material/LinkedIn'

import MyLogo from './MyLogo'
import ApiService from '../../../../services/api.service'
import useAlert from '../../../Alerting/useAlert'
import { useAuth0 } from '@auth0/auth0-react'

function Copyright() {
    return (
        <Typography variant="body2" color="text.secondary" mt={1}>
            {'Copyright © '}
            <Link href="/">Construct QuickEST</Link> {new Date().getFullYear()}
        </Typography>
    )
}

export default function Footer() {
    const [subscribeEmailAddress, setSubscribeEmailAddress] = useState<string>('')
    const { setAlert } = useAlert()
    const { isAuthenticated, isLoading } = useAuth0()

    return (
        <Container
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: { xs: 4, sm: 8 },
                py: { xs: 8, sm: 10 },
                textAlign: { sm: 'center', md: 'left' },
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    width: '100%',
                    justifyContent: 'space-between',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 4,
                        minWidth: { xs: '100%', sm: '60%' },
                    }}
                >
                    <Box sx={{ width: { xs: '100%', sm: '60%' } }}>
                        <MyLogo></MyLogo>
                        <Box sx={{ ml: '-15px' }}>
                            {/*<img src={logo192}/>*/}
                            {/*<img*/}
                            {/*  src={*/}
                            {/*      logo*/}
                            {/*  }*/}
                            {/*  style={logoStyle}*/}
                            {/*  alt="construct quick est"*/}
                            {/*/>*/}
                        </Box>
                        <Typography variant="body2" fontWeight={600} gutterBottom>
                            Newsletter
                        </Typography>
                        <Typography variant="body2" color="text.secondary" mb={2}>
                            Subscribe to our newsletter for weekly updates and promotions.
                        </Typography>
                        <Stack direction="row" spacing={1} useFlexGap>
                            <TextField
                                id="outlined-basic"
                                hiddenLabel
                                size="small"
                                variant="outlined"
                                fullWidth
                                aria-label="Enter your email address"
                                placeholder="Your email address"
                                type={'email'}
                                onChange={(ev: any) => {
                                    setSubscribeEmailAddress(ev.target.value)
                                }}
                                inputProps={
                                    {
                                        // autoComplete: 'off',
                                        // ariaLabel: 'Enter your email address',
                                    }
                                }
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{ flexShrink: 0 }}
                                onClick={() => {
                                    ApiService.submitEmailSubscribe(subscribeEmailAddress)
                                        .then((r) => {
                                            setAlert(
                                                'Successfully subscribed to our mailing list',
                                                'success',
                                            )
                                            setSubscribeEmailAddress('')
                                        })
                                        .catch((err) => {
                                            setAlert(
                                                'Error subscribing to our mailing list',
                                                'warning',
                                            )
                                            setSubscribeEmailAddress('')
                                        })
                                }}
                            >
                                Subscribe
                            </Button>
                        </Stack>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: { xs: 'none', sm: 'flex' },
                        flexDirection: 'column',
                        gap: 1,
                    }}
                >
                    <Typography variant="body2" fontWeight={600}>
                        Product
                    </Typography>
                    <Link color="text.secondary" href="/features">
                        Features
                    </Link>
                    <Link color="text.secondary" href="/pricing">
                        Pricing
                    </Link>
                    <Link color="text.secondary" href="/faq">
                        FAQs
                    </Link>
                    {!isLoading && !isAuthenticated && (
                        <>
                            <Link color="text.secondary" href="/login">
                                Login
                            </Link>
                        </>
                    )}
                    {!isLoading && isAuthenticated && (
                        <>
                            <Link color="text.secondary" href="/logout">
                                Logout
                            </Link>
                        </>
                    )}
                </Box>
                <Box
                    sx={{
                        display: { xs: 'none', sm: 'flex' },
                        flexDirection: 'column',
                        gap: 1,
                    }}
                >
                    <Typography variant="body2" fontWeight={600}>
                        Company
                    </Typography>
                    <Link color="text.secondary" href="/">
                        About us
                    </Link>
                    {/*<Link color="text.secondary" href="#">*/}
                    {/*    Careers*/}
                    {/*</Link>*/}
                    {/*<Link color="text.secondary" href="#">*/}
                    {/*    Press*/}
                    {/*</Link>*/}
                </Box>
                <Box
                    sx={{
                        display: { xs: 'none', sm: 'flex' },
                        flexDirection: 'column',
                        gap: 1,
                    }}
                >
                    <Typography variant="body2" fontWeight={600}>
                        Legal
                    </Typography>
                    <Link color="text.secondary" href="/terms">
                        Terms
                    </Link>
                    <Link color="text.secondary" href="/privacy">
                        Privacy
                    </Link>
                    <Link color="text.secondary" href="/contact">
                        Contact
                    </Link>
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    pt: { xs: 4, sm: 8 },
                    width: '100%',
                    borderTop: '1px solid',
                    borderColor: 'divider',
                }}
            >
                <div>
                    <Link color="text.secondary" href="/privacy">
                        Privacy Policy
                    </Link>
                    <Typography display="inline" sx={{ mx: 0.5, opacity: 0.5 }}>
                        &nbsp;•&nbsp;
                    </Typography>
                    <Link color="text.secondary" href="/terms">
                        Terms of Service
                    </Link>
                    <Copyright />
                </div>
                <Stack
                    direction="row"
                    justifyContent="left"
                    spacing={1}
                    useFlexGap
                    sx={{
                        color: 'text.secondary',
                    }}
                >
                    {/*<IconButton*/}
                    {/*    color="inherit"*/}
                    {/*    href="https://github.com/mui"*/}
                    {/*    aria-label="GitHub"*/}
                    {/*    sx={{alignSelf: 'center'}}*/}
                    {/*>*/}
                    {/*    <FacebookIcon/>*/}
                    {/*</IconButton>*/}
                    {/*<IconButton*/}
                    {/*    color="inherit"*/}
                    {/*    href="https://twitter.com/MaterialUI"*/}
                    {/*    aria-label="X"*/}
                    {/*    sx={{alignSelf: 'center'}}*/}
                    {/*>*/}
                    {/*    <TwitterIcon/>*/}
                    {/*</IconButton>*/}
                    <IconButton
                        color="inherit"
                        href="https://www.linkedin.com/in/chris-gottron-1ba76b25/"
                        target={'_blank'}
                        aria-label="LinkedIn"
                        sx={{ alignSelf: 'center' }}
                    >
                        <LinkedInIcon />
                    </IconButton>
                </Stack>
            </Box>
        </Container>
    )
}
