// AlertPopup.js
import { Alert } from '@mui/material'
import useAlert from './useAlert'
import Snackbar from '@mui/material/Snackbar'
import * as React from 'react'
import { TransitionProps } from '@mui/material/transitions'
import Slide from '@mui/material/Slide'

const AlertTransition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />
})

const AlertPopup = () => {
    const { text, type } = useAlert()

    if (text && type) {
        return (
            <React.Fragment>
                <Snackbar open={true} autoHideDuration={2000} TransitionComponent={AlertTransition}>
                    <Alert severity={type} variant="filled">
                        {text}
                    </Alert>
                </Snackbar>
            </React.Fragment>
        )
    } else {
        return <></>
    }
}

export default AlertPopup
