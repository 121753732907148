import React, { useEffect } from 'react'
import { Box, Button, CircularProgress, TextField } from '@mui/material'
import { ProjectContact } from './ProjectContact'
import ApiService from '../../../services/api.service'
import useAlert from '../../Alerting/useAlert'
import { styled } from '@mui/system'

interface MyProjectContactFormProps {
    contact: ProjectContact
}

const StyledBox = styled(Box)({
    marginBottom: 20,
    maxWidth: 500,
    paddingRight: 20,
})

export default function MyProjectContactForm(props: MyProjectContactFormProps) {
    const [contact, setContact] = React.useState(props.contact)
    const [hasLoaded, setHasLoaded] = React.useState(false)
    const [hasChanges, setHasChanges] = React.useState(false)
    const [saving, setSaving] = React.useState(false)
    const { setAlert } = useAlert()

    function check() {
        let hasSomeChanges = false
        if (contact.name !== props.contact.name) {
            hasSomeChanges = true
        }
        if (contact.phone !== props.contact.phone) {
            hasSomeChanges = true
        }
        if (contact.email !== props.contact.email) {
            hasSomeChanges = true
        }
        setHasChanges(hasSomeChanges)
    }

    function save() {
        setSaving(true)
        ApiService.addProjectContact(contact.project_id || '', contact).then((res) => {
            setAlert('Contact updated successfully', 'success')
            setSaving(false)
            setHasChanges(false)
        })
    }

    useEffect(() => {
        const load = () => {
            if (hasLoaded) {
                return
            }

            ApiService.getProjectContacts(contact.project_id).then((response) => {
                setHasLoaded(true)
                const exists =
                    response.data.filter(
                        (item: any) => item.contact_type === props.contact.contact_type,
                    ).length > 0
                if (exists) {
                    setContact(
                        response.data.filter(
                            (item: any) => item.contact_type === props.contact.contact_type,
                        )[0],
                    )
                }
            })
        }
        load()
    }, [contact.project_id, hasLoaded, props.contact.contact_type])

    // declare your data as initial state

    function onKeyPress(e: any) {
        if (e.keyCode === 13) {
            save()
        }
    }

    return (
        <Box>
            <StyledBox>
                <TextField
                    fullWidth
                    disabled={!hasLoaded || saving}
                    label="Name"
                    value={contact.name || ''}
                    autoComplete={'off'}
                    type={'text'}
                    size={'small'}
                    onKeyDown={onKeyPress}
                    onChange={(event) => {
                        setContact({
                            ...contact,
                            name: event.target.value,
                        })
                        check()
                    }}
                    onBlur={(event) => {
                        check()
                    }}
                />
            </StyledBox>
            <StyledBox>
                <TextField
                    fullWidth
                    disabled={!hasLoaded || saving}
                    label="Phone"
                    value={contact.phone || ''}
                    autoComplete={'off'}
                    type={'text'}
                    size={'small'}
                    onKeyDown={onKeyPress}
                    onChange={(event) => {
                        setContact({
                            ...contact,
                            phone: event.target.value,
                        })
                        check()
                    }}
                    onBlur={(event) => {
                        check()
                    }}
                />
            </StyledBox>
            <StyledBox>
                <TextField
                    fullWidth
                    disabled={!hasLoaded || saving}
                    label="Email"
                    value={contact.email || ''}
                    autoComplete={'off'}
                    type={'email'}
                    size={'small'}
                    onKeyDown={onKeyPress}
                    onChange={(event) => {
                        setContact({
                            ...contact,
                            email: event.target.value,
                        })
                        check()
                    }}
                    onBlur={(event) => {
                        check()
                    }}
                />
            </StyledBox>
            {hasChanges && (
                <StyledBox>
                    {!saving ? (
                        <>
                            <Button
                                fullWidth
                                color={'success'}
                                variant={'contained'}
                                onClick={save}
                            >
                                Save
                            </Button>
                        </>
                    ) : (
                        <>
                            <Box textAlign={'center'}>
                                <CircularProgress></CircularProgress>
                            </Box>
                        </>
                    )}
                </StyledBox>
            )}
        </Box>
    )
}
