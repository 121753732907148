import { GridValidRowModel } from '@mui/x-data-grid'
import * as React from 'react'
import Grid from '@mui/material/Grid'
import { Typography } from '@mui/material'
import Paper from '@mui/material/Paper'

// noinspection JSUnusedGlobalSymbols
export function GridRowDetailPanelContent({ row }: { row: GridValidRowModel }) {
    console.log(row)
    if (row.reviews.length === 0) {
        return <>No reviews</>
    }

    return (
        <Paper sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}>
            <Grid container>
                <Grid container key={'columns'} xs={12} sx={{ textAlign: 'left', paddingLeft: 10 }}>
                    <Grid item xs={3}>
                        <Typography variant="h6">Status</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="h6">User</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="h6">Comment</Typography>
                    </Grid>

                    <Grid item xs={3}>
                        <Typography variant="h6">Date</Typography>
                    </Grid>
                </Grid>
                {row.reviews
                    .sort((a: any, b: any) => {
                        if (a.date_updated < b.date_updated) {
                            return -1
                        }
                        if (a.date_updated > b.date_updated) {
                            return 1
                        }
                        return 0
                    })
                    .reverse()
                    .map((item: any) => (
                        <Grid
                            container
                            key={item.id}
                            xs={12}
                            sx={{ textAlign: 'left', paddingLeft: 10 }}
                        >
                            <Grid item xs={3}>
                                {item.status.replace('_', ' ').toString().toUpperCase()}
                            </Grid>
                            <Grid item xs={3}>
                                {item.user_email}
                            </Grid>
                            <Grid item xs={3}>
                                {item.message}
                            </Grid>
                            <Grid item xs={3}>
                                {item.date_updated}
                            </Grid>
                        </Grid>
                    ))}
            </Grid>
        </Paper>
    )
}
