import * as React from 'react'
import { Box, Typography } from '@mui/material'
import logo192 from '../../../../assets/logo192.png'

export default function MyLogo() {
    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    maxWidth: 225,
                    height: 80,
                    // border: '0.01em solid grey'
                }}
            >
                <img
                    src={logo192}
                    style={{
                        maxWidth: 60,
                        maxHeight: 60,
                    }}
                    alt={'Construct QuickEST'}
                />
                <Typography
                    sx={{
                        paddingLeft: 1,
                    }}
                >
                    Construct QuickEST
                </Typography>
            </Box>
        </>
    )
}
