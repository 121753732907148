import * as React from 'react'
import { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import { useAuth0 } from '@auth0/auth0-react'
import ApiService from '../../../../services/api.service'
import { Alert, Card, CardActions, CardContent, Chip, Divider } from '@mui/material'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import Grid from '@mui/material/Grid'

export default function Pricing() {
    const { isAuthenticated } = useAuth0()
    const [subscriptions, setSubscriptions] = useState<any | null>(null)
    const [tiers, setTiers] = useState<any[] | null>(null)
    const [manageData, setManageData] = useState<any | null>(null)

    useEffect(() => {
        async function login() {
            const tiersResponse = await ApiService.getTiers()
            if (tiersResponse.status === 200) {
                if (tiersResponse.data.length) {
                    setTiers(
                        tiersResponse.data
                            .sort((a: any, b: any) => {
                                if (a.lookup_key === 'standard_monthly') {
                                    return -1
                                }
                                return 0
                            })
                            .map((item: any) => {
                                return {
                                    title:
                                        item['lookup_key'] === 'standard_monthly'
                                            ? 'Monthly'
                                            : 'Yearly',
                                    price: item['unit_amount'] / 100,
                                    key: item['lookup_key'],
                                    price_id: item['id'],
                                    interval: item['recurring']['interval'],
                                    preferred: item['lookup_key'] === 'standard_monthly',
                                    subheader: '',
                                    description: ['Unlimited Storage', 'Company Dashboard'],
                                    buttonText: 'Get Started',
                                    buttonLink: '/checkout',
                                    buttonVariant: 'outlined',
                                }
                            }),
                    )
                }
            }

            if (isAuthenticated) {
                const subs = await ApiService.getSubscriptions()
                if (subs.status === 200) {
                    if (subs.data.subscriptions.length) {
                        setSubscriptions(subs.data.subscriptions)
                    }
                    setManageData(subs.data.manage)
                }
            }
        }

        login().catch(console.error)

        // eslint-disable-next-line
    }, [])
    return (
        <Container
            id="pricing"
            maxWidth={'xl'}
            sx={{
                // pt: { xs: 4, sm: 12 },
                // pb: { xs: 8, sm: 16 },
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                // gap: { xs: 3, sm: 6 },
            }}
        >
            <Box
                sx={{
                    width: {
                        sm: '100%',
                        // md: '60%'
                    },
                    textAlign: { sm: 'left', md: 'center' },
                    marginBottom: 2,
                }}
            >
                {subscriptions && subscriptions.length && (
                    <>
                        <Typography component="div" variant="body1" color="text.primary">
                            <Alert severity={'success'} sx={{ marginBottom: 2 }}>
                                Your subscription renews on:{' '}
                                <b>{`${subscriptions[0].current_period_end.split(' ')[0]} `}</b>
                            </Alert>
                        </Typography>
                    </>
                )}
                {manageData !== null && (
                    <>
                        {manageData.kind === 'billing' && (
                            <>
                                <Button
                                    fullWidth
                                    size="medium"
                                    color="primary"
                                    variant="contained"
                                    target="_blank"
                                    href={manageData.url}
                                >
                                    Manage Subscription
                                </Button>
                            </>
                        )}
                    </>
                )}
            </Box>

            <Grid
                container
                spacing={3}
                alignItems="center"
                justifyContent="center"
                aria-hidden={true}
            >
                {tiers !== null &&
                    tiers.length &&
                    tiers.map((tier) => (
                        <Grid
                            item
                            key={tier.key}
                            xs={12}
                            sm={12}
                            md={6}
                            lg={4}
                            // sm={6}
                            // md={4}
                        >
                            <Card
                                sx={{
                                    p: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 2,
                                    border: tier.preferred ? '1px solid' : undefined,
                                    borderColor: tier.preferred ? 'primary.main' : undefined,
                                    background: tier.preferred
                                        ? 'linear-gradient(#033363, #021F3B)'
                                        : undefined,
                                }}
                            >
                                <CardContent>
                                    <Box
                                        sx={{
                                            mb: 1,
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'baseline',
                                            color: tier.preferred ? 'grey.100' : '',
                                        }}
                                    >
                                        <Typography component="h3" variant="h6">
                                            {tier.title}
                                        </Typography>
                                        {tier.preferred && (
                                            <Chip
                                                icon={<AutoAwesomeIcon />}
                                                label={tier.subheader}
                                                size="small"
                                                sx={{
                                                    background: (theme) =>
                                                        theme.palette.mode === 'light'
                                                            ? ''
                                                            : 'none',
                                                    backgroundColor: 'primary.contrastText',
                                                    '& .MuiChip-label': {
                                                        color: 'primary.dark',
                                                    },
                                                    '& .MuiChip-icon': {
                                                        color: 'primary.dark',
                                                    },
                                                }}
                                            />
                                        )}
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'baseline',
                                            color: tier.preferred ? 'grey.50' : undefined,
                                        }}
                                    >
                                        <Typography component="h3" variant="h2">
                                            ${tier.price}
                                        </Typography>
                                        <Typography component="h5" variant="body2">
                                            &nbsp; per {tier.interval}
                                        </Typography>
                                    </Box>
                                    <Divider
                                        sx={{
                                            my: 2,
                                            opacity: 0.2,
                                            borderColor: 'grey.500',
                                        }}
                                    />
                                    {tier.description.map((line: any) => (
                                        <Box
                                            key={line}
                                            sx={{
                                                py: 1,
                                                display: 'flex',
                                                gap: 1.5,
                                                alignItems: 'center',
                                            }}
                                        >
                                            <CheckCircleRoundedIcon
                                                sx={{
                                                    width: 20,
                                                    color: tier.preferred
                                                        ? 'primary.light'
                                                        : 'primary.main',
                                                }}
                                            />
                                            <Typography
                                                variant="subtitle2"
                                                sx={{
                                                    color: tier.preferred ? 'grey.200' : 'inherit',
                                                }}
                                            >
                                                {line}
                                            </Typography>
                                        </Box>
                                    ))}
                                </CardContent>
                                <CardActions>
                                    <Button
                                        fullWidth
                                        variant={'contained'}
                                        component="a"
                                        onClick={async () => {
                                            let axiosResponse = await ApiService.getPaymentLink(
                                                tier.price_id,
                                            )
                                            window.open(axiosResponse.data.url, '_blank')
                                        }}
                                    >
                                        {tier.buttonText}
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
            </Grid>
        </Container>
    )
}
