import React, { useState } from 'react'
import { Button, Stack } from '@mui/material'
import getReviewStatusButtonColor from '../getReviewStatusButtonColor'
import { ReviewStatus } from '../ReviewStatus'
import getReviewStatusButtonVariant from '../getReviewStatusButtonVariant'
import JsonData from '../../Shared/JsonData'

export interface DashboardStats {
    total: number
    needs_review: number
    pursuing: number
    not_pursuing: number
    won: number
    lost: number
    needs_quote: number
    quoting: number
    sent_to_bidders: number
}

export interface DashboardStatsButtonState {
    needs_review: boolean
    pursuing: boolean
    not_pursuing: boolean
    won: boolean
    lost: boolean
    needs_quote: boolean
    quoting: boolean
    sent_to_bidders: boolean
}

export interface DashboardStatsButtonViewProps {
    values: DashboardStats
    onToggleUpdate: (buttonState: DashboardStatsButtonState) => void
}

const DashboardStatsButtonView: React.FC<DashboardStatsButtonViewProps> = ({
    values,
    onToggleUpdate,
}) => {
    const [toggledStates, setToggledStates] = useState<DashboardStatsButtonState>({
        needs_review: false,
        lost: false,
        needs_quote: false,
        not_pursuing: false,
        pursuing: false,
        quoting: false,
        sent_to_bidders: false,
        won: false,
    })

    const handleToggle = (key: keyof typeof toggledStates) => {
        setToggledStates((prevState) => ({
            ...prevState,
            [key]: !prevState[key],
        }))
        let data = toggledStates
        data[key] = !toggledStates[key]
        onToggleUpdate(data)
    }
    return (
        <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
            <Button>Total: {values.total}</Button>
            <Button
                color={getReviewStatusButtonColor(ReviewStatus.NeedsReview)}
                variant={toggledStates.needs_review ? 'contained' : 'outlined'}
                onClick={() => handleToggle(ReviewStatus.NeedsReview)}
            >
                Needs Review: {values.needs_review}
            </Button>

            <Button
                color={getReviewStatusButtonColor(ReviewStatus.Lost)}
                variant={toggledStates.lost ? 'contained' : 'outlined'}
                onClick={() => handleToggle(ReviewStatus.Lost)}
            >
                Lost: {values.lost}
            </Button>
            <Button
                color={getReviewStatusButtonColor(ReviewStatus.NeedsQuote)}
                variant={toggledStates.needs_quote ? 'contained' : 'outlined'}
                onClick={() => handleToggle(ReviewStatus.NeedsQuote)}
            >
                Needs Quote: {values.needs_quote}
            </Button>
            <Button
                color={getReviewStatusButtonColor(ReviewStatus.NotPursuing)}
                variant={toggledStates.not_pursuing ? 'contained' : 'outlined'}
                onClick={() => handleToggle(ReviewStatus.NotPursuing)}
            >
                Not Pursuing: {values.not_pursuing}
            </Button>
            <Button
                color={getReviewStatusButtonColor(ReviewStatus.Pursuing)}
                variant={toggledStates.pursuing ? 'contained' : 'outlined'}
                onClick={() => handleToggle(ReviewStatus.Pursuing)}
            >
                Pursuing: {values.pursuing}
            </Button>
            <Button
                color={getReviewStatusButtonColor(ReviewStatus.Quoting)}
                variant={toggledStates.quoting ? 'contained' : 'outlined'}
                onClick={() => handleToggle(ReviewStatus.Quoting)}
            >
                Quoting: {values.quoting}
            </Button>
            <Button
                color={getReviewStatusButtonColor(ReviewStatus.SentToBidders)}
                variant={toggledStates.sent_to_bidders ? 'contained' : 'outlined'}
                onClick={() => handleToggle(ReviewStatus.SentToBidders)}
            >
                Sent to Bidders: {values.sent_to_bidders}
            </Button>
            <Button
                color={getReviewStatusButtonColor(ReviewStatus.Won)}
                variant={toggledStates.won ? 'contained' : 'outlined'}
                onClick={() => handleToggle(ReviewStatus.Won)}
            >
                Won: {values.won}
            </Button>
        </Stack>
    )
}

export default DashboardStatsButtonView
