import * as React from 'react'
import { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import ApiService from '../../services/api.service'
import FormControl from '@mui/material/FormControl'
import { Divider, TextField } from '@mui/material'
import { Authority } from '../../services/api/models/authority'
import OrganizationSelect from './OrganizationSelect'

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
}

export default function CreateOrgUserModal() {
    const [open, setOpen] = React.useState(false)
    const [saving, setSaving] = useState(false)

    const [firstName, setFirstName] = useState<string>()
    const [lastName, setLastName] = useState<string>()
    const [email, setEmail] = useState<string>()
    const [password, setPassword] = useState<string>()
    const [organizationId, setOrganizationId] = useState<any | null>(null)

    async function handleSave() {
        // setSaving(true)
        await ApiService.addUserToOrg({
            organizationId: organizationId,
            firstName: firstName,
            lastName: lastName,
            email: email,
            password: '',
        })
        setSaving(false)
        setOpen(false)
    }

    async function load() {}

    useEffect(() => {
        load()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleOpen = () => {
        load().then((res) => {
            setOpen(true)
        })
    }
    const handleClose = () => setOpen(false)

    return (
        <div>
            <Button onClick={handleOpen} variant={'contained'}>
                Create New User
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
                        Create a new user
                    </Typography>
                    <Divider></Divider>
                    {ApiService.isInRoles([Authority.Admin]) && (
                        <>
                            <OrganizationSelect
                                onOrganizationChange={(organization) => {
                                    if (organization) {
                                        setOrganizationId(organization.id)
                                    }
                                }}
                            />
                        </>
                    )}
                    <FormControl sx={{ minWidth: 80, width: '100%', mb: 2 }}>
                        <TextField
                            label={'First Name'}
                            autoFocus
                            sx={{ mb: 1 }}
                            size={'small'}
                            disabled={saving}
                            onChange={(ev) => {
                                setFirstName(ev.target.value)
                            }}
                        ></TextField>
                        <TextField
                            label={'Last Name'}
                            sx={{ mb: 1 }}
                            disabled={saving}
                            size={'small'}
                            onChange={(ev) => {
                                setLastName(ev.target.value)
                            }}
                        ></TextField>
                        <TextField
                            label={'Email'}
                            sx={{ mb: 2 }}
                            disabled={saving}
                            size={'small'}
                            onChange={(ev) => {
                                setEmail(ev.target.value)
                            }}
                        ></TextField>
                        <Button
                            color="primary"
                            disabled={!(email || password) || saving}
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            onClick={handleSave}
                        >
                            Create
                        </Button>
                    </FormControl>
                </Box>
            </Modal>
        </div>
    )
}
