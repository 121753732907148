import * as React from 'react'
import { useEffect } from 'react'
import Paper from '@mui/material/Paper'
import { GridColDef, GridRenderCellParams, GridToolbar } from '@mui/x-data-grid'
import { DataGridPro } from '@mui/x-data-grid-pro'
import ApiService from '../../../services/api.service'
import { Box, Button } from '@mui/material'
import { Authority } from '../../../services/api/models/authority'
import UpdateUserOrgModal from '../UpdateUserOrgModal'
import { renderDataRow } from '../renderDataRow'

function renderAction(params: GridRenderCellParams<any, string>) {
    if (params.value === undefined || params.value.toString() === '') {
        return <></>
    }

    async function update(ev: any, id: string) {
        await ApiService.deleteUserById(id)
        window.location.reload()
    }

    return (
        <Button
            key={`${params.value || new Date().toString()}-view-button`}
            size="small"
            color={'error'}
            variant={'contained'}
            onClick={(ev: any) => {
                update(ev, params.value || '')
            }}
        >
            Delete
        </Button>
    )
}

// https://mui.com/material-ui/react-table/#data-table
export default function ListUsers() {
    const columns: GridColDef[] = ApiService.isInRoles([Authority.Admin])
        ? [
              {
                  field: 'id',
                  headerName: '',
                  minWidth: 100,
                  renderCell: renderAction,
              },
              {
                  field: 'username',
                  headerName: 'Username',
                  minWidth: 100,
              },
          ]
        : [
              {
                  field: 'username',
                  headerName: 'Username',
                  minWidth: 100,
              },
          ]

    const [rows, setRows] = React.useState<any[]>([])
    const [cols] = React.useState<GridColDef[]>(columns)

    useEffect(() => {
        async function load() {
            const result = await ApiService.getAllUsers()
            if (result.status === 200) {
                const rowItems = result.data
                setRows(rowItems)
                Object.keys(rowItems[0]).forEach((item) => {
                    const existing = cols.map((item) => item.field)
                    if (
                        existing.indexOf(item) === -1 &&
                        item.toString() !== 'integrations' &&
                        item.toString() !== 'organization'
                    ) {
                        cols.push({
                            field: item.toString(),
                            headerName: item.toString(),
                            minWidth: 250,
                            renderCell: renderDataRow,
                        })
                    }
                })
            }
        }

        load()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <Box sx={{ minHeight: 250 }}>
            {ApiService.isInRoles([Authority.Admin]) && <UpdateUserOrgModal></UpdateUserOrgModal>}
            {rows.length > 0 && (
                <Paper sx={{ width: '100%', overflow: 'hidden', marginBottom: 4 }}>
                    <DataGridPro
                        rows={rows}
                        columns={cols}
                        density={'compact'}
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                                csvOptions: { disableToolbarButton: true },
                                printOptions: { disableToolbarButton: true },
                            },
                        }}
                        pagination
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 5 },
                            },
                        }}
                        pageSizeOptions={[5, 250, 500]}
                    />
                </Paper>
            )}
        </Box>
    )
}
