import React from 'react'
import Box from '@mui/material/Box'
import Features from '../Examples/landing-page/components/Features'
import Divider from '@mui/material/Divider'
import Highlights from '../Examples/landing-page/components/Highlights'
import FAQ from '../Examples/landing-page/components/FAQ'
import Pricing from '../Examples/landing-page/components/Pricing'

function Home() {
    return (
        <>
            {/*<Hero/>*/}
            {/*<Box component="section" sx={{p: 2, border: '1px dashed grey'}}>*/}
            <Box sx={{ padding: 0, margin: 0 }}>
                {/*<LogoCollection/>*/}
                <Features />
                {/*<Divider/>*/}
                {/*<Testimonials/>*/}
                <Divider />
                <Highlights />
                <Divider />
                <Pricing />
                <Divider />
                <FAQ />
            </Box>
        </>
    )
}

export default Home
