import { ReviewStatus } from './ReviewStatus'
import { OverridableStringUnion } from '@mui/types'
import { ButtonPropsVariantOverrides } from '@mui/material/Button/Button'

export default function getReviewStatusButtonVariant(
    status: ReviewStatus,
): OverridableStringUnion<'text' | 'outlined' | 'contained', ButtonPropsVariantOverrides> {
    switch (status) {
        case ReviewStatus.NotPursuing:
        case ReviewStatus.Won:
        case ReviewStatus.NeedsQuote:
        case ReviewStatus.Quoting:
        case ReviewStatus.SentToBidders:
        case ReviewStatus.NeedsReview:
        case ReviewStatus.Pursuing:
        case ReviewStatus.Lost:
            return 'contained'
        // return 'outlined'
    }
}
