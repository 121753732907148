import * as React from 'react'
import { useEffect, useState } from 'react'
import {
    DataGridPro,
    DataGridProProps,
    GridColDef,
    GridCsvExportOptions,
    GridCsvGetRowsToExportParams,
    GridFilterInputValueProps,
    GridFilterOperator,
    GridGroupNode,
    gridSortedRowIdsSelector,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarFilterButton,
    useGridApiContext,
    useGridApiRef,
} from '@mui/x-data-grid-pro'
import Box from '@mui/material/Box'
import Button, { ButtonProps } from '@mui/material/Button'
import ApiService from '../../../services/api.service'
import { DataRow } from './DataRow'
import { RenderReviewCell } from './RenderReviewCell'
import useAlert from '../../Alerting/useAlert'
import { RenderViewCell } from './RenderViewCell'
import { ReviewStatus } from '../ReviewStatus'
import { toLocalDate, toLocalDateDateFirst, toLocalDateOnly } from '../../Projects/ToLocalDate'
import { MuiBaseEvent } from '@mui/x-data-grid/models/muiEvent'
import { GridCallbackDetails } from '@mui/x-data-grid/models/api/gridCallbackDetails'
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro'
import { createSvgIcon, LinearProgress, Select } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import { CustomGridTreeDataGroupingCell } from './CustomGridTreeDataGroupingCell'
import { exportBlob, getJson } from './JsonExportMenuItem'
import DashboardStatsButtonView, {
    DashboardStats,
    DashboardStatsButtonState,
} from './DashboardStatsButtonView'
import { GridFilterItem } from '@mui/x-data-grid/models/gridFilterItem'
import { GridLogicOperator } from '@mui/x-data-grid'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import JsonData from '../../Shared/JsonData'

interface CustomToolbarProps {
    showToolbarColumns?: boolean
    showToolbarFilters?: boolean
    showToolbarExport?: boolean
    toggleCollapse: (value: boolean) => Promise<void>
}

const CustomToolbar = ({
    showToolbarColumns,
    showToolbarFilters,
    showToolbarExport,
    toggleCollapse,
}: CustomToolbarProps) => {
    const apiRef = useGridApiContext()
    const [isRunning, setIsRunning] = useState(false)
    const [showToolbarDensity, setShowToolbarDensity] = useState()
    const ExportIcon = createSvgIcon(
        <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />,
        'SaveAlt',
    )

    const handleExport = (options: GridCsvExportOptions) => apiRef.current.exportDataAsCsv(options)

    const buttonBaseProps: ButtonProps = {
        color: 'primary',
        size: 'small',
        startIcon: <ExportIcon />,
        style: {
            marginBottom: 10,
        },
    }

    const getRowsWithGroups = ({ apiRef }: GridCsvGetRowsToExportParams) => {
        return gridSortedRowIdsSelector(apiRef)
    }

    let fileName = `construct-quick-est-grouped-dashboard-${toLocalDateDateFirst(new Date())}`

    return (
        <GridToolbarContainer>
            {showToolbarColumns && <GridToolbarColumnsButton />}
            {showToolbarFilters && <GridToolbarFilterButton />}
            {showToolbarDensity && (
                <GridToolbarDensitySelector slotProps={{ tooltip: { title: 'Change density' } }} />
            )}
            <Box sx={{ flexGrow: 1 }} />
            <Stack direction="row" spacing={2} sx={{ mt: 2, mr: 2 }}>
                <Button
                    variant="contained"
                    disabled={isRunning}
                    endIcon={isRunning ? <OpenInNewIcon /> : null}
                    onClick={async (event) => {
                        setIsRunning(true)
                        await toggleCollapse(false)
                        setIsRunning(false)
                    }}
                    style={{ marginBottom: 10 }}
                >
                    Collapse All
                </Button>
                <Button
                    variant="contained"
                    disabled={isRunning}
                    endIcon={isRunning ? <OpenInNewIcon /> : null}
                    onClick={async (event) => {
                        setIsRunning(true)
                        await toggleCollapse(true)
                        setIsRunning(false)
                    }}
                    style={{ marginBottom: 10 }}
                >
                    Expand All
                </Button>
                {showToolbarExport && (
                    <>
                        <Button
                            {...buttonBaseProps}
                            onClick={() =>
                                handleExport({
                                    getRowsToExport: getRowsWithGroups,
                                    fileName: fileName,
                                })
                            }
                        >
                            CSV
                        </Button>
                        <Button
                            {...buttonBaseProps}
                            onClick={() => {
                                const jsonString = getJson(apiRef)
                                const blob = new Blob([jsonString], {
                                    type: 'text/json',
                                })
                                exportBlob(blob, fileName + '.json')
                            }}
                        >
                            JSON
                        </Button>
                    </>
                )}
            </Stack>
        </GridToolbarContainer>
    )
}

const getTreeDataPath: DataGridProProps['getTreeDataPath'] = (row) => row.hierarchy

const groupingColDef: DataGridProProps['groupingColDef'] = {
    headerName: 'Project',
    flex: 0.5,
    sortable: true,
    renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} />,
}

export default function DashboardV4(props: {
    projectId?: string | undefined
    searchFilterId?: string | undefined
    showToolbarColumns?: boolean | undefined
    showToolbarFilters?: boolean | undefined
    showToolbarDensity?: boolean | undefined
    showToolbarExport?: boolean | undefined
}) {
    const apiRef: React.MutableRefObject<GridApiPro> = useGridApiRef()
    let { projectId, searchFilterId } = props
    let showToolbarColumns = props.showToolbarColumns || true
    let showToolbarFilters = props.showToolbarFilters || true
    let showToolbarDensity = props.showToolbarDensity || true
    let showToolbarExport = props.showToolbarExport || true
    const [loading, setLoading] = useState(false)
    const [rows, setRows] = useState<any[]>([])
    const [lastUpdatedAt, setLastUpdatedAt] = useState<any | null>(null)
    const [statistics, setStatistics] = useState<DashboardStats | null>(null)
    const { setAlert } = useAlert()

    const CustomFilterInput: React.FC<GridFilterInputValueProps> = (props) => {
        const { item, applyValue } = props

        const handleFilterChange = (event: any) => {
            applyValue({ ...item, value: event.target.value as string })
        }
        const projectNames = Array.from(new Set(rows.map((row) => row.project_name)))
        return (
            <Box sx={{ background: 'lightred' }}>
                <Select
                    value={item.value || ''}
                    onChange={handleFilterChange}
                    displayEmpty
                    style={{ width: '100%' }}
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    {projectNames.map((item: any) => (
                        <MenuItem value={item} key={`${item}-filter-select-project`}>
                            {item}
                        </MenuItem>
                    ))}
                </Select>
            </Box>
        )
    }

    const customFilterOperator: GridFilterOperator = {
        label: 'Equals',
        value: 'selectFromList',
        getApplyFilterFn: (filterItem, column) => {
            if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                return null
            }
            return (value, row, column, apiRef) => {
                console.log(row)
                console.log(filterItem.value)
                if (row.hierarchy) {
                    return Object.keys(row.hierarchy).indexOf(filterItem.value) !== -1
                }
                return false
            }
        },
        // getApplyFilterFn: (filterItem: GridFilterItem) => {
        //     if (!filterItem.value || filterItem.value === '') {
        //         return null
        //     }
        //     console.log(filterItem.id)
        //     console.log(filterItem)
        //     return ({ value }: { value: any }) => value === filterItem.value
        // },
        InputComponent: CustomFilterInput,
        InputComponentProps: { type: 'text' },
    }

    const columns: GridColDef<DataRow>[] = [
        {
            field: 'id',
            headerName: 'Id',
            flex: 0.0,
            // filterable: false,
            disableExport: true,
            disableColumnMenu: true,
            hideable: true,
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 0.5,
            sortable: false,
            // filterOperators: [customFilterOperator],
            valueGetter: (value: any, row) => {
                if (row?.search_filter_name) {
                    return row?.search_filter_name
                }
                try {
                    const hierarchy = row.hierarchy
                    return `${hierarchy[hierarchy.length - 1]}`
                } catch (e) {}
                return ``
            },
        },
        {
            field: 'project_bid_date',
            headerName: 'Bid Date',
            flex: 0.5,
            sortable: true,
            type: 'date',
            sortComparator: (v1: string | null, v2: string | null, cellParams1, cellParams2) => {
                if (v1 === null) return 1
                if (v2 === null) return -1
                if (cellParams1.rowNode.type === 'group' && cellParams2.rowNode.type === 'group') {
                    const children1 = apiRef.current.getRowGroupChildren({
                        groupId: cellParams1.rowNode.id,
                    })
                    const children2 = apiRef.current.getRowGroupChildren({
                        groupId: cellParams2.rowNode.id,
                    })

                    if (children1.length > 0 && children1.length > 0) {
                        const child1 = apiRef.current.getRow(children1[0])
                        const child2 = apiRef.current.getRow(children2[0])
                        return (
                            new Date(child1.project_bid_date).getTime() -
                            new Date(child2.project_bid_date).getTime()
                        )
                    }
                }
                return new Date(v1).getTime() - new Date(v2).getTime()
            },
            valueFormatter: (value: any, row, column, api) => {
                if (value === null || value === undefined) {
                    return ``
                }
                return toLocalDateOnly(value)
            },
            renderCell: (params) => {
                let thisValue = params?.value
                if (params.rowNode.depth > 0) {
                    return null
                }
                if (params.rowNode.type === 'group') {
                    const children = apiRef.current.getRowGroupChildren({
                        groupId: params.rowNode.id,
                    })
                    if (children.length > 0) {
                        const child = apiRef.current.getRow(children[0])
                        thisValue = child.project_bid_date
                    }
                }
                if (!thisValue) {
                    return null
                }
                if (
                    thisValue?.toString().toLowerCase() === 'null' ||
                    thisValue?.toString().toLowerCase() === 'nat'
                ) {
                    return null
                }

                return <>{toLocalDateOnly(thisValue)}</>
            },
            // valueGetter: (params: any) => {
            //     if (params?.value === null || params?.value === undefined) {
            //         return ``
            //     }
            //     return new Date(params.value)
            // },
        },
        // {
        //     field: 'project_bid_date',
        //     headerName: 'Bid Date',
        //     flex: 1,
        //     sortComparator: (v1: string | null, v2: string | null, cellParams1, cellParams2) => {
        //         if (v1 === null) return 1
        //         if (v2 === null) return -1
        //         return new Date(v1).getTime() - new Date(v2).getTime()
        //     },
        //     // valueGetter: (value: any, row) => {
        //     //     try {
        //     //         return new Date(toLocalDateOnly(value))
        //     //     } catch (e) {}
        //     //     return new Date(toLocalDateOnly(row.project_bid_date))
        //     // },

        // },
        {
            field: 'search_filter_category',
            headerName: 'Category',
            flex: 0.8,
            sortable: true,
            disableExport: false,
            valueGetter: (value: any, row) => {
                try {
                    return value
                } catch (e) {}
                return ``
            },
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 0.8,
            sortable: false,
            valueParser: (params) => {
                return params
            },
            valueGetter: (params) => {
                if (params) {
                    return params
                }
                return <></>
            },
            renderCell: (params) => (
                <RenderReviewCell
                    params={params}
                    callback={function callbackNow(params) {
                        setAlert('Updating review status...', 'info')
                        load().then((res) => {
                            setAlert('Updated review status', 'success')
                        })
                        // setAlert('Updating review status...', 'info')
                        // getNewRows().then((rows) => {
                        //     setRows(rows)
                        //     setAlert('Updated review status', 'success')
                        // })
                    }}
                />
            ),
        },
        {
            field: 'view',
            headerName: 'Preview',
            flex: 0.5,
            disableExport: true,
            sortable: false,
            // renderCell: (params) => <RenderReviewStatusCell {...params} />,
            renderCell: (params) => (
                <RenderViewCell
                    params={params}
                    onOpen={() => {
                        console.log(params)
                        setLoading(true)
                    }}
                    onClose={() => {
                        setLoading(false)
                    }}
                />
            ),
        },

        {
            field: 'review_date',
            headerName: 'Review Date',
            flex: 1,
            renderCell: (params) => {
                if (params.rowNode.type === 'group' || params?.value?.length === 0) {
                    return <></>
                }
                if (params?.value) {
                    if (params.value.toString().toLowerCase() === 'nat') {
                        return <></>
                    }
                    return <>{toLocalDate(params.value)}</>
                }
                return <></>
            },
        },

        {
            field: 'reviewer',
            headerName: 'Reviewer',
            flex: 1,
            sortable: false,
            renderCell: (params) => {
                if (params.rowNode.type === 'group' || params?.value?.length === 0) {
                    return <></>
                }
                if (params?.value) {
                    return <>{params.value}</>
                }
            },
        },
        {
            field: 'reviewer_comment',
            headerName: 'Comment',
            flex: 1,
            sortable: false,
            renderCell: (params) => {
                if (params.rowNode.type === 'group' || params?.value?.length === 0) {
                    return <></>
                }
                if (params?.value) {
                    return <>{params.value}</>
                }
            },
        },

        {
            field: 'terms',
            headerName: 'Terms',
            flex: 1,
            sortable: false,
        },
    ]

    async function load() {
        if (loading) {
            return
        }
        setLoading(true)
        const response = await ApiService.getDashboardV4(projectId, searchFilterId)
        const data: DataRow[] = []
        const existingIds: any = {}
        if (response.status === 200) {
            const results: any[] = response.data
            for (const result of results) {
                const row: DataRow = {
                    date_updated: result.search_result_date_updated,
                    hierarchy: [
                        result.project_name,
                        result.search_filter_category,
                        result.search_filter_name,
                    ],
                    hits: 0,
                    project_id: result.project_id,
                    project_name: result.project_name.trimStart().trimEnd(),
                    search_filter_id: result.search_filter_id,
                    search_filter_name: result.search_filter_name,
                    search_filter_category: result.search_filter_category,
                    project_bid_date: new Date(
                        result?.project_bid_date || new Date(1970, 1, 1).toDateString(),
                    ),
                    terms: result?.search_result_terms,
                    reviews: result?.reviews,
                    review_date: result?.search_filter_review_date_updated,
                    // reviewer: result?.reviews,
                    reviewer: result?.user_email,
                    reviewer_comment: result?.search_filter_review_message,
                    status:
                        result?.search_filter_review_status || ReviewStatus.NeedsReview.toString(),
                    view: result,
                    id: `${result.project_name}/${result.search_filter_category}/${result.search_filter_name}`,
                }
                if (Object.keys(existingIds).indexOf(row.id) !== -1) {
                    console.log('row already exists', row)
                    continue
                }
                if (projectId !== undefined && projectId !== null) {
                    if (projectId !== row.project_id) {
                        continue
                    }
                }
                existingIds[row.id] = {}
                data.push(row)
            }
            data.sort((a: any, b: any) => {
                if (a.project_bid_date === null || b.project_bid_date === null) {
                    return 0
                }
                if (a.project_bid_date < b.project_bid_date) {
                    return -1
                }
                if (a.project_bid_date > b.project_bid_date) {
                    return 1
                }
                return 0
            }).reverse()
            setRows(data)
            const updatedRows = data
            updatedRows
                .sort((a: any, b: any) => {
                    if (a.date_updated === null || b.date_updated === null) {
                        return 0
                    }
                    if (a.date_updated < b.date_updated) {
                        return -1
                    }
                    if (a.date_updated > b.date_updated) {
                        return 1
                    }
                    return 0
                })
                .reverse()
            if (updatedRows.length > 0) {
                setLastUpdatedAt(updatedRows[0].date_updated)
            }
            let stats: DashboardStats = {
                total: data.length,
                lost: data.filter((x) => x.status === ReviewStatus.Lost).length,
                needs_quote: data.filter((x) => x.status === ReviewStatus.NeedsQuote).length,
                needs_review: data.filter((x) => x.status === ReviewStatus.NeedsReview).length,
                not_pursuing: data.filter((x) => x.status === ReviewStatus.NotPursuing).length,
                pursuing: data.filter((x) => x.status === ReviewStatus.Pursuing).length,
                quoting: data.filter((x) => x.status === ReviewStatus.Quoting).length,
                sent_to_bidders: data.filter((x) => x.status === ReviewStatus.SentToBidders).length,
                won: data.filter((x) => x.status === ReviewStatus.Won).length,
            }
            setStatistics(stats)
            console.log(stats)
        }
        setLoading(false)
    }

    useEffect(() => {
        load().catch(console.error)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function handleRowClick(params: any, event: MuiBaseEvent, details: GridCallbackDetails) {
        const rowNode = apiRef.current.getRowNode(params.id)
        const row = apiRef.current.getRow(params.id)
        if (rowNode && rowNode?.type) {
            switch (rowNode.type) {
                case 'footer':
                    break
                case 'leaf':
                    break
                case 'group':
                    if (rowNode.groupingKey) {
                        let expansions = JSON.parse(localStorage.getItem('expansion-depth') || '{}')
                        const newValue = !rowNode.childrenExpanded
                        const children = apiRef.current.getRowGroupChildren({
                            groupId: rowNode?.id || '',
                        })
                        for (const childId of children) {
                            const child = apiRef.current.getRowNode(childId)
                            if (child?.type === 'group') {
                                apiRef.current.setRowChildrenExpansion(childId, newValue)
                                expansions[childId] = newValue
                            }
                        }
                        apiRef.current.setRowChildrenExpansion(rowNode.id, newValue)
                        expansions[rowNode.id] = newValue
                        localStorage.setItem('expansion-depth', JSON.stringify(expansions))
                    }
                    break
                case 'pinnedRow':
                    break
                case 'skeletonRow':
                    break
            }
        }
    }

    async function toggleCollapse(shouldExpand: boolean) {
        setLoading(true)
        if (apiRef.current) {
            let expansions = JSON.parse(localStorage.getItem('expansion-depth') || '{}')
            // if (!shouldExpand) {
            //     localStorage.setItem('expansion-depth', JSON.stringify(expansions))
            // }

            rows.forEach((row) => {
                const node = apiRef.current.getRowNode<any>(row.id)
                expansions[node.id] = shouldExpand
                if (node.parent) {
                    const parent = apiRef.current.getRowNode<any>(node.parent)
                    apiRef.current.setRowChildrenExpansion(parent.id, shouldExpand)
                    expansions[parent.id] = shouldExpand
                    if (node.parent) {
                        const grandParent = apiRef.current.getRowNode<any>(parent.parent)
                        apiRef.current.setRowChildrenExpansion(grandParent.id, shouldExpand)
                        expansions[grandParent.id] = shouldExpand
                    }
                }
                if (node && node.childrenExpanded) {
                    apiRef.current.setRowChildrenExpansion(row.id, shouldExpand)
                    expansions[row.id] = shouldExpand
                }
            })
            if (!shouldExpand) {
                localStorage.setItem('expansion-depth', JSON.stringify(expansions))
            }
        }
        setLoading(false)
    }

    return (
        <div style={{ minHeight: 1000, width: '100%' }}>
            <Box sx={{ minHeight: 10 }}>
                <Box sx={{ mb: 1, mt: 1 }}>{loading && <LinearProgress></LinearProgress>}</Box>
            </Box>
            <Box sx={{ minHeight: 50 }}>
                {statistics && (
                    <DashboardStatsButtonView
                        values={statistics}
                        onToggleUpdate={(buttonStates: DashboardStatsButtonState) => {
                            const items: GridFilterItem[] = []
                            const lookup = JSON.parse(JSON.stringify(buttonStates))
                            for (const key of Object.keys(lookup)) {
                                if (lookup[key]) {
                                    items.push({
                                        id: items.length + 1,
                                        field: 'status',
                                        operator: 'equals',
                                        value: key,
                                    })
                                }
                            }

                            apiRef.current.setFilterModel(
                                {
                                    items: [],
                                },
                                'removeAllFilterItems',
                            )

                            if (items.length > 0) {
                                apiRef.current.setFilterModel(
                                    {
                                        items: items,
                                        logicOperator: GridLogicOperator.Or,
                                    },
                                    'upsertFilterItems',
                                )
                            } else {
                                apiRef.current.setFilterModel(
                                    {
                                        items: [],
                                    },
                                    'removeAllFilterItems',
                                )
                            }
                        }}
                    />
                )}
            </Box>
            <Box sx={{ minHeight: 10 }}>
                <Box sx={{ mb: 1, mt: 1 }}>
                    {!loading && rows.length > 0 && lastUpdatedAt && (
                        <>
                            <b>{rows.length} Search Results</b>{' '}
                            <small>updated at {toLocalDate(lastUpdatedAt)}</small>
                        </>
                    )}
                </Box>
            </Box>
            <DataGridPro
                treeData
                rows={rows}
                columns={columns}
                getTreeDataPath={getTreeDataPath}
                groupingColDef={groupingColDef}
                loading={loading}
                defaultGroupingExpansionDepth={1}
                isGroupExpandedByDefault={function (node: GridGroupNode) {
                    const expansions = JSON.parse(localStorage.getItem('expansion-depth') || '{}')
                    const nodeId = (node?.id || '').toString()
                    if (Object.keys(expansions).indexOf(nodeId) !== -1) {
                        return expansions[node?.id]
                    }
                    return node.depth === 0
                }}
                onRowClick={handleRowClick}
                apiRef={apiRef}
                hideFooterPagination={true}
                hideFooterRowCount={true}
                hideFooterSelectedRowCount={true}
                hideFooter={true}
                slots={{
                    toolbar: () => {
                        return CustomToolbar({
                            async toggleCollapse(value: boolean): Promise<void> {
                                await toggleCollapse(value)
                            },
                            showToolbarColumns: true,
                            showToolbarFilters: true,
                            showToolbarExport: true,
                        })
                    },
                }}
                // rowHeight={38}
                disableRowSelectionOnClick={true}
                density={'compact'}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                    },
                }}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            id: false,
                            search_filter_category: false,
                        },
                    },
                    // pinnedColumns: { left: ['project_bid_date'] },
                    // sorting: {
                    //     sortModel: [{ field: 'project_bid_date', sort: 'desc' }],
                    // },
                }}
            />
        </div>
    )
}
