import * as React from 'react'
import { useEffect } from 'react'
import Paper from '@mui/material/Paper'
import ApiService from '../../../services/api.service'
import { GridColDef, GridFilterModel, GridToolbar } from '@mui/x-data-grid'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { Alert, CircularProgress, Container, Link, Typography, Button } from '@mui/material'
import Stack from '@mui/material/Stack'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { timeSince } from '../../Projects/ToLocalDate'

export default function ListProjectSearchResults() {
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const [filterModel, setFilterModel] = React.useState<GridFilterModel>({ items: [] })
    const [rows, setRows] = React.useState<any[]>([])
    const [loading, setLoading] = React.useState(false)
    const [noData, setNoData] = React.useState<any>(null)
    const [paginationModel, setPaginationModel] = React.useState({ page: 0, pageSize: 25 })
    const [rowCount, setRowCount] = React.useState(0) // Total number of rows

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'Id',
            flex: 1,
        },
        {
            field: 'project',
            headerName: 'Project',
            flex: 1,
            renderCell: (params) => (
                <Link
                    component="button"
                    variant="body2"
                    onClick={() => navigate(`/projects/${params.row.project_id}/edit`)}
                >
                    {params.row.project_name}
                </Link>
            ),
        },
        {
            field: 'search_filter',
            headerName: 'Search Filter',
            flex: 1,
            renderCell: (params) => (
                <Link
                    component="button"
                    variant="body2"
                    onClick={() => navigate(`/search-filters/${params.row.search_filter_id}/edit`)}
                >
                    {params.row.search_filter_name}
                </Link>
            ),
        },
        {
            field: 'search_filter_term_name',
            headerName: 'term',
            flex: 1,
            filterable: true,
        },
        {
            field: 'upload',
            headerName: 'Upload',
            flex: 1,
            renderCell: (params) => (
                <Link
                    component="button"
                    variant="body2"
                    onClick={() => {
                        const url = ApiService.viewUploadUrl(params.row.upload_id)
                        window.open(url, '_blank')
                    }}
                >
                    {params.row.upload_name}
                </Link>
            ),
            valueGetter: (params: any, row: any) => row.upload_name,
        },
        {
            field: 'page_number',
            headerName: 'Page',
            flex: 0.25,
            renderCell: (params) => (
                <Link
                    component="button"
                    variant="body2"
                    onClick={() => {
                        const url = ApiService.viewUploadUrl(
                            params.row.upload_id,
                            params.row.page_number,
                        )
                        window.open(url, '_blank')
                    }}
                >
                    {params.row.page_number}
                </Link>
            ),
            valueGetter: (params: any, row: any) => row.page_number,
        },
        {
            field: 'time_since_updated',
            headerName: 'Updated',
            flex: 1,
            renderCell: (params) => {
                const dateUpdated = params.row.search_result_date_updated
                return timeSince(dateUpdated).html
            },
        },
        {
            field: 'search_result_date_created',
            headerName: 'Search Result Date Created',
            flex: 1,
            filterable: true,
        },
        {
            field: 'search_filter_review_status',
            headerName: 'Review Status',
            flex: 1,
            filterable: true,
        },
    ]

    const parseFiltersFromURL = () => {
        const items: any = Array.from(searchParams.entries()).map(([field, value]) => ({
            field: field,
            operator: 'contains',
            operatorValue: 'contains',
            value: value,
        }))
        setFilterModel({ items: items || [] })
    }

    const updateURLWithFilters = (newFilterModel: GridFilterModel) => {
        const newParams = new URLSearchParams()
        newFilterModel.items.forEach((item) => {
            if (item.value) {
                newParams.set(item.field, item.value.toString())
            }
        })
        setSearchParams(newParams)
    }

    useEffect(() => {
        parseFiltersFromURL()
        load(paginationModel.page, paginationModel.pageSize)
        // eslint-disable-next-line
    }, [paginationModel])

    const load = async (page: number, pageSize: number) => {
        setLoading(true)
        const result = await ApiService.getProjectSearchResults(page, pageSize)
        if (result.status === 200) {
            const resultJson: any = result.data
            const data = resultJson.data
            const total = resultJson.total
            if (data.length === 0) {
                setNoData(true)
            }
            setRows(data)
            setRowCount(total)
        }
        setLoading(false)
    }

    const handleFilterChange = (newFilterModel: GridFilterModel) => {
        setFilterModel(newFilterModel)
        updateURLWithFilters(newFilterModel)
    }

    const handlePaginationModelChange = (newPaginationModel: any) => {
        setPaginationModel(newPaginationModel)
    }

    return (
        <Container sx={{ minHeight: 250 }} maxWidth={'xl'}>
            {rows.length === 0 && noData === null && (
                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 10 }}>
                    <CircularProgress />
                </div>
            )}
            {rows.length === 0 && noData && (
                <Container>
                    <Alert severity="info">
                        <div>No search results found.</div>
                    </Alert>
                </Container>
            )}
            <Stack spacing={2} mb={2}>
                {rows.length > 0 && (
                    <>
                        <Typography variant={'h2'} mb={2}>
                            Total Results {rows.length}
                        </Typography>
                        <Button
                            variant={'contained'}
                            onClick={async () => {
                                const res = await ApiService.refreshProjectSearchResults()
                                console.log(res.data)
                            }}
                        >
                            Refresh Results
                        </Button>
                    </>
                )}
            </Stack>
            {rows.length > 0 && (
                <Paper sx={{ width: '100%', overflow: 'hidden', marginBottom: 4 }}>
                    <DataGridPro
                        rows={rows}
                        columns={columns}
                        density={'compact'}
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                                csvOptions: { disableToolbarButton: true },
                                printOptions: { disableToolbarButton: true },
                            },
                        }}
                        filterModel={filterModel}
                        onFilterModelChange={handleFilterChange}
                        pagination
                        paginationMode="server"
                        paginationModel={paginationModel}
                        onPaginationModelChange={handlePaginationModelChange}
                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                    id: false,
                                },
                            },
                        }}
                        columnVisibilityModel={{
                            id: false,
                        }}
                        pageSizeOptions={[25, 100, 250, 500]}
                        loading={loading}
                        rowCount={rowCount}
                    />
                </Paper>
            )}
        </Container>
    )
}
