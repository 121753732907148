import * as React from 'react'
import AddNewProjectButton from './AddNewProjectButton'
import Box from '@mui/material/Box'
import ProjectsList from './List/ProjectsList'

export default function Projects() {
    return (
        <Box sx={{ p: 2 }}>
            <Box sx={{ mb: 2 }}>
                <AddNewProjectButton></AddNewProjectButton>
            </Box>
            <ProjectsList></ProjectsList>
        </Box>
    )
}
