import { GridRenderCellParams } from '@mui/x-data-grid'
import { SearchFilterReview } from '../../../../services/api/models/projects'
import { Button } from '@mui/material'
import getReviewStatusButtonColor from '../../getReviewStatusButtonColor'
import { ReviewStatus } from '../../ReviewStatus'
import * as React from 'react'
import getReviewStatusButtonVariant from '../../getReviewStatusButtonVariant'

export function RenderReviewsCell({
    params,
}: {
    params: GridRenderCellParams<any, SearchFilterReview[]>
}) {
    if (params.value === undefined || params.value.toString() === '') {
        if (params.rowNode.type === 'leaf') {
            return (
                <Button
                    key={`status-button-${params.id}`}
                    size="small"
                    color={getReviewStatusButtonColor(ReviewStatus.NeedsReview)}
                    sx={{ width: 100 }}
                    variant={getReviewStatusButtonVariant(ReviewStatus.NeedsReview)}
                >
                    {ReviewStatus.NeedsReview.toString().replace('_', ' ')}
                </Button>
            )
        }
        return <></>
    }
    let searchFilterReview = params.value[params.value.length - 1]
    return (
        <Button
            key={`status-button-${searchFilterReview.project_id}-${searchFilterReview.search_filter_id}`}
            size="small"
            color={getReviewStatusButtonColor(searchFilterReview.status)}
            sx={{ width: 100 }}
            variant={getReviewStatusButtonVariant(searchFilterReview.status)}
        >
            {searchFilterReview.status.toString().replace('_', ' ')}
        </Button>
    )
}
