// @flow
import * as React from 'react'
import EditableTextField from '../../../Text/EditableTextField'
import ApiService from '../../../../services/api.service'
import useAlert from '../../../Alerting/useAlert'
import ProjectBidDatePicker from '../ProjectBidDatePicker'
import { Box, Typography } from '@mui/material'
import ProjectDollarAmountSelector from '../ProjetDollarAmountSelector'
import numberWithCommas from '../../../Shared/NumberWithCommas'

type Props = {
    project: any
}
export const ProjectInfoSquare = (props: Props) => {
    const { setAlert } = useAlert()

    return (
        <>
            <Box sx={{ mb: 2, textAlign: 'left' }}>
                <EditableTextField
                    value={props.project.name}
                    debug={false}
                    label={'Project Name'}
                    onChange={(value: any) => {
                        ApiService.updateProject({ id: props.project.id || '', name: value }).then(
                            (res) => {
                                setAlert(`updated project name "${value}"`, 'success')
                            },
                        )
                    }}
                ></EditableTextField>
            </Box>
            {props?.project?.address?.line_1 && (
                <Box sx={{ mb: 2 }}>
                    <Typography variant={'subtitle1'} textAlign={'left'}>
                        {props.project.address.line_1}
                    </Typography>
                </Box>
            )}
            {/*<Box sx={{ mb: 2 }}>*/}
            {/*    <EditableTextField*/}
            {/*        value={props.project.alternate_name}*/}
            {/*        debug={false}*/}
            {/*        label={'Alternate Name'}*/}
            {/*        onChange={(value: any) => {*/}
            {/*            ApiService.updateProject({*/}
            {/*                id: props.project.id || '',*/}
            {/*                alternateName: value,*/}
            {/*            }).then((res) => {*/}
            {/*                setAlert(`updated project alternate name "${value}"`, 'success')*/}
            {/*            })*/}
            {/*        }}*/}
            {/*    ></EditableTextField>*/}
            {/*</Box>*/}

            <Box sx={{ mb: 3 }}>
                <ProjectDollarAmountSelector
                    dollarAmount={props.project['dollar_amount']}
                    projectId={props.project.id}
                    projectName={props.project.name}
                    onChange={(value) => {
                        setAlert(
                            `updated project dollar amount to $${numberWithCommas(value)}`,
                            'success',
                        )
                    }}
                ></ProjectDollarAmountSelector>
            </Box>
            <Box sx={{ mb: 2 }}>
                <ProjectBidDatePicker
                    project={props.project}
                    onChange={(value) => {
                        setAlert(`updated project bid date to ${value}`, 'success')
                    }}
                ></ProjectBidDatePicker>
            </Box>
        </>
    )
}
