import React from 'react'
import SignIn from './SignIn'
import { useAuth0 } from '@auth0/auth0-react'
import Loading from '../../utils/Loading'
import Home from '../Home/Home'

function LoginForm() {
    const { isLoading, error, isAuthenticated } = useAuth0()

    if (error) {
        return <div>Oops... {error.message}</div>
    }

    if (isLoading) {
        return <Loading />
    }

    if (!isLoading && isAuthenticated) {
        return <Home></Home>
    }

    return (
        <>
            <SignIn kind={'login'}></SignIn>
        </>
    )
}

export default LoginForm
