import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import ReactGA from 'react-ga'
import { Config } from './config/config'
import { LicenseInfo } from '@mui/x-license-pro'
import { getConfig } from './config'
import { Auth0Provider } from '@auth0/auth0-react'
import history from './utils/history'

import AlertProvider from './components/Alerting/AlertProvider'

if (Config.AnalyticsEnabled) {
    ReactGA.initialize(Config.AnalyticsTag, {
        debug: true,
    })
}
LicenseInfo.setLicenseKey(
    'd23359d7f2a93666c1911584cb3bb2c5Tz04NTgxNixFPTE3NDEyODY4MzYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
)

const onRedirectCallback = (appState: any) => {
    console.log(appState)
    history.push(appState && appState.returnTo ? appState.returnTo : window.location.pathname)
}

// Please see https://auth0.github.io/auth0-react/interfaces/Auth0ProviderOptions.html
// for a full list of the available properties on the provider
const config = getConfig()

const providerConfig = {
    domain: config.domain,
    clientId: config.clientId,
    onRedirectCallback,
    authorizationParams: {
        redirect_uri: window.location.origin,
        ...(config.audience ? { audience: config.audience } : null),
    },
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <Auth0Provider {...providerConfig}>
        {/*<ThemeProvider theme={theme}>*/}
        <AlertProvider>
            <App />
        </AlertProvider>
        {/*</ThemeProvider>*/}
    </Auth0Provider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
