import axios, { InternalAxiosRequestConfig } from 'axios'
import { Config } from './config/config'

const axiosApiInstance = axios.create({
    baseURL: Config.ApiUrl,
    headers: {
        Accept: 'application/json',
    },
})

// Add a request interceptor
axiosApiInstance.interceptors.request.use(
    function (config: InternalAxiosRequestConfig<any>) {
        // Do something before request is sent
        config.withCredentials = false
        config.xsrfCookieName = 'session'
        if (!config.headers.has('Authorization') && localStorage.getItem('token') !== null) {
            const authHeader = `Bearer ${localStorage.getItem('token')}`
            config.headers.set('Authorization', authHeader)
            axiosApiInstance.defaults.headers.common.Authorization = authHeader
        }
        return config
    },
    function (error) {
        // Do something with request error
        console.error('request error')
        console.error(error)
        return Promise.reject(error)
    },
)

axiosApiInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        console.error(error)
        let statusCode = 0
        try {
            if (error.response !== undefined && error.response.status !== undefined) {
                statusCode = error.response.status
            }
        } catch (e) {
            console.warn(e)
        }
        switch (statusCode) {
            case 401:
            case 403:
                console.warn('unauthorized')
                break
            case 404:
                console.warn('not found')
                break
            default:
                throw error
        }
    },
)

export default axiosApiInstance
