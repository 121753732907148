import { GridRenderCellParams } from '@mui/x-data-grid'
import { Tooltip } from '@mui/material'
import Chip from '@mui/material/Chip'
import * as React from 'react'

export function RenderSearchGroupCell({
    params,
    callback,
}: {
    params: GridRenderCellParams<any, any>
    callback: () => void
}) {
    if (params.value === undefined || params.value.toString() === '') {
        return <></>
    }

    const handleClick = () => {
        console.info(`clicked chip: ${params.row['search_filter_id']}`)
        callback()
    }

    return (
        <>
            <Tooltip title={`Go to ${params.value} search filter`}>
                <Chip
                    key={params.value}
                    label={params.value}
                    sx={{
                        padding: 0,
                        width: '100%',
                        textTransform: 'unset',
                        wordWrap: 'revert',
                    }}
                    variant={'outlined'}
                    onClick={handleClick}
                />
            </Tooltip>
        </>
    )
}
