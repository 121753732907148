import moment from 'moment'
import { number } from 'yup'

export function toLocalDate(date: any) {
    try {
        if (typeof date !== typeof Date) {
            date = new Date(date)
        }

        const newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000)

        const offset = date.getTimezoneOffset() / 60
        const hours = date.getHours()

        newDate.setHours(hours - offset)

        return newDate.toLocaleTimeString('en-US') + ' on ' + newDate.toLocaleDateString('en-US')
    } catch (e) {
        console.warn(e)
    }
    return ''
}

export function toLocalDateDateFirst(date: any) {
    try {
        if (typeof date !== typeof Date) {
            date = new Date(date)
        }

        const newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000)

        const offset = date.getTimezoneOffset() / 60
        const hours = date.getHours()

        newDate.setHours(hours - offset)

        return newDate.toLocaleDateString('en-US') + ' at ' + newDate.toLocaleTimeString('en-US')
    } catch (e) {
        console.warn(e)
    }
    return ''
}

export function toLocalDateOnly(date: any) {
    try {
        return toLocalDateObjectOnly(date).toLocaleDateString('en-US')
    } catch (e) {
        console.warn(e)
    }
    return ''
}

interface TimeSince {
    days: number
    hours: number
    minutes: number
    seconds: number
    html: any
}

export function timeSince(date: any): TimeSince {
    try {
        const parsedMomentRaw = moment(date, 'YYYY-MM-DD HH:mm:ss.SSSSSS')
        const parsedMoment = moment(parsedMomentRaw).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
        const moment1 = moment(parsedMoment).local()
        const moment2 = moment(moment.now()).local()
        const diffInSeconds = moment2.diff(moment1, 'seconds')
        const diffInMinutes = moment2.diff(moment1, 'minutes')
        const diffInHours = moment2.diff(moment1, 'hours')
        const diffInDays = moment2.diff(moment1, 'days')
        const days = diffInDays
        const hours = diffInHours
        const minutes = diffInMinutes
        const seconds = diffInSeconds
        let html: any = ''
        if (diffInDays > 0) {
            html = <>{diffInDays} days ago</>
        } else if (diffInHours > 0) {
            html = <>{diffInHours} hours ago</>
        } else if (diffInMinutes > 0) {
            html = (
                <>
                    {diffInMinutes} min {diffInSeconds - diffInMinutes * 60} s ago
                </>
            )
        } else if (diffInSeconds > 0) {
            html = <>{diffInSeconds} seconds ago</>
        }

        return {
            days: days,
            hours: hours,
            html: html,
            minutes: minutes,
            seconds: seconds,
        }
    } catch (e) {
        console.warn(e)
    }
    return {
        days: 0,
        hours: 0,
        html: `error!`,
        minutes: 0,
        seconds: 0,
    }
}

export function toLocalDateObjectOnly(date: any) {
    try {
        if (typeof date !== typeof Date) {
            date = new Date(date)
        }

        const newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000)

        const offset = date.getTimezoneOffset() / 60
        const hours = date.getHours()

        newDate.setHours(hours - offset)

        return newDate
    } catch (e) {
        console.warn(e)
    }
    return new Date()
}
