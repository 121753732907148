import { FC, type ReactNode, useState } from 'react'
import AlertContext from './AlertContext'
import { AlertColor } from '@mui/material/Alert/Alert'

const ALERT_TIME = 5000

interface AlertProviderProps {
    children: ReactNode
}

const AlertProvider: FC<AlertProviderProps> = ({ children }) => {
    const [text, setText] = useState('')
    const [type, setType] = useState<AlertColor>('success')

    const setAlert = (text: string, type: AlertColor) => {
        setText(text)
        setType(type)

        setTimeout(() => {
            setText('')
            setType('success')
        }, ALERT_TIME)
    }

    return (
        <AlertContext.Provider
            value={{
                text,
                type,
                setAlert,
            }}
        >
            {children}
        </AlertContext.Provider>
    )
}
export default AlertProvider
