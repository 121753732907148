import * as React from 'react'
import FormControl from '@mui/material/FormControl'
import { TextField } from '@mui/material'
import ApiService from '../../../services/api.service'
import { NumericFormat, NumericFormatProps } from 'react-number-format'

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void
    name: string
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
    function NumericFormatCustom(props, ref) {
        const { onChange, ...other } = props

        return (
            <NumericFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    })
                }}
                thousandSeparator
                valueIsNumericString
                prefix="$"
            />
        )
    },
)

export default function ProjectDollarAmountSelector({
    projectId,
    projectName,
    dollarAmount,
    onChange,
}: {
    projectId: any
    projectName: any
    dollarAmount: any
    onChange: (value: any) => void
}) {
    const [value, setValue] = React.useState<number>(dollarAmount || 0)
    const [values, setValues] = React.useState({
        numberformat: dollarAmount || 0,
    })

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        })

        if (parseFloat(event.target.value)) {
            setValue(parseFloat(event.target.value))
        }
    }

    function onKeyPress(e: any) {
        if (e.keyCode === 13) {
            ApiService.updateProject({
                id: projectId,
                name: projectName,
                address: null,
                bidDate: null,
                dollarAmount: value,
            }).catch(console.error)
            onChange(value)
            // put the login here
        }
    }

    return (
        <>
            <FormControl fullWidth variant="standard">
                <TextField
                    label="Dollar Amount"
                    size={'medium'}
                    value={values.numberformat}
                    onChange={handleChange}
                    onKeyDown={onKeyPress}
                    name="numberformat"
                    id="formatted-numberformat-input"
                    InputProps={{
                        inputComponent: NumericFormatCustom as any,
                    }}
                    variant="standard"
                    onBlur={async () => {
                        await ApiService.updateProject({
                            id: projectId,
                            name: projectName,
                            address: null,
                            bidDate: null,
                            dollarAmount: value,
                        })
                        onChange(value)
                    }}
                />
            </FormControl>
        </>
    )
}
