import * as React from 'react'
import { useEffect } from 'react'
import Paper from '@mui/material/Paper'
import { GridColDef, GridRenderCellParams, GridToolbar } from '@mui/x-data-grid'
import { DataGridPro } from '@mui/x-data-grid-pro'
import ApiService from '../../../services/api.service'
import { Box } from '@mui/material'
import { Authority } from '../../../services/api/models/authority'

// https://mui.com/material-ui/react-table/#data-table
function renderUsers(params: GridRenderCellParams<any, any>) {
    if (params.value === undefined || params.value === null) {
        return <></>
    }
    if (true) {
        return <>{params.value.length}</>
    }
    return (
        <>
            {params.value.map((item: any) => (
                <Box key={item.id}>
                    {item.authority} | {item.email}
                </Box>
            ))}
        </>
    )
}

export default function ListOrgs() {
    const columns: GridColDef[] = ApiService.isInRoles([Authority.Admin])
        ? [
              {
                  field: 'id',
                  headerName: 'ID',
                  minWidth: 100,
              },
              {
                  field: 'name',
                  headerName: 'Name',
                  minWidth: 100,
              },
              {
                  field: 'users',
                  headerName: 'Users',
                  minWidth: 250,
                  renderCell: renderUsers,
              },
          ]
        : [
              {
                  field: 'id',
                  headerName: '',
                  minWidth: 100,
              },
              {
                  field: 'name',
                  headerName: 'Name',
                  minWidth: 100,
              },
              {
                  field: 'users',
                  headerName: 'Users',
                  minWidth: 250,
                  renderCell: renderUsers,
              },
          ]

    const [rows, setRows] = React.useState<any[]>([])
    const [cols] = React.useState<GridColDef[]>(columns)

    useEffect(() => {
        async function load() {
            const result = await ApiService.getAllOrganizations()
            if (result.status === 200) {
                const rowItems = result.data
                setRows(rowItems)
            }
        }

        load()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <Box sx={{ minHeight: 250 }}>
            {rows.length > 0 && (
                <Paper sx={{ width: '100%', overflow: 'hidden', marginBottom: 4 }}>
                    <DataGridPro
                        rows={rows}
                        columns={cols}
                        density={'compact'}
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                                csvOptions: { disableToolbarButton: true },
                                printOptions: { disableToolbarButton: true },
                            },
                        }}
                        pagination
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 5 },
                            },
                        }}
                        pageSizeOptions={[5, 250, 500]}
                    />
                </Paper>
            )}
        </Box>
    )
}
