import {
    gridFilteredDescendantCountLookupSelector,
    GridRenderCellParams,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid-pro'
import Button, { ButtonProps } from '@mui/material/Button'
import Box from '@mui/material/Box'
import * as React from 'react'
import JsonData from '../../Shared/JsonData'

export function CustomGridTreeDataGroupingCell(props: GridRenderCellParams) {
    const { id, field, rowNode } = props
    const apiRef = useGridApiContext()
    const filteredDescendantCountLookup = useGridSelector(
        apiRef,
        gridFilteredDescendantCountLookupSelector,
    )
    const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0

    const handleClick: ButtonProps['onClick'] = (event) => {
        if (rowNode.type !== 'group') {
            return
        }
        const expansions = JSON.parse(localStorage.getItem('expansion-depth') || '{}')
        expansions[id] = !rowNode.childrenExpanded
        localStorage.setItem('expansion-depth', JSON.stringify(expansions))
        apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded)
        apiRef.current.setCellFocus(id, field)
        event.stopPropagation()
    }
    let data: any = {}
    if (filteredDescendantCount) {
        data = JSON.parse(JSON.stringify(rowNode))
    }
    let totalChildren = 0
    if (rowNode.type === 'group') {
        totalChildren = filteredDescendantCountLookup[rowNode.id]
    }
    return (
        <Box sx={{ ml: rowNode.depth * 2 }}>
            <div>
                {filteredDescendantCount > 0 ? (
                    <>
                        <Button
                            onClick={handleClick}
                            tabIndex={-1}
                            size="small"
                            sx={{
                                textTransform: 'none',
                                fontWeight: rowNode.depth === 0 ? 800 : 400,
                            }}
                        >
                            {data.groupingKey} {rowNode.depth === 1 && <>({totalChildren})</>}
                        </Button>
                    </>
                ) : (
                    <span />
                )}
            </div>
        </Box>
    )
}
