import * as React from 'react'
import { useEffect } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import ApiService from '../../services/api.service'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { Authority } from '../../services/api/models/authority'

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
}

export default function UpdateUserOrgModal() {
    const [open, setOpen] = React.useState(false)
    const [users, setUsers] = React.useState<any[]>([])
    const [organizations, setOrganizations] = React.useState<any[]>([])

    const [user, setUser] = React.useState('')
    const [organization, setOrganization] = React.useState('')

    const handleChange = (event: SelectChangeEvent) => {
        setUser(event.target.value)
    }
    const handleSetOrg = (event: SelectChangeEvent) => {
        setOrganization(event.target.value)
    }

    function handleSave() {
        ApiService.addUserToOrganizationById(organization, user).then((res) => {
            setOpen(false)
        })
    }

    async function load() {
        if (ApiService.isInRoles([Authority.Admin])) {
            ApiService.getAllUsers().then((res) => {
                if (res?.data) {
                    setUsers(res.data)
                }
            })
            ApiService.getAllOrganizations().then((res) => {
                if (res?.data) {
                    setOrganizations(res.data)
                }
            })
        } else {
            ApiService.adminGetUsers().then((res) => {
                if (res?.data) {
                    setUsers(res.data)
                }
            })
            ApiService.adminGetOrganizations().then((res) => {
                if (res?.data) {
                    setOrganizations(res.data)
                }
            })
        }
    }

    useEffect(() => {
        load()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleOpen = () => {
        load().then((res) => {
            setOpen(true)
        })
    }
    const handleClose = () => setOpen(false)

    return (
        <div>
            <Button onClick={handleOpen}>Update User</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
                        Text in a modal
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mb: 2 }}>
                        Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
                    </Typography>

                    {users.length > 0 && organizations.length > 0 && (
                        <>
                            <FormControl sx={{ minWidth: 80, width: '100%', mb: 2 }}>
                                <InputLabel id="demo-simple-select-autowidth-label">
                                    User
                                </InputLabel>
                                <Select
                                    labelId="selected-user"
                                    value={user}
                                    onChange={handleChange}
                                    autoWidth
                                    label="User"
                                >
                                    {users.map((item: any) => (
                                        <MenuItem value={item.id} key={item.id}>
                                            {item.authority} | {item.email}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl sx={{ minWidth: 80, width: '100%', mb: 2 }}>
                                <InputLabel id="demo-simple-select-org">Organization</InputLabel>
                                <Select
                                    labelId="selected-org"
                                    value={organization}
                                    onChange={handleSetOrg}
                                    autoWidth
                                    label="Organization"
                                >
                                    {organizations.map((item: any) => (
                                        <MenuItem value={item.id} key={item.id}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <Button
                                color="primary"
                                disabled={!(user || organization)}
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                onClick={handleSave}
                            >
                                Save
                            </Button>
                        </>
                    )}
                </Box>
            </Modal>
        </div>
    )
}
