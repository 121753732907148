import * as React from 'react'
import { Box } from '@mui/material'

export default function JsonData(props: { data: any }) {
    return (
        <React.Fragment>
            <Box sx={{ textAlign: 'left', fontSize: '10px', lineHeight: 1.0 }}>
                <code>
                    <pre>{JSON.stringify(props.data, null, 2)}</pre>
                </code>
            </Box>
        </React.Fragment>
    )
}
