import React from 'react'
import SearchFilterList from './SearchFilterList/SearchFilterList'
import AddNewSearchFilterButton from './AddNewSearchFilterButton'
import Box from '@mui/material/Box'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { Container } from '@mui/material'

function SearchFilter() {
    return (
        <Box sx={{ p: 2 }}>
            <Container maxWidth={'xl'}>
                <Box sx={{ mb: 2, textAlign: 'left' }}>
                    <AddNewSearchFilterButton></AddNewSearchFilterButton>
                </Box>
                <Box sx={{ mb: 2 }}>
                    <SearchFilterList></SearchFilterList>
                </Box>
            </Container>
        </Box>
    )
}

export default withAuthenticationRequired(SearchFilter)
