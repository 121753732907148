import { GridRenderCellParams } from '@mui/x-data-grid'
import { Box } from '@mui/material'
import Chip from '@mui/material/Chip'
import * as React from 'react'

import { unique } from '../../../Shared/Unique'

export function RenderSearchTermCell({ params }: { params: GridRenderCellParams<any, any[]> }) {
    if (params.value === undefined || params.value.toString() === '') {
        return <></>
    }
    let myList: string[] = Array.from(params.value.map((i: any) => i.term))
    const items = unique(myList)
    return (
        <Box key={params.value.toString()}>
            {
                items.length > 0 && (
                    <Chip
                        key={params.value.toString()}
                        label={params.value.toString()}
                        sx={{
                            padding: 0,
                            width: '100%',
                            textTransform: 'unset',
                            wordWrap: 'revert',
                        }}
                        variant={'outlined'}
                    />
                )
                // <Box key={items.join(', ')}>
                //     {items.join(', ')}
                //     {/*{items.map((item: any) => (*/}
                //     {/*    item.toString()*/}
                //     {/*))}*/}
                // </Box>
            }
        </Box>
    )
}
