import React, { Component } from 'react'
import { AutocompleteChangeReason, Box, TextField } from '@mui/material'
import AddressAutocomplete, { AddressAutocompleteValue } from 'mui-address-autocomplete'
import { Config } from '../../../config/config'

export interface AddressAutocompleteClassProps {
    onAddressChange: (address: AddressAutocompleteValue | null) => void
}

export interface AddressAutocompleteClassState {
    address: AddressAutocompleteValue | null
}

class AddressAutocompleteClass extends Component<
    AddressAutocompleteClassProps,
    AddressAutocompleteClassState
> {
    constructor(props: AddressAutocompleteClassProps) {
        super(props)
        this.state = {
            address: null,
        }
    }

    handleAddressChange = (
        event: React.SyntheticEvent<Element, Event>,
        value: AddressAutocompleteValue | null,
        reason: AutocompleteChangeReason,
    ) => {
        this.setState({ address: value })
        this.props.onAddressChange(value)
    }

    render() {
        return (
            <Box>
                <AddressAutocomplete
                    apiKey={Config.GoogleMapsApiKey}
                    onChange={this.handleAddressChange}
                    renderInput={(params) => (
                        <TextField {...params} label="Address" variant="outlined" fullWidth />
                    )}
                    label={'Address'}
                    value={this.state.address}
                />
            </Box>
        )
    }
}

export default AddressAutocompleteClass
