import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Breadcrumbs, Link, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

interface BreadcrumbsBackButtonProps {
    navigateTo: string
    linkText: string
    currentPageText: string
}

const BreadcrumbsBackButton: React.FC<BreadcrumbsBackButtonProps> = ({
    navigateTo,
    linkText,
    currentPageText,
}) => {
    const navigate = useNavigate()

    const handleBackClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault()
        navigate(navigateTo)
    }

    return (
        <Breadcrumbs
            aria-label="breadcrumb"
            sx={{
                ml: 2,
            }}
        >
            <Link
                href="#"
                underline="hover"
                color="inherit"
                onClick={handleBackClick}
                sx={{ display: 'flex', alignItems: 'center' }}
            >
                <ArrowBackIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                {linkText}
            </Link>
            <Typography color="text.primary">{currentPageText}</Typography>
        </Breadcrumbs>
    )
}

export default BreadcrumbsBackButton
