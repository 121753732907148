import * as React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import Chip from '@mui/material/Chip'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded'
import DevicesRoundedIcon from '@mui/icons-material/DevicesRounded'
import EdgesensorHighRoundedIcon from '@mui/icons-material/EdgesensorHighRounded'
import ViewQuiltRoundedIcon from '@mui/icons-material/ViewQuiltRounded'
import dashboardImage from '../../../../assets/images/dashboard.png'
import mobileDark from '../../../../assets/images/mobile-dark.png'
// noinspection ES6UnusedImports

const items = [
    {
        icon: <ViewQuiltRoundedIcon />,
        title: 'Dashboard',
        description:
            'The Dashboard is the team’s best friend as it shows which projects need to be reviewed, and which were identified as High Value or Low Value. If your team covers more than one scope of work, the analysis will be broken out into your Group names. Once the project has been reviewed, it shows who reviewed it to streamline the discussion/analysis process.',
        imageLight: dashboardImage,
        imageDark: dashboardImage,
    },
    {
        icon: <EdgesensorHighRoundedIcon />,
        title: 'Mobile integration',
        description: 'Use Construct QuickEST on your tablet or mobile device.',
        imageLight: mobileDark,
        imageDark: mobileDark,
    },
    {
        icon: <DevicesRoundedIcon />,
        title: 'Available on all platforms',
        description:
            'This item could let users know the product is available on all platforms, such as web, mobile, and desktop.',
        imageLight: mobileDark,
        imageDark: mobileDark,
    },
]

export default function Features() {
    const [selectedItemIndex, setSelectedItemIndex] = React.useState(0)

    const handleItemClick = (index: number) => {
        setSelectedItemIndex(index)
    }

    const selectedFeature = items[selectedItemIndex]

    return (
        <Container id="features" sx={{ py: { xs: 8, sm: 8 } }}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <>
                        <Typography component="h2" variant="h4" color="text.primary">
                            Product features
                        </Typography>
                        <Typography
                            variant="body1"
                            color="text.secondary"
                            sx={{ mb: { xs: 2, sm: 4 } }}
                        >
                            Construct QuickEST streamlines the challenging task of finding
                            high-value job opportunities for your company based on the scope of work
                            you focus on: plumbing, drywall, roofing, concrete, etc. By
                            automatically extracting relevant information and documents from plans
                            and specifications, it saves time and ensures a focused approach to
                            pertinent projects. This eliminates the need to sift through irrelevant
                            details, allowing your team to concentrate on evaluating the most
                            promising opportunities.
                        </Typography>
                    </>
                    <Grid container item gap={1} sx={{ display: { xs: 'auto', sm: 'none' } }}>
                        {items.map(({ title }, index) => (
                            <Chip
                                key={index}
                                label={title}
                                onClick={() => handleItemClick(index)}
                                sx={{
                                    borderColor: (theme) => {
                                        if (theme.palette.mode === 'light') {
                                            return selectedItemIndex === index
                                                ? 'primary.light'
                                                : ''
                                        }
                                        return selectedItemIndex === index ? 'primary.light' : ''
                                    },
                                    background: (theme) => {
                                        if (theme.palette.mode === 'light') {
                                            return selectedItemIndex === index ? 'none' : ''
                                        }
                                        return selectedItemIndex === index ? 'none' : ''
                                    },
                                    backgroundColor:
                                        selectedItemIndex === index ? 'primary.main' : '',
                                    '& .MuiChip-label': {
                                        color: selectedItemIndex === index ? '#fff' : '',
                                    },
                                }}
                            />
                        ))}
                    </Grid>
                    <Box
                        component={Card}
                        variant="outlined"
                        sx={{
                            display: { xs: 'auto', sm: 'none' },
                            mt: 4,
                        }}
                    >
                        <Box
                            sx={{
                                backgroundImage: (theme) =>
                                    theme.palette.mode === 'light'
                                        ? items[selectedItemIndex].imageLight
                                        : items[selectedItemIndex].imageDark,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                minHeight: 280,
                            }}
                        />
                        <Box sx={{ px: 2, pb: 2 }}>
                            <Typography color="text.primary" variant="body2" fontWeight="bold">
                                {selectedFeature.title}
                            </Typography>
                            <Typography color="text.secondary" variant="body2" sx={{ my: 0.5 }}>
                                {selectedFeature.description}
                            </Typography>
                            <Link
                                color="primary"
                                variant="body2"
                                fontWeight="bold"
                                sx={{
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                    '& > svg': { transition: '0.2s' },
                                    '&:hover > svg': { transform: 'translateX(2px)' },
                                }}
                            >
                                <span>Learn more</span>
                                <ChevronRightRoundedIcon
                                    fontSize="small"
                                    sx={{ mt: '1px', ml: '2px' }}
                                />
                            </Link>
                        </Box>
                    </Box>
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="flex-start"
                        spacing={2}
                        useFlexGap
                        sx={{ width: '100%', display: { xs: 'none', sm: 'flex' } }}
                    >
                        {items.map(({ icon, title, description }, index) => (
                            <Card
                                key={index}
                                variant="outlined"
                                component={Button}
                                onClick={() => handleItemClick(index)}
                                sx={{
                                    p: 3,
                                    height: 'fit-content',
                                    width: '100%',
                                    background: 'none',
                                    backgroundColor:
                                        selectedItemIndex === index ? 'action.selected' : undefined,
                                    borderColor: (theme) => {
                                        if (theme.palette.mode === 'light') {
                                            return selectedItemIndex === index
                                                ? 'primary.light'
                                                : 'grey.200'
                                        }
                                        return selectedItemIndex === index
                                            ? 'primary.dark'
                                            : 'grey.800'
                                    },
                                }}
                            >
                                <Box
                                    sx={{
                                        width: '100%',
                                        display: 'flex',
                                        textAlign: 'left',
                                        flexDirection: { xs: 'column', md: 'row' },
                                        alignItems: { md: 'center' },
                                        gap: 2.5,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            color: (theme) => {
                                                if (theme.palette.mode === 'light') {
                                                    return selectedItemIndex === index
                                                        ? 'primary.main'
                                                        : 'grey.300'
                                                }
                                                return selectedItemIndex === index
                                                    ? 'primary.main'
                                                    : 'grey.700'
                                            },
                                        }}
                                    >
                                        {icon}
                                    </Box>
                                    <Box sx={{ textTransform: 'none' }}>
                                        <Typography
                                            color="text.primary"
                                            variant="body2"
                                            fontWeight="bold"
                                        >
                                            {title}
                                        </Typography>
                                        <Typography
                                            color="text.secondary"
                                            variant="body2"
                                            sx={{ my: 0.5 }}
                                        >
                                            {description}
                                        </Typography>
                                        <Link
                                            color="primary"
                                            variant="body2"
                                            fontWeight="bold"
                                            sx={{
                                                display: 'inline-flex',
                                                alignItems: 'center',
                                                '& > svg': { transition: '0.2s' },
                                                '&:hover > svg': { transform: 'translateX(2px)' },
                                            }}
                                            onClick={(event) => {
                                                event.stopPropagation()
                                            }}
                                        >
                                            <span>Learn more</span>
                                            <ChevronRightRoundedIcon
                                                fontSize="small"
                                                sx={{ mt: '1px', ml: '2px' }}
                                            />
                                        </Link>
                                    </Box>
                                </Box>
                            </Card>
                        ))}
                    </Stack>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{ display: { xs: 'none', sm: 'flex' }, width: '100%' }}
                >
                    <Card
                        variant="outlined"
                        sx={{
                            m: 'auto',
                            minHeight: '250px',
                            width: '100%',
                            display: { xs: 'none', sm: 'flex' },
                            pointerEvents: 'none',
                        }}
                    >
                        <Box
                            sx={{
                                display: { xs: 'none', sm: 'flex' },
                                width: '100%',
                                height: 420,
                                margin: 'auto',
                                padding: 0,
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <img
                                src={items[selectedItemIndex].imageLight}
                                style={{
                                    maxWidth: '100%',
                                    // height: '100%',
                                    justifyContent: 'center',
                                }}
                                alt={items[selectedItemIndex].title}
                            />
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    )
}
