import * as React from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs, { Dayjs } from 'dayjs'

interface BidDatePickerProps {
    bidDate?: Date | null
    onChange: (value: any) => void
}

export default function BidDatePicker({ bidDate, onChange }: BidDatePickerProps) {
    const [value, setValue] = React.useState<Dayjs | null>(bidDate ? dayjs(bidDate) : null)

    const handleDateChange = async (newValue: Dayjs | null) => {
        setValue(newValue)
        onChange(newValue)
    }

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label="Bid Date"
                    value={value}
                    sx={{
                        width: '100%',
                        textAlign: 'left',
                    }}
                    onChange={handleDateChange}
                />
            </LocalizationProvider>
        </>
    )
}
