// dev: G-W3ZKWJW73R
// prod: G-YNTKMDCQWX
export const Config = {
    Version: process.env.REACT_APP_PACKAGE_VERSION || '0.0.1',
    ApiUrl: process.env.REACT_APP_API_URL || 'https://app-dev.construct-quick-est.com',
    Audience: process.env.REACT_APP_AUDIENCE || 'VN72V9Qazfengwhum1eU84wNU40HIFtx',
    Auth0ClientId: process.env.REACT_APP_AUTH0_CLIENT_ID || 'VN72V9Qazfengwhum1eU84wNU40HIFtx',
    Auth0Domain: process.env.REACT_APP_AUTH0_DOMAIN || 'dev-jifagoe7lulp0otu.us.auth0.com',
    AnalyticsTag: process.env.REACT_APP_ANALYTICS_TAG || '',
    AnalyticsEnabled: (process.env.REACT_APP_ANALYTICS_TAG || '').toString().length > 0,
    GoogleMapsApiKey: 'AIzaSyD6bUJcMMGR16DraC4_dfJurypUzheAGmE',
    UploadsLimit: 100,
}
