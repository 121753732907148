import React from 'react'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'

function ManageSalesforceIntegrationNavigateButton() {
    const navigate = useNavigate()
    return (
        <Button
            variant={'outlined'}
            onClick={() => {
                navigate('/account/integrations/salesforce')
            }}
        >
            Manage Integration
        </Button>
    )
}

export default ManageSalesforceIntegrationNavigateButton
