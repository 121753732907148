import React from 'react'
import Button from '@mui/material/Button/Button'
import ApiService from '../../services/api.service'
import useAlert from '../Alerting/useAlert'

function ResetPasswordButton() {
    const { setAlert } = useAlert()
    return (
        <Button
            fullWidth
            variant="contained"
            color={'info'}
            onClick={() => {
                ApiService.resetMyPassword().then((res) => {
                    setAlert(res?.data?.msg, 'success')
                })
            }}
        >
            Reset Password
        </Button>
    )
}

export default ResetPasswordButton
