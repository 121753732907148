import React from 'react'
import { Box, LinearProgress, Typography } from '@mui/material'
import { LinearProgressProps } from '@mui/material/LinearProgress'

export default function LinearProgressWithLabel(
    props: LinearProgressProps & {
        value: number
        text: string | undefined
    },
) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <Typography variant="body2" color="text.secondary" textAlign={'center'}>
                    {props.text !== undefined && props.text.length > 0
                        ? props.text
                        : `${Math.round(props.value)}%`}
                </Typography>
                <LinearProgress variant="determinate" {...props} />
            </Box>
        </Box>
    )
}
