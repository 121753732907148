import { GridRenderCellParams } from '@mui/x-data-grid'
import * as React from 'react'
import { useState } from 'react'
import {
    gridFilteredDescendantCountLookupSelector,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid-pro'
import { Box, Button, ButtonProps } from '@mui/material'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

export function CustomGridTreeDataGroupingCell(props: GridRenderCellParams) {
    const { id, field, rowNode } = props
    const [isNodeExpanded, setIsNodeExpanded] = useState(true)
    const [isSecondNodeExpanded, setIsSecondNodeExpanded] = useState(true)
    const apiRef = useGridApiContext()
    const filteredDescendantCountLookup = useGridSelector(
        apiRef,
        gridFilteredDescendantCountLookupSelector,
    )
    const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0

    const handleClick: ButtonProps['onClick'] = (event) => {
        console.log(props)
        if (rowNode.type === 'group') {
            let two = isNodeExpanded
            if (rowNode.depth === 0) {
                two = isNodeExpanded
                setIsNodeExpanded(!two)
            }
            if (rowNode.depth === 1) {
                two = isSecondNodeExpanded
                setIsSecondNodeExpanded(!two)
            }
        }
        if (rowNode.type !== 'group') {
            return
        }

        apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded)
        apiRef.current.setCellFocus(id, field)
        event.stopPropagation()
    }

    let text: string = ``
    switch (rowNode.type) {
        case 'footer':
            break
        case 'leaf':
            break
        case 'group':
            text = (rowNode.groupingKey || '').toString()
            break
        case 'pinnedRow':
            break
    }
    return (
        <Box sx={{ ml: rowNode.depth * 2 }}>
            <>
                {filteredDescendantCount > 0 ? (
                    <>
                        {rowNode.depth === 0 && (
                            <Button
                                fullWidth
                                onClick={handleClick}
                                tabIndex={-1}
                                size="small"
                                startIcon={isNodeExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            >
                                {text}
                            </Button>
                        )}
                        {rowNode.depth === 1 && (
                            <Button
                                fullWidth
                                onClick={handleClick}
                                tabIndex={-1}
                                size="small"
                                startIcon={
                                    isSecondNodeExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />
                                }
                            >
                                {text} ({filteredDescendantCount})
                            </Button>
                        )}
                    </>
                ) : (
                    <></>
                )}
            </>
        </Box>
    )
}
