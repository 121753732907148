import { createContext } from 'react'
import { AlertColor } from '@mui/material/Alert/Alert'

const initialState: { text: string; type: AlertColor } = {
    text: '',
    type: 'success',
}

const AlertContext = createContext({
    ...initialState,
    setAlert: (text: string, type: AlertColor) => {},
})
export default AlertContext
