import * as React from 'react'
import { useState } from 'react'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { Button, Grid, TextField } from '@mui/material'
import ApiService from '../../../../services/api.service'
import useAlert from '../../../Alerting/useAlert'

export default function Contact() {
    const [name, setName] = useState(null)
    const [email, setEmail] = useState(null)
    const [messageText, setMessageText] = useState(null)
    const { setAlert } = useAlert()

    return (
        <Container
            id="terms-and-conditions"
            sx={{
                pt: { xs: 4, sm: 12 },
                pb: { xs: 8, sm: 16 },
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: { xs: 3, sm: 6 },
            }}
        >
            <Typography
                component="h2"
                variant="h4"
                color="text.primary"
                sx={{
                    width: { sm: '100%', md: '60%' },
                    textAlign: { sm: 'left', md: 'center' },
                }}
            >
                Get in Touch
            </Typography>
            <Box sx={{ width: '100%' }}>
                <Typography variant="body2">
                    You can reach our team by emailing <Link>support@construct-quick-est.com</Link>{' '}
                    or by filling out the form below.
                </Typography>
            </Box>
            <Box sx={{ width: '100%' }}>
                <form
                    onSubmit={function data(ev: any) {
                        ev.preventDefault()
                        console.log(name)
                        console.log(email)
                        console.log(messageText)
                        console.log('submitted!')

                        ApiService.submitContactForm(name, email, messageText)
                            .then((r) => {
                                console.log(r)
                                setAlert('Successfully submitted contact form', 'success')
                            })
                            .catch((err) => {
                                setAlert('Error contact form', 'warning')
                            })
                    }}
                >
                    <Grid
                        container
                        spacing={1}
                        maxWidth={500}
                        alignItems={'center'}
                        alignSelf={'center'}
                    >
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            alignItems={'center'}
                            alignSelf={'center'}
                        >
                            <TextField
                                id="name"
                                type="text"
                                label="Name"
                                fullWidth
                                margin="dense"
                                autoComplete={'off'}
                                required={true}
                                onChange={(ev: any) => {
                                    setName(ev.target.value)
                                }}
                            />
                            <TextField
                                id="email"
                                type="email"
                                label="Email Address"
                                fullWidth
                                margin="dense"
                                required={true}
                                autoComplete={'off'}
                                onChange={(ev: any) => {
                                    setEmail(ev.target.value)
                                }}
                            />
                            <TextField
                                id="contact-body"
                                type="text"
                                label="Write your message here"
                                fullWidth
                                multiline={true}
                                rows={5}
                                margin="dense"
                                required={true}
                                autoComplete={'off'}
                                onChange={(ev: any) => {
                                    setMessageText(ev.target.value)
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Box mt={3} maxWidth={500}>
                        <Button type="submit" variant="contained" color="primary" fullWidth>
                            Submit
                        </Button>
                    </Box>
                </form>
                {/*<FormControl>*/}
                {/*        <InputLabel htmlFor="my-input">Email address</InputLabel>*/}
                {/*        <Input id="my-input" aria-describedby="my-helper-text"/>*/}
                {/*        <FormHelperText id="my-helper-text">We'll never share your email.</FormHelperText>*/}
                {/*</FormControl>*/}
            </Box>
        </Container>
    )
}
