import * as React from 'react'
import { useEffect, useState } from 'react'
import Paper from '@mui/material/Paper'
import { GridColDef, GridToolbar } from '@mui/x-data-grid'
import { DataGridPro } from '@mui/x-data-grid-pro'
import ApiService from '../../../services/api.service'
import { timeSince, toLocalDate } from '../../Projects/ToLocalDate'
import LinearProgressWithLabel from '../../Dashboard/LinearProgressWithLabel'
import { Alert } from '@mui/material'

// https://mui.com/material-ui/react-table/#data-table
export default function ListOrganizationProcesses() {
    const [rows, setRows] = React.useState<any[]>([])
    const [loading, setLoading] = useState(false)
    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Name',
            flex: 0.5,
        },

        {
            field: 'progress',
            headerName: 'Progress',
            flex: 0.5,
            renderCell: (params) => {
                const value = params.row.progress || 0
                return (
                    <LinearProgressWithLabel
                        value={value}
                        text={undefined}
                    ></LinearProgressWithLabel>
                )
            },
        },
        {
            field: 'message',
            headerName: 'Message',
            flex: 0.5,
        },
        {
            field: 'date_updated',
            headerName: 'Updated At',
            flex: 0.5,
            valueFormatter: (params: any) => {
                return toLocalDate(params)
            },
        },
        {
            field: 'date_created',
            headerName: 'Created At',
            flex: 0.5,
            valueFormatter: (params: any) => {
                return toLocalDate(params)
            },
        },
        {
            field: 'time_since_updated',
            headerName: 'Time Ago',
            flex: 1,
            renderCell: (params) => {
                const dateUpdated = params.row.date_updated
                return timeSince(dateUpdated).html
            },
        },
    ]

    useEffect(() => {
        async function load() {
            setLoading(true)

            try {
                const result = await ApiService.getAllOrganizationProcesses()
                if (result.status === 200) {
                    const rowItems = result.data['processes']
                    setRows(rowItems)
                }
            } catch (error) {
                console.error('Failed to load data', error)
            } finally {
                setLoading(false)
            }
        }

        load() // Initial load
        const intervalId = setInterval(load, 5000) // Set interval to load data every 5 seconds

        return () => clearInterval(intervalId) // Cleanup on component unmount
    }, [])

    return (
        <>
            {!loading && rows.length === 0 && (
                <Alert severity="info">
                    <div>No processes found.</div>
                </Alert>
            )}
            {rows.length > 0 && (
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <DataGridPro
                        rows={rows}
                        columns={columns}
                        density={'compact'}
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                            },
                        }}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 50 },
                            },
                        }}
                        pageSizeOptions={[50, 250, 500]}
                    />
                </Paper>
            )}
        </>
    )
}
