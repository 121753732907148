import Container from '@mui/material/Container'
import React from 'react'
import { Button } from '@mui/material'
import { useAuth0 } from '@auth0/auth0-react'

interface SignInProps {
    kind: string
}

export default function SignIn({ kind }: SignInProps) {
    const { loginWithPopup } = useAuth0()

    return (
        <Container component="main" maxWidth="xs">
            <Button
                type="submit"
                onClick={function () {
                    loginWithPopup()
                }}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
            >
                {kind === 'register' ? 'Register' : 'Sign In'}
            </Button>

            {/*<Box*/}
            {/*    sx={{*/}
            {/*        marginTop: 2,*/}
            {/*        display: "flex",*/}
            {/*        flexDirection: "column",*/}
            {/*        alignItems: "center",*/}
            {/*    }}*/}
            {/*>*/}
            {/*    <Typography component="h1" variant="h5">*/}
            {/*        <>*/}
            {/*            {kind === "login" ? "Login" : "Register"}*/}
            {/*        </>*/}
            {/*    </Typography>*/}
            {/*    <Box component="form" onSubmit={function (event) {*/}
            {/*        event.preventDefault();*/}
            {/*        const data = new FormData(event.currentTarget);*/}
            {/*        if (kind === 'login') {*/}
            {/*            loginWithCredentials(data.get('email'), data.get('password'));*/}
            {/*        }*/}
            {/*        if (kind === 'register') {*/}
            {/*            ApiService.register(data.get("email"), data.get("password")).then(res => {*/}
            {/*                loginWithCredentials(data.get('email'), data.get('password'));*/}
            {/*            });*/}
            {/*        }*/}

            {/*    }} noValidate sx={{mt: 1}}>*/}
            {/*        <TextField*/}
            {/*            margin="normal"*/}
            {/*            required*/}
            {/*            fullWidth*/}
            {/*            id="email"*/}
            {/*            label="Email Address"*/}
            {/*            name="email"*/}
            {/*            autoComplete="email"*/}
            {/*            autoFocus={true}*/}
            {/*            value={email || ''}*/}
            {/*            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {*/}
            {/*                setEmail(event.target.value);*/}
            {/*            }}*/}
            {/*        />*/}
            {/*        <TextField*/}
            {/*            margin="normal"*/}
            {/*            required*/}
            {/*            fullWidth*/}
            {/*            name="password"*/}
            {/*            label="Password"*/}
            {/*            type="password"*/}
            {/*            id="password"*/}
            {/*            autoFocus={false}*/}
            {/*            value={password || ''}*/}
            {/*            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {*/}
            {/*                setPassword(event.target.value);*/}
            {/*            }}*/}
            {/*            autoComplete="current-password"*/}
            {/*        />*/}
            {/*        {kind === "login" &&*/}
            {/*            <FormControlLabel*/}
            {/*                control={<Checkbox value="remember" color="primary"/>}*/}
            {/*                label="Remember me"*/}
            {/*            />*/}
            {/*        }*/}

            {/*<Button*/}
            {/*    type="submit"*/}
            {/*    fullWidth*/}
            {/*    variant="contained"*/}
            {/*    sx={{mt: 3, mb: 2}}*/}
            {/*>*/}
            {/*    {kind === "register" ? "Register" : "Sign In"}*/}
            {/*</Button>*/}
            {/*        {kind === "login" &&*/}
            {/*            <Container>*/}
            {/*                <Grid container>*/}
            {/*                    <Grid item sm>*/}
            {/*                        <Link*/}
            {/*                            color='textSecondary'*/}
            {/*                            component={RouterLink}*/}
            {/*                            to='/register'*/}
            {/*                            variant='body2'*/}
            {/*                        >*/}
            {/*                            Don't have an account? Sign up*/}
            {/*                        </Link>*/}
            {/*                    </Grid>*/}
            {/*                </Grid>*/}
            {/*            </Container>*/}
            {/*        }*/}

            {/*    </Box>*/}
            {/*</Box>*/}
        </Container>
    )
}
