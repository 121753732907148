import React from 'react'
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react'
import { Container } from '@mui/material'
import JsonData from '../Shared/JsonData'
import Loading from '../../utils/Loading'

export const ProfileComponent = () => {
    const { user } = useAuth0()

    return (
        <Container>
            <JsonData data={user}></JsonData>
        </Container>
    )
}

export default withAuthenticationRequired(ProfileComponent, {
    onRedirecting: () => <Loading />,
})
