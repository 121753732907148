import { createTheme } from '@mui/material/styles'
import { amber, deepPurple } from '@mui/material/colors'

export const theme = createTheme({
    palette: {
        primary: {
            main: deepPurple[600],
        },
        secondary: {
            main: amber[800],
        },
    },
})
