import * as React from 'react'
import { useEffect } from 'react'
import ListUsers from './Users/ListUsers'
import ListOrgs from './Orgs/ListOrgs'
import { Container, Divider, Typography } from '@mui/material'
import CreateOrganizationForm from '../Shared/CreateOrganizationForm'
import ApiService from '../../services/api.service'
import { Authority } from '../../services/api/models/authority'
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react'
import ListSearchFilters from './SearchFilters/ListSearchFilters'
import CreateOrgUserModal from './CreateOrgUserModal'
import ManageUsers from './Users/ManageUsers'
import Loading from '../../utils/Loading' // https://mui.com/material-ui/react-table/#data-table

// https://mui.com/material-ui/react-table/#data-table
function AdminHome() {
    const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0()

    useEffect(() => {
        async function load() {
            if (isAuthenticated) {
                let token = await getAccessTokenSilently({
                    detailedResponse: true,
                })
                ApiService.saveToken(token.access_token)
                await ApiService.getAccount()
            }
            // if (allUsers.status === 200) {
            //     const rowItems = allUsers.data;
            //     setRows(rowItems);
            //     Object.keys(rowItems[0]).forEach((item) => {
            //         const existing = cols.map(item => item.field)
            //         if (existing.indexOf(item) === -1) {
            //             cols.push({
            //                 field: item.toString(),
            //                 headerName: item.toString(),
            //                 minWidth: 250,
            //             },);
            //         }
            //     })
            // }
        }

        load()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {!isAuthenticated || isLoading ? (
                <>
                    <Loading></Loading>
                </>
            ) : (
                <>
                    <Container sx={{ pt: 2 }} maxWidth={'xl'}>
                        <Typography color="textPrimary" variant="h4" textAlign={'left'}>
                            Create Users
                        </Typography>
                        <Divider sx={{ mb: 2 }}></Divider>
                        {ApiService.isInRoles([Authority.Admin, Authority.OrgAdmin]) && (
                            <CreateOrgUserModal />
                        )}

                        <Typography color="textPrimary" variant="h4" textAlign={'left'}>
                            Users
                        </Typography>
                        <Divider sx={{ mb: 2 }}></Divider>
                        <ListUsers />

                        {ApiService.isInRoles([Authority.Admin, Authority.OrgAdmin]) && (
                            <>
                                <Typography color="textPrimary" variant="h4" textAlign={'left'}>
                                    Manage Users
                                </Typography>
                                <Divider sx={{ mb: 2 }}></Divider>
                                <ManageUsers />
                            </>
                        )}

                        <Typography color="textPrimary" variant="h4" textAlign={'left'}>
                            Organizations
                        </Typography>
                        <Divider sx={{ mb: 2 }}></Divider>
                        <ListOrgs />

                        {ApiService.isInRoles([Authority.Admin]) && (
                            <>
                                <ListSearchFilters />
                                <CreateOrganizationForm
                                    onUpdate={function () {
                                        console.log('updated org form')
                                    }}
                                ></CreateOrganizationForm>
                            </>
                        )}
                    </Container>
                </>
            )}
        </>
    )
}

export default withAuthenticationRequired(AdminHome)
