export enum ReviewStatus {
    NeedsReview = 'needs_review',
    Pursuing = 'pursuing',
    NotPursuing = 'not_pursuing',
    Won = 'won',
    Lost = 'lost',
    NeedsQuote = 'needs_quote',
    Quoting = 'quoting',
    SentToBidders = 'sent_to_bidders',
}

export function ReviewStatusFromString(data: string) {
    switch (data.toString()) {
        case ReviewStatus.NeedsReview.toString():
            return ReviewStatus.NeedsReview
        case ReviewStatus.Pursuing.toString():
            return ReviewStatus.Pursuing
        case ReviewStatus.NotPursuing.toString():
            return ReviewStatus.NotPursuing
        case ReviewStatus.Won.toString():
            return ReviewStatus.Won
        case ReviewStatus.Lost.toString():
            return ReviewStatus.Lost
        case ReviewStatus.NeedsQuote.toString():
            return ReviewStatus.NeedsQuote
        case ReviewStatus.Quoting.toString():
            return ReviewStatus.Quoting
        case ReviewStatus.SentToBidders.toString():
            return ReviewStatus.SentToBidders
    }
    return ReviewStatus.NeedsReview
}

export function ReviewStatusText(status: ReviewStatus) {
    switch (status) {
        case ReviewStatus.NeedsReview:
            return 'Needs Review'
        case ReviewStatus.Pursuing:
            return 'Pursuing'
        case ReviewStatus.NotPursuing:
            return 'Not Pursuing'
        case ReviewStatus.Won:
            return 'Won'
        case ReviewStatus.Lost:
            return 'Lost'
        case ReviewStatus.NeedsQuote:
            return 'Needs Quote'
        case ReviewStatus.Quoting:
            return 'Quoting'
        case ReviewStatus.SentToBidders:
            return 'Sent to Bidders'
    }
    return ''
}
