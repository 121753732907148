import React, { useEffect, useState } from 'react'
import { Autocomplete, Box, TextField, Typography } from '@mui/material'
import ApiService from '../../services/api.service'
import apiService from '../../services/api.service'
import { Authority } from '../../services/api/models/authority'

interface SelectedOrganization {
    id: string
    name: string
}

interface OrganizationSelectProps {
    onOrganizationChange?: (organization: SelectedOrganization | null) => void
}

const OrganizationSelect: React.FC<OrganizationSelectProps> = ({ onOrganizationChange }) => {
    const [selectedOrganization, setSelectedOrganization] = useState<SelectedOrganization | null>(
        null,
    )
    const [organizations, setOrganizations] = useState<SelectedOrganization[]>([])

    async function load() {
        let getOrganizationsResponse = await apiService.getOrganizations()
        setOrganizations(
            Array.from(getOrganizationsResponse.data).map((item) => {
                return {
                    id: item.id,
                    name: item.name,
                }
            }),
        )
        if (!ApiService.isInRoles([Authority.Admin])) {
            setSelectedOrganization(getOrganizationsResponse.data[0])
            if (onOrganizationChange) {
                onOrganizationChange(getOrganizationsResponse.data[0])
            }
        }
    }

    const handleChange = (event: React.ChangeEvent<{}>, newValue: SelectedOrganization | null) => {
        setSelectedOrganization(newValue)
        if (onOrganizationChange) {
            onOrganizationChange(newValue)
        }
    }

    useEffect(() => {
        load()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Box sx={{ width: 300, pt: 2 }}>
            <Autocomplete
                options={organizations}
                getOptionLabel={(option) => option.name}
                value={selectedOrganization}
                onChange={handleChange}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Select Organization"
                        variant="outlined"
                        size={'small'}
                    />
                )}
            />
            {selectedOrganization && (
                <Box mt={1} mb={2}>
                    <Typography>
                        Organization: <b>{selectedOrganization.name}</b>
                    </Typography>
                </Box>
            )}
        </Box>
    )
}

export default OrganizationSelect
