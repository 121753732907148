import { styled } from '@mui/material/styles'
import { Typography } from '@mui/material'
import { grey } from '@mui/material/colors'

export const SquareTitle = styled(Typography)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    color: theme.palette.text.primary,
    fontSize: theme.typography.h5.fontSize,
    lineHeight: '50px',
    borderBottomWidth: `1px`,
    borderBottomStyle: 'solid',
    borderBottomColor: grey[400],
    marginBottom: '20px',
}))
