import { GridRenderCellParams } from '@mui/x-data-grid'
import * as React from 'react'
import { Box } from '@mui/material'

export function renderDataRow(params: GridRenderCellParams<any, string>) {
    if (params.value === undefined) {
        return <></>
    }
    if (params.value === null) {
        return <></>
    }

    switch (typeof params.value) {
        case 'undefined':
            break
        case 'object':
            if (Array.isArray(params.value)) {
                return Array.from(params.value).map((item: any) => {
                    return <Box key={item}>{JSON.stringify(item)}</Box>
                })
            }
            try {
                return <>{params.value['organization']['name']}</>
            } catch (e) {}
            return JSON.stringify(params.value || '')
        case 'boolean':
            return params.value || ''
        case 'number':
            break
        case 'string':
            return params.value || ''
        case 'function':
            break
        case 'symbol':
            break
        case 'bigint':
            break
    }

    return <></>
}
