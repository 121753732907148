import React from 'react'
import { Button } from '@mui/material'
import SalesforceIcon from '../../../Shared/Icons/SalesforceIcon'
import ApiService from '../../../../services/api.service'

function LoginWithSalesforceButton() {
    return (
        <Button
            variant={'outlined'}
            color={'info'}
            endIcon={<SalesforceIcon sx={{ height: 40, width: 40 }} />}
            onClick={async () => {
                await ApiService.loginWithSalesforce({})
            }}
        >
            Link Salesforce Account
        </Button>
    )
}

export default LoginWithSalesforceButton
