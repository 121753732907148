import { ReviewStatus } from './ReviewStatus'
import { OverridableStringUnion } from '@mui/types'
import { ButtonPropsColorOverrides } from '@mui/material'

export default function getReviewStatusButtonColor(
    status: ReviewStatus,
): OverridableStringUnion<
    'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning',
    ButtonPropsColorOverrides
> {
    switch (status) {
        case ReviewStatus.Pursuing:
        case ReviewStatus.Won:
            return 'success'
        case ReviewStatus.NotPursuing:
        case ReviewStatus.Lost:
            return 'error'
        case ReviewStatus.NeedsQuote:
            return 'warning'
        case ReviewStatus.Quoting:
        case ReviewStatus.SentToBidders:
        case ReviewStatus.NeedsReview:
            return 'info'
    }
}
