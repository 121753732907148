import React from 'react'
import { Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

function AddNewProjectButton() {
    return (
        <Button variant="contained" color="secondary" href={'/projects/add'} endIcon={<AddIcon />}>
            New Project
        </Button>
    )
}

export default AddNewProjectButton
