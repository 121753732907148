import * as React from 'react'
import { useEffect } from 'react'
import Paper from '@mui/material/Paper'
import { GridColDef, GridRenderCellParams, GridToolbar } from '@mui/x-data-grid'
import { DataGridPro } from '@mui/x-data-grid-pro'
import ApiService from '../../../services/api.service'
import { Box, Button } from '@mui/material'
import { toLocalDate } from '../../Projects/ToLocalDate'
import useAlert from '../../Alerting/useAlert'
import { useAuth0 } from '@auth0/auth0-react'

function renderAction(params: GridRenderCellParams<any, string>) {
    if (params.value === undefined || params.value.toString() === '') {
        return <></>
    }

    async function update(ev: any, id: string) {
        await ApiService.deleteUserById(id)
        window.location.reload()
    }

    return (
        <Button
            key={`${params.value || new Date().toString()}-view-button`}
            size="small"
            color={'error'}
            variant={'contained'}
            onClick={(ev: any) => {
                update(ev, params.value || '')
            }}
        >
            Delete
        </Button>
    )
}

// https://mui.com/material-ui/react-table/#data-table
export default function ManageUsers() {
    const { setAlert } = useAlert()

    const { isAuthenticated } = useAuth0()
    // const apiRef = useGridApiContext()
    // const ref = React.useRef<HTMLElement>()

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'Id',
            width: 100,
            renderCell: renderAction,
        },
        {
            field: 'subscription_name',
            headerName: 'Subscription',
            flex: 0.1,
        },
        {
            field: 'organization_name',
            headerName: 'Org',
            flex: 0.2,
        },
        {
            field: 'username',
            headerName: 'Username',
            flex: 0.2,
        },
        {
            field: 'authority',
            headerName: 'Role',
            flex: 0.2,
        },

        {
            field: 'reset_password_user_id',
            headerName: 'Reset Pass',
            flex: 0.2,
            renderCell: (params) => {
                return (
                    <>
                        <Button
                            variant={'contained'}
                            size={'small'}
                            color={'info'}
                            onClick={() => {
                                ApiService.resetPasswordForUser(params.value).then((res) => {
                                    setAlert(res?.data?.msg, 'success')
                                })
                            }}
                        >
                            Reset Password
                        </Button>
                    </>
                )
            },
        },
        // {
        //     field: 'change_role',
        //     headerName: 'Change Role',
        //     flex: 1.5,
        //     renderCell: (params) => {
        //         // apiRef.current.getRO
        //
        //         return (
        //             <>
        //                 <FormControl fullWidth>
        //                     <InputLabel id="demo-simple-select-label">Age</InputLabel>
        //                     <Select
        //                         labelId={`demo-label-${params.value}`}
        //                         id={`demo-label-id-${params.value}`}
        //                         value={params.value}
        //                         label="Role"
        //                         size={'small'}
        //                         onChange={(ev) => {
        //                             console.log(ev.target.value)
        //                         }}
        //                     >
        //                         <MenuItem value={Authority.OrgAdmin} key={Authority.OrgAdmin}>
        //                             {Authority.OrgAdmin}
        //                         </MenuItem>
        //                         <MenuItem value={Authority.OrgUser} key={Authority.OrgUser}>
        //                             {Authority.OrgUser}
        //                         </MenuItem>
        //                     </Select>
        //                 </FormControl>
        //             </>
        //         )
        //     },
        // },

        {
            field: 'user_link',
            headerName: 'Auth0 User',
            flex: 0.1,
            renderCell: (params) => {
                return (
                    <>
                        <Button
                            variant={'contained'}
                            href={params.value}
                            target={'_blank'}
                            size={'small'}
                        >
                            View
                        </Button>
                    </>
                )
            },
        },
        {
            field: 'login_count',
            headerName: 'Logins',
            width: 75,
        },
        {
            field: 'subject_id',
            headerName: 'Subject Id',
            flex: 0.1,
            renderCell: (params) => {
                return <>{params.value}</>
            },
        },
        {
            field: 'date_updated',
            headerName: 'Updated At',
            flex: 0.1,
            valueFormatter: (params: any) => {
                return toLocalDate(params)
            },
        },
        {
            field: 'date_created',
            headerName: 'Created At',
            flex: 0.1,
            valueFormatter: (params: any) => {
                return toLocalDate(params)
            },
        },
    ]

    const [rows, setRows] = React.useState<any[]>([])
    const [cols] = React.useState<GridColDef[]>(columns)

    useEffect(() => {
        async function load() {
            const result = await ApiService.getAllUsers()
            if (result.status === 200) {
                let data: any[] = []
                let items: any[] = result.data
                for (const item of items) {
                    item.login_count = item?.user_info?.logins_count ?? 0
                    item.user_link = `https://manage.auth0.com/dashboard/us/dev-jifagoe7lulp0otu/users/${item.subject_id}`
                    item.reset_password_user_id = item.subject_id
                    item.organization_name = item.organization.name
                    item.change_role = item.authority
                    data.push(item)
                }

                setRows(data)
                // Object.keys(rowItems[0]).forEach((item) => {
                //     const existing = cols.map((item) => item.field)
                //     if (existing.indexOf(item) === -1) {
                //         cols.push({
                //             field: item.toString(),
                //             headerName: item.toString(),
                //             minWidth: 250,
                //             renderCell: renderDataRow,
                //         })
                //     }
                // })
            }
        }

        load()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <Box sx={{ minHeight: 250 }}>
            {/*<>*/}
            {/*    <CreateNewUserForm></CreateNewUserForm>*/}
            {/*</>*/}
            {rows.length > 0 && isAuthenticated && (
                <>
                    <Paper sx={{ width: '100%', overflow: 'hidden', marginBottom: 4 }}>
                        <DataGridPro
                            rows={rows}
                            columns={cols}
                            density={'compact'}
                            slots={{ toolbar: GridToolbar }}
                            slotProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                    csvOptions: { disableToolbarButton: true },
                                    printOptions: { disableToolbarButton: true },
                                },
                            }}
                            pagination
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 25 },
                                },
                            }}
                            pageSizeOptions={[25, 100, 250, 500]}
                        />
                    </Paper>
                </>
            )}
            {/*{rows.length > 0 && isAuthenticated && (*/}
            {/*    <>*/}
            {/*        <JsonData data={rows}></JsonData>*/}
            {/*    </>*/}
            {/*)}*/}
        </Box>
    )
}
