import { GridRenderCellParams } from '@mui/x-data-grid'
import { ReviewStatus, ReviewStatusFromString } from '../../ReviewStatus'
import ApiService from '../../../../services/api.service'
import ButtonGroup from '@mui/material/ButtonGroup'
import { Button, Checkbox, TextField, Tooltip } from '@mui/material'
import getReviewStatusButtonColor from '../../getReviewStatusButtonColor'
import * as React from 'react'
import { useEffect } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import FormControlLabel from '@mui/material/FormControlLabel'
import Stack from '@mui/material/Stack'

import SalesforceIcon from '../../../Shared/Icons/SalesforceIcon'
import LinkSalesforceAlert from '../../../Shared/LinkSalesforceAlert'
import getReviewStatusButtonVariant from '../../getReviewStatusButtonVariant'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    width: '75vw',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
}

export function RenderReviewActionCell({
    params,
    callback,
}: {
    params: GridRenderCellParams<any, any>
    callback: (params: GridRenderCellParams<any, any>) => void
}) {
    const [open, setOpen] = React.useState(false)
    const [canUseSalesforce, setCanUseSalesforce] = React.useState<boolean>(false)
    const [useSalesforce, setUseSalesforce] = React.useState<boolean>(false)
    const [comment, setComment] = React.useState('')

    useEffect(() => {
        setCanUseSalesforce(window.localStorage.getItem('has_sf_integration') !== null)
    }, [])

    if (params.value === undefined || params.value.toString() === '') {
        return <></>
    }

    function reviewItem(status: ReviewStatus, data: any, comment: any | undefined = undefined) {
        console.log(`on click`, status, data, canUseSalesforce)
        setOpen(false)
        ApiService.reviewProjectSearchFilter(
            data['project_id'],
            data['search_filter_id'],
            status,
            comment,
            useSalesforce,
        ).then((res) => {
            console.log(res)
            callback(params)
        })
    }

    if (params.value['search_results'].length === 0) {
        return <></>
    }
    const reviews = params.row['reviews']
    let status = ReviewStatus.NeedsReview
    if (reviews !== null && reviews.length > 0) {
        status = ReviewStatusFromString(reviews[reviews.length - 1]['status'])
    }

    const handleClose = () => setOpen(false)

    return (
        <ButtonGroup orientation={'horizontal'} size={'small'}>
            <Button
                key={`review-button-${params.value['project_id']}-${params.value['search_filter_id']}`}
                size="small"
                color={'primary'}
                variant={'contained'}
                sx={{ mr: 1 }}
                endIcon={<OpenInNewIcon />}
                onClick={(ev: any) => {
                    setOpen(true)
                }}
            >
                Add Review
            </Button>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography sx={{ mb: 2 }} component={'div'} variant={'h6'}>
                        Project: {params.value.project_name.toString()}
                    </Typography>
                    <Typography sx={{ mb: 2 }} component={'div'} variant={'body1'}>
                        Search Filter: {params.value.search_filter_name.toString()}
                    </Typography>
                    <TextField
                        autoFocus
                        fullWidth
                        label="Comment"
                        margin="normal"
                        name="comment"
                        multiline={true}
                        rows={3}
                        // onBlur={handleBlur}
                        onChange={(ev: any) => setComment(ev.target.value)}
                        type="text"
                        value={comment}
                        variant="outlined"
                    />

                    <Box sx={{ minWidth: 400, mb: 2, mt: 1 }}>
                        {status !== ReviewStatus.Pursuing && (
                            <>
                                {canUseSalesforce ? (
                                    <>
                                        <FormControlLabel
                                            value={useSalesforce}
                                            control={
                                                <Checkbox
                                                    onChange={() => {
                                                        setUseSalesforce(!useSalesforce)
                                                    }}
                                                />
                                            }
                                            label={
                                                <>
                                                    <Tooltip
                                                        title={
                                                            <React.Fragment>
                                                                In Salesforce, this will create an
                                                                opportunity "
                                                                {params.value['project_name']} -{' '}
                                                                {params.value['search_filter_name']}
                                                                "
                                                            </React.Fragment>
                                                        }
                                                    >
                                                        <Stack
                                                            alignItems="center"
                                                            direction="row"
                                                            gap={0}
                                                        >
                                                            <Typography variant="body1">
                                                                Create an opportunity in{' '}
                                                                <b>Salesforce</b>
                                                            </Typography>
                                                            <SalesforceIcon
                                                                sx={{ mr: 1, ml: 0.5 }}
                                                            />
                                                        </Stack>
                                                    </Tooltip>
                                                </>
                                            }
                                            labelPlacement="end"
                                        />
                                    </>
                                ) : (
                                    <>
                                        <LinkSalesforceAlert />
                                    </>
                                )}
                            </>
                        )}
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            '& > *': {
                                m: 0.1,
                            },
                        }}
                    >
                        <ButtonGroup orientation={'horizontal'} size={'small'} fullWidth>
                            {status !== ReviewStatus.Pursuing && (
                                <Button
                                    key={`${params.value['project_id']}-${params.value['search_filter_id']}-Pursuing`}
                                    size="small"
                                    color={getReviewStatusButtonColor(ReviewStatus.Pursuing)}
                                    variant={getReviewStatusButtonVariant(ReviewStatus.Pursuing)}
                                    sx={{ mr: 1 }}
                                    onClick={(ev: any) => {
                                        reviewItem(
                                            ReviewStatus.Pursuing,
                                            params.value || '',
                                            comment,
                                        )
                                    }}
                                >
                                    Pursue
                                </Button>
                            )}

                            {status !== ReviewStatus.NotPursuing && (
                                <Button
                                    key={`${params.value['project_id']}-${params.value['search_filter_id']}-NotPursuing`}
                                    size="small"
                                    color={getReviewStatusButtonColor(ReviewStatus.NotPursuing)}
                                    variant={getReviewStatusButtonVariant(ReviewStatus.NotPursuing)}
                                    sx={{ mr: 1 }}
                                    onClick={(ev: any) => {
                                        reviewItem(
                                            ReviewStatus.NotPursuing,
                                            params.value || '',
                                            comment,
                                        )
                                    }}
                                >
                                    Don't Pursue
                                </Button>
                            )}
                            {status !== ReviewStatus.NeedsReview && (
                                <Button
                                    key={`${params.value['project_id']}-${params.value['search_filter_id']}-NeedsReview`}
                                    size="small"
                                    color={getReviewStatusButtonColor(ReviewStatus.NeedsReview)}
                                    variant={getReviewStatusButtonVariant(ReviewStatus.NeedsReview)}
                                    sx={{ mr: 1 }}
                                    onClick={(ev: any) => {
                                        reviewItem(
                                            ReviewStatus.NeedsReview,
                                            params.value || '',
                                            comment,
                                        )
                                    }}
                                >
                                    Needs Review
                                </Button>
                            )}
                            {status !== ReviewStatus.NeedsQuote && (
                                <Button
                                    key={`${params.value['project_id']}-${params.value['search_filter_id']}-NeedsQuote`}
                                    size="small"
                                    color={getReviewStatusButtonColor(ReviewStatus.NeedsQuote)}
                                    variant={getReviewStatusButtonVariant(ReviewStatus.NeedsQuote)}
                                    sx={{ mr: 1 }}
                                    onClick={(ev: any) => {
                                        reviewItem(
                                            ReviewStatus.NeedsQuote,
                                            params.value || '',
                                            comment,
                                        )
                                    }}
                                >
                                    Needs Quote
                                </Button>
                            )}

                            {status !== ReviewStatus.Won && (
                                <Button
                                    key={`${params.value['project_id']}-${params.value['search_filter_id']}-Won`}
                                    size="small"
                                    color={getReviewStatusButtonColor(ReviewStatus.Won)}
                                    variant={getReviewStatusButtonVariant(ReviewStatus.Won)}
                                    sx={{ mr: 1 }}
                                    onClick={(ev: any) => {
                                        reviewItem(ReviewStatus.Won, params.value || '', comment)
                                    }}
                                >
                                    Won
                                </Button>
                            )}
                            {status !== ReviewStatus.Lost && (
                                <Button
                                    key={`${params.value['project_id']}-${params.value['search_filter_id']}-Lost`}
                                    size="small"
                                    color={getReviewStatusButtonColor(ReviewStatus.Lost)}
                                    variant={getReviewStatusButtonVariant(ReviewStatus.Lost)}
                                    sx={{ mr: 1 }}
                                    onClick={(ev: any) => {
                                        reviewItem(ReviewStatus.Lost, params.value || '', comment)
                                    }}
                                >
                                    Lost
                                </Button>
                            )}
                        </ButtonGroup>
                    </Box>
                </Box>
            </Modal>
        </ButtonGroup>
    )
}
