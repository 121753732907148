import * as React from 'react'
import { useEffect } from 'react'
import Stack from '@mui/material/Stack'
import ApiService from '../../../services/api.service'
import Paper from '@mui/material/Paper'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Button, Chip, Divider, Grid, Typography } from '@mui/material'
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro'
import { GridToolbar } from '@mui/x-data-grid'
import { scrollToTop } from '../../Shared/scrollToTop'
import { StripedDataGridPro } from '../../Dashboard/StripedDataGridPro'
import DashboardV4 from '../../Dashboard/V3/DashboardV4'

export default function SearchFilterSearch() {
    const { id } = useParams()
    const navigate = useNavigate()
    const [searchFilter, setSearchFilter] = React.useState<any>(null)
    const [searchFilterTermsFound, setSearchFilterTermsFound] = React.useState<any>(null)
    const [rows, setRows] = React.useState<any[]>([])
    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'Project',
            sortable: true,
            minWidth: 120,
            flex: 1,
            renderCell: (params) => {
                return (
                    <Box
                        key={`${params.row['project']['id']}-${params.row['upload']['id']}-${params.row['id']}`}
                    >
                        {params.row['project']['name']}
                    </Box>
                )
            },
        },
        {
            field: 'upload',
            headerName: 'Upload',
            sortable: true,
            minWidth: 120,
            flex: 1.2,
            renderCell: (params) => {
                return <>{params.row['upload']['name']}</>
            },
        },
        {
            field: 'page_number',
            headerName: 'Page',
            minWidth: 120,
            flex: 0.2,
        },
        {
            field: 'term',
            headerName: 'Terms',
            minWidth: 20,
            flex: 1,
        },

        {
            field: 'View',
            headerName: 'View',
            sortable: false,
            minWidth: 20,
            flex: 1,
            renderCell: (params) => {
                const onViewPage = (e: any) => {
                    e.stopPropagation()
                    console.log(params.row)
                    const url = ApiService.viewSearchResultUrl(params.row.id)
                    window.open(url, '_blank')
                    return
                }
                const onViewDoc = (e: any) => {
                    e.stopPropagation()
                    const url = ApiService.viewUploadUrl(params.row.upload_id)
                    window.open(url, '_blank')
                    return
                }
                return (
                    <Stack direction="row" spacing={2}>
                        <Button
                            fullWidth
                            variant="outlined"
                            onClick={onViewPage}
                            size="small"
                            color={'success'}
                            sx={{
                                maxWidth: 100,
                            }}
                        >
                            View Page
                        </Button>
                        <Button
                            fullWidth
                            variant="outlined"
                            onClick={onViewDoc}
                            size="small"
                            color={'primary'}
                            sx={{
                                maxWidth: 100,
                            }}
                        >
                            View Doc
                        </Button>
                    </Stack>
                )
            },
        },
    ]

    useEffect(() => {
        async function load() {
            scrollToTop()
            const searchFilterRes = await ApiService.getSearchFilterById(id || '')
            setSearchFilter(searchFilterRes.data)
            setSearchFilterTermsFound([])
            ApiService.searchDocuments(id || '').then((res) => {
                setRows(res.data['results'])
                const termsInResults: string[] = []
                res.data['results'].forEach((item: any) => {
                    termsInResults.push(item['term'])
                })
                setSearchFilterTermsFound(termsInResults)
            })
        }

        load().then((r) => {})

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (id === undefined || searchFilter === undefined || searchFilter === null) {
        return <></>
    }

    return (
        <Box sx={{ p: 2 }}>
            <Box sx={{ p: 2 }} component={Paper}>
                {searchFilter && (
                    <Box sx={{ textAlign: 'left' }}>
                        <Grid container>
                            <Grid item xs={10}>
                                <Box
                                    display="flex"
                                    justifyContent="flex-start"
                                    key={`search-filter-title`}
                                >
                                    <Typography color="text" variant="h4">
                                        {searchFilter['name']} ({searchFilter['category']})
                                    </Typography>
                                </Box>
                                <Divider sx={{ width: '100%', mb: 2, mt: 1 }}></Divider>
                                <Box
                                    display="flex"
                                    justifyContent="flex-start"
                                    key={`search-filter-terms-title`}
                                    sx={{
                                        mb: 2,
                                    }}
                                >
                                    <Typography variant="h5" gutterBottom>
                                        Search Terms
                                    </Typography>
                                </Box>
                                <Box justifyContent="flex-start" key={`search-filter-terms-box`}>
                                    <Grid container spacing={1} key={`chip-grid`}>
                                        {searchFilter['terms'].map((item: any) => (
                                            <Grid item xs={1} key={`chip-grid-${item['id']}`}>
                                                <Chip
                                                    key={`chip-key-${item['name']}`}
                                                    label={item['name']}
                                                    color={
                                                        searchFilterTermsFound.indexOf(
                                                            item['name'],
                                                        ) === -1
                                                            ? 'default'
                                                            : 'primary'
                                                    }
                                                    // variant={searchFilterTermsFound.indexOf(term) === -1 ? 'filled' : 'outlined'}
                                                    sx={{
                                                        fontSize: '12px',
                                                        border: '0.01em solid #bdbdbd',
                                                        borderRadius: '0.5em',
                                                        width: '100%',
                                                    }}
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Box>
                            </Grid>

                            <Grid item xs={2}>
                                <Box display="flex" justifyContent="flex-end">
                                    <Chip
                                        label="Edit"
                                        variant={'filled'}
                                        color={'secondary'}
                                        onClick={function onClick() {
                                            navigate(`/search-filters/${id}/edit`)
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                )}
                <Box sx={{ alignItems: 'left', textAlign: 'left', pt: 2 }}>
                    <Stack spacing={1}>
                        {rows === undefined && <>loading</>}
                        {rows !== undefined && rows.length === 0 && <>loading</>}
                        <StripedDataGridPro
                            rows={rows}
                            columns={columns}
                            density={'compact'}
                            // slots={{
                            //     toolbar: CustomToolbar
                            // }}
                            slots={{ toolbar: GridToolbar }}
                            slotProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                },
                            }}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        page: 0,
                                        pageSize: 10,
                                    },
                                },
                            }}
                            pagination
                            pageSizeOptions={[10, 50, 250, 1000]}
                        />
                    </Stack>
                </Box>
                <Box sx={{ alignItems: 'left', textAlign: 'left', pt: 2 }}>
                    <Typography variant="h5" gutterBottom>
                        Dashboard
                    </Typography>
                    <DashboardV4 searchFilterId={searchFilter['id']}></DashboardV4>
                </Box>
            </Box>
        </Box>
    )
}
