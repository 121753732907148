import * as React from 'react'
import { useEffect } from 'react'
import Stack from '@mui/material/Stack'
import ApiService from '../../../services/api.service'
import AddNewSearchFilterButton from '../SearchFilter/AddNewSearchFilterButton'
import ButtonGroup from '@mui/material/ButtonGroup'
import Paper from '@mui/material/Paper'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Box, Button, CardActionArea, CardActions, Select, SelectChangeEvent } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import { GridColDef, GridToolbar } from '@mui/x-data-grid'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { DataGridPro } from '@mui/x-data-grid-pro'

export default function DocumentSearch() {
    const navigate = useNavigate()
    const [searchFilterList, setSearchFilterList] = React.useState<any[]>([])
    const [searchFilter, setSearchFilter] = React.useState<any>(null)
    const [searchParams, setSearchParams] = useSearchParams()

    const [rows, setRows] = React.useState<any[]>([])
    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'File',
            minWidth: 250,
            sortable: true,
        },
        {
            field: 'page_number',
            headerName: 'Page',
            minWidth: 20,
        },
        {
            field: 'term',
            headerName: 'Terms',
            minWidth: 20,
        },
        {
            field: 'previews',
            headerName: 'Matches',
            sortable: false,
            minWidth: 250,
            renderCell: (params) => {
                return JSON.stringify(params.row)
                // const els = new Map();
                // params.row.previews.forEach((item: any) => {
                //     let cur = item['term'].toString();
                //     if (els.get(cur) !== undefined) {
                //         els.set(cur, els.get(cur) + 1);
                //     } else {
                //         els.set(cur, 1);
                //     }
                // })
                // let res = ``;
                // // @ts-ignore
                // for (let [key, value] of els.entries()) {
                //     res = `${key}: ${value.toString()} ${res}`;
                // }
                // return res;
            },
        },
        {
            field: 'action',
            headerName: '',
            sortable: false,
            minWidth: 300,
            renderCell: (params) => {
                const onViewPage = (e: any) => {
                    e.stopPropagation()
                    console.log(params.row)
                    const url = ApiService.viewSearchResultUrl(params.row.id)
                    window.open(url, '_blank')
                    return
                }
                const onViewDoc = (e: any) => {
                    e.stopPropagation()
                    const url = ApiService.viewUploadUrl(params.row.upload_id)
                    window.open(url, '_blank')
                    return
                }
                return (
                    <Stack direction="row" spacing={2}>
                        <Button
                            fullWidth
                            variant="outlined"
                            onClick={onViewPage}
                            size="small"
                            color={'success'}
                            sx={{
                                maxWidth: 100,
                            }}
                        >
                            View Page
                        </Button>
                        <Button
                            fullWidth
                            variant="outlined"
                            onClick={onViewDoc}
                            size="small"
                            color={'primary'}
                            sx={{
                                maxWidth: 100,
                            }}
                        >
                            View Doc
                        </Button>
                    </Stack>
                )
            },
        },
    ]

    useEffect(() => {
        async function load() {
            const result = await ApiService.getAllSearchGroups()
            if (result.status === 200) {
                setSearchFilterList(result.data)
                if (searchParams.get('g') !== undefined) {
                    searchParams.get('g')
                    const found = result.data.find((x: any) => x.id === searchParams.get('g'))
                    setSearchFilter(found)
                    if (found !== undefined) {
                        updateSearchResults()
                    }
                }
            }
        }

        // noinspection JSIgnoredPromiseFromCall
        load()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (searchFilterList.length === 0) {
        return <AddNewSearchFilterButton></AddNewSearchFilterButton>
    }

    function editSearchGroup() {
        navigate(`/search-filters/${searchFilter['id']}/edit`)
    }

    function updateSearchResults() {
        if (searchFilter !== undefined && searchFilter !== null) {
            ApiService.searchDocuments(searchFilter['id'].toString()).then((res) => {
                setRows(res.data['results'])
                setSearchParams({
                    g: searchFilter['id'],
                })
            })
        }
    }

    const handleChange = (event: SelectChangeEvent) => {
        setSearchFilter(event.target.value)
        const data = JSON.parse(JSON.stringify(event.target.value))
        setSearchParams({
            g: data['id'],
        })
    }

    return (
        <>
            <Box sx={{ alignItems: 'left', textAlign: 'left', p: 2 }}>
                <Grid container spacing={1} alignItems={'left'} alignContent={'left'}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Card sx={{ maxWidth: 345, mb: 1 }}>
                            <CardActionArea disableRipple disableTouchRipple>
                                <CardContent>
                                    {searchFilterList && (
                                        <FormControl
                                            fullWidth
                                            size={'small'}
                                            sx={{
                                                alignItems: 'left',
                                                textAlign: 'left',
                                            }}
                                        >
                                            <InputLabel>Search Filter</InputLabel>
                                            <Select
                                                labelId="demo-select-small-label"
                                                id="demo-select-small"
                                                value={searchFilter}
                                                label="Search Filter"
                                                onChange={handleChange}
                                            >
                                                {searchFilterList &&
                                                    searchFilterList.map((item) => (
                                                        <MenuItem value={item} key={item.id}>
                                                            {item.name}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    )}
                                </CardContent>
                            </CardActionArea>
                            <CardActions>
                                <Button
                                    fullWidth
                                    variant="outlined"
                                    color={'secondary'}
                                    size={'small'}
                                    // sx={{mt: 1, mb: 1, minWidth: 250, maxWidth: "500px"}}
                                    onClick={editSearchGroup}
                                >
                                    Edit Search Group
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <ButtonGroup fullWidth orientation={'vertical'} sx={{ mb: 1 }}>
                            <Button
                                sx={{ maxWidth: 345 }}
                                variant="contained"
                                size={'large'}
                                color={'primary'}
                                onClick={updateSearchResults}
                            >
                                Search
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>

                <Stack spacing={1}>
                    {rows !== undefined && rows.length > 0 && (
                        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                            <DataGridPro
                                rows={rows}
                                columns={columns}
                                density={'compact'}
                                slots={{ toolbar: GridToolbar }}
                                slotProps={{
                                    toolbar: {
                                        showQuickFilter: true,
                                    },
                                }}
                                initialState={{
                                    pagination: {
                                        paginationModel: { page: 0, pageSize: 50 },
                                    },
                                }}
                                pageSizeOptions={[50, 250, 500]}
                            />
                        </Paper>
                    )}
                </Stack>
            </Box>
        </>
    )
}
