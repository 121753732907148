import { GridRenderCellParams } from '@mui/x-data-grid'
import { Button, Container, Divider, LinearProgress, Typography } from '@mui/material'
import * as React from 'react'
import { useEffect, useState } from 'react'
import ApiService from '../../../services/api.service'
import { DataRow } from './DataRow'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import { Simulate } from 'react-dom/test-utils'
import load = Simulate.load

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90vw',
    maxHeight: '90vh',
    maxWidth: '90vw',
    bgcolor: 'background.paper',
    overflow: 'scroll',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
}

export function RenderViewCell({
    params,
    onOpen,
    onClose,
}: {
    params: GridRenderCellParams<DataRow, any>
    onOpen: () => void
    onClose: () => void
}) {
    const [pdfPath, setPdfPath] = useState<string | null>(null)
    const [numPages, setNumPages] = useState(1)
    const [loading, setLoading] = useState(true)
    const [open, setOpen] = useState(false)
    const doOnOpen = onOpen
    const doOnClose = onClose

    // eslint-disable-next-line react-hooks/rules-of-hooks
    // useEffect(() => {
    //     if (params?.value?.length === 0) {
    //         setStatus(ReviewStatus.NeedsReview)
    //     } else {
    //         const review = params.value[params.value.length - 1]
    //         console.log(params.value)
    //         setStatus(ReviewStatusFromString(review.status))
    //     }
    //     setCanUseSalesforce(window.localStorage.getItem('has_sf_integration') !== null)
    //     // eslint-disable-next-line
    // }, [])

    const handleClose = () => {
        setOpen(false)
        doOnClose()
    }
    useEffect(() => {
        const close = (e: any) => {
            if (e.keyCode === 27) {
                handleClose()
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (params.rowNode.type === 'group' || params?.value?.length === 0) {
        return <></>
    }

    return (
        <>
            <>
                <Modal
                    open={open}
                    onClose={handleClose}
                    disableEscapeKeyDown={false}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    style={{ overflow: 'auto' }}
                >
                    <>
                        <Box component={Paper} sx={style}>
                            <Typography
                                id="modal-modal-title"
                                variant="h6"
                                component="h2"
                                sx={{ mb: 2 }}
                            >
                                {params.row.project_name} / {params.row.search_filter_name}
                            </Typography>
                            <Divider sx={{ mb: 2 }} />
                            <Container sx={{ mb: 1 }}>
                                {!loading && (
                                    <Button
                                        fullWidth
                                        variant={'contained'}
                                        href={pdfPath || ''}
                                        target={'_blank'}
                                    >
                                        Download {numPages} pages
                                    </Button>
                                )}
                                {loading && (
                                    <>
                                        <Box sx={{ marginBottom: 2 }}>
                                            <Typography>Generating Preview</Typography>
                                        </Box>
                                        <LinearProgress></LinearProgress>
                                    </>
                                )}
                            </Container>
                            <Container sx={{ mb: 1 }}>
                                <b>Terms: </b> {params.row.terms}
                            </Container>

                            {/*<Container>*/}
                            {/*    {pdfPath !== null && (*/}
                            {/*        <PDFViewer path={pdfPath} numPages={numPages} />*/}
                            {/*    )}*/}
                            {/*</Container>*/}
                        </Box>
                        {/*<PDFViewer path={pdfPath}></PDFViewer>*/}
                    </>
                </Modal>
                <Button
                    disabled={open}
                    variant={'outlined'}
                    onClick={() => {
                        setOpen(true)
                        setLoading(true)
                        ApiService.viewSearchResults(
                            params.row.project_id,
                            params.row.search_filter_id,
                        ).then((res) => {
                            setNumPages(res.data.num_pages)
                            setPdfPath(res.data.url)
                            setLoading(false)
                            window.open(res.data.url)
                            // doOnOpen()
                            // window.open(res.data.url, '_blank')
                        })
                    }}
                >
                    Preview
                </Button>
            </>
        </>
    )
}
