import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Home from './components/Home/Home'
import SearchFilter from './components/Search/SearchFilter/SearchFilter'
import LoginForm from './components/Login/LoginForm'
import SearchFilterCreate from './components/Search/SearchFilter/SearchFilterCreate/SearchFilterCreate'
import ListUsers from './components/Admin/Users/ListUsers'
import React from 'react'
import ListOrgs from './components/Admin/Orgs/ListOrgs'
import Projects from './components/Projects/Projects'
import ProjectsCreate from './components/Projects/ProjectsCreate/ProjectsCreate'
import AdminHome from './components/Admin/AdminHome'
import DocumentSearch from './components/Search/DocumentSearch/DocumentSearch'
import ListUploads from './components/Upload/ListUploads/ListUploads'
import LogoutForm from './components/Login/LogoutForm'
import { ProfileComponent } from './components/Login/Profile'
import FAQ from './components/Examples/landing-page/components/FAQ'
import Pricing from './components/Examples/landing-page/components/Pricing'
import Features from './components/Examples/landing-page/components/Features'
import Highlights from './components/Examples/landing-page/components/Highlights'
import Terms from './components/Examples/landing-page/components/Terms'
import Privacy from './components/Examples/landing-page/components/Privacy'
import Contact from './components/Examples/landing-page/components/Contact'
import Checkout from './components/Examples/checkout/Checkout'
import ListProjectSearchFilterReviews from './components/Dashboard/ListProjectSearchFilterReviews/ListProjectSearchFilterReviews'
import SearchFilterSearch from './components/Search/SearchFilterSearch/SearchFilterSearch'
import IntegrationsPage from './components/Integrations/IntegrationsPage'
import ListProjectUploads from './components/Upload/List/ListProjectUploads'
import SalesforceLoginForm from './components/Login/SalesforceLoginForm'
import MyAccountPage from './components/Login/MyAccountPage'
import { Container, Divider, Typography } from '@mui/material'
import AnalyticsPage from './components/Analytics/AnalyticsPage'
import EditProject from './components/Projects/Edit/EditProject'
import { AnalyticsKind } from './components/Analytics/AnalyticsKind'
import ListUserReviews from './components/Analytics/ListUserReviews'
import ManageUsers from './components/Admin/Users/ManageUsers'
import DashboardV2 from './components/Dashboard/ListProjectSearchFilterReviews/DashboardV2'
import { ListOrganizationsPage } from './components/Admin/Orgs/ListOrganizationsPage'
import DashboardV3 from './components/Dashboard/V3/DashboardV3'
import DashboardV4 from './components/Dashboard/V3/DashboardV4'
import SearchFilterReviewList from './components/Search/SearchFilter/SearchFilterReviewList/SearchFilterReviewList'
import ProjectDashboard from './components/Projects/List/ProjectDashboard'
import ListOrganizationProcesses from './components/Organization/ListOrganizationProcesses/ListOrganizationProcesses'
import ManageSalesforceIntegration from './components/Integrations/Salesforce/ManageSalesforceIntegration'
import ListProjectSearchResults from './components/Shared/ProjectSearchResults/ListProjectSearchResults'

const AppRouter = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/account" element={<MyAccountPage />} />
                <Route path="/me" element={<MyAccountPage />} />
                <Route path="/integrations" element={<IntegrationsPage />} />
                <Route path="/profile" element={<ProfileComponent />} />
                <Route path="/login" element={<LoginForm />} />
                <Route path="/login/salesforce" element={<SalesforceLoginForm />} />
                <Route path="/dashboard/v2" element={<DashboardV2 />} />
                <Route
                    path="/dashboard/v3"
                    element={
                        <Container sx={{ mt: 2 }} maxWidth={false}>
                            <DashboardV3 />
                        </Container>
                    }
                />
                <Route
                    path="/dashboard"
                    element={
                        <Container sx={{ mt: 2 }} maxWidth={false}>
                            <DashboardV4 />
                        </Container>
                    }
                />
                <Route
                    path="/dashboard/v4"
                    element={
                        <Container sx={{ mt: 2 }} maxWidth={false}>
                            <DashboardV4 />
                        </Container>
                    }
                />

                <Route path="/dashboard/legacy" element={<ListProjectSearchFilterReviews />} />

                <Route path="/search-filter-reviews" element={<SearchFilterReviewList />} />
                <Route path="/search-filters" element={<SearchFilter />} />
                <Route path="/search-filters/add" element={<SearchFilterCreate />} />
                <Route path="/search-filters/:id/edit" element={<SearchFilterCreate />} />
                <Route path="/search-filters/:id/search" element={<SearchFilterSearch />} />
                <Route
                    path="/search-filters/:searchFilterId/dashboard"
                    element={<ProjectDashboard />}
                />
                <Route path="/search" element={<DocumentSearch />} />
                <Route path="/uploads" element={<ListUploads />} />
                <Route path="/project-search-results" element={<ListProjectSearchResults />} />
                <Route path="/logout" element={<LogoutForm />} />
                <Route path="/projects" element={<Projects />} />
                <Route path="/projects/:id/edit" element={<EditProject />} />
                <Route path="/projects/:projectId/dashboard" element={<ProjectDashboard />} />
                <Route
                    path="/projects/:projectId/search-filters/:searchFilterId/dashboard"
                    element={<ProjectDashboard />}
                />
                <Route
                    path="/projects/:id/uploads"
                    element={
                        <ListProjectUploads
                            projectId={undefined}
                            uploads={[]}
                            project={undefined}
                        />
                    }
                />
                <Route path="/projects/add" element={<ProjectsCreate />} />
                <Route path="/admin" element={<AdminHome />} />
                <Route path="/admin/users" element={<ListUsers />} />
                <Route path="/admin/orgs" element={<ListOrgs />} />
                <Route
                    path="/admin/manage-users"
                    element={
                        <Container maxWidth={'xl'} sx={{ pt: 2 }}>
                            <ManageUsers />
                        </Container>
                    }
                />
                <Route
                    path="/account/processes"
                    element={
                        <Container maxWidth={'xl'} sx={{ pt: 2 }}>
                            <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
                                Organization Processes
                            </Typography>
                            <Divider sx={{ width: '100%', mb: 2 }}></Divider>
                            <ListOrganizationProcesses></ListOrganizationProcesses>
                        </Container>
                    }
                />

                <Route path="/admin/organizations" element={<ListOrganizationsPage />} />

                <Route
                    path="/pricing"
                    element={
                        <>
                            <Container sx={{ mt: 2 }}>
                                <Pricing />
                            </Container>
                        </>
                    }
                />
                <Route path="/contact" element={<Contact />} />
                <Route path="/features" element={<Features />} />
                <Route path="/highlights" element={<Highlights />} />
                <Route path="/faq" element={<FAQ />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/checkout" element={<Checkout />} />
                {/*<Route*/}
                {/*    path="/upload"*/}
                {/*    element={*/}
                {/*        <Container sx={{ mt: 2 }}>*/}
                {/*            <UploadFilesModal projectId={'4b8ebca9-b97b-42b7-bf74-dca8410e3ea3'} />*/}
                {/*        </Container>*/}
                {/*    }*/}
                {/*></Route>*/}
                <Route path="/analytics" element={<AnalyticsPage />} />

                <Route
                    path="/analytics/user-reviews"
                    element={
                        <Container>
                            <ListUserReviews kind={AnalyticsKind.ByReviewer}></ListUserReviews>
                        </Container>
                    }
                />
                <Route
                    path="/analytics/search-filter"
                    element={
                        <Container>
                            <ListUserReviews
                                kind={AnalyticsKind.BySearchFilterName}
                            ></ListUserReviews>
                        </Container>
                    }
                />
                <Route
                    path="/analytics/search-filter-category"
                    element={
                        <Container>
                            <ListUserReviews
                                kind={AnalyticsKind.BySearchFilterCategory}
                            ></ListUserReviews>
                        </Container>
                    }
                />
                <Route
                    path="/account/integrations/salesforce"
                    element={
                        <Container>
                            <ManageSalesforceIntegration></ManageSalesforceIntegration>
                        </Container>
                    }
                />
            </Routes>
        </Router>
    )
}

export default AppRouter
