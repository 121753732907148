import * as React from 'react'
import { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import AddIcon from '@mui/icons-material/Add'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { DropzoneArea } from 'mui-file-dropzone'
import UploadFileService from '../../../services/upload-file.service'
import { Alert, LinearProgress } from '@mui/material'
import { useParams } from 'react-router-dom'
import { Config } from '../../../config/config'

export default function UploadFilesModal(
    props: { projectId: string | undefined } = { projectId: undefined },
) {
    const { id } = useParams()
    const [upload, setUpload] = React.useState(false)
    const [files, setFiles] = React.useState<File[]>([])
    const [progressInfos, setProgressInfos] = useState<any[]>([])

    function addProgressInfo(id: any, data: any) {
        setProgressInfos((names) => [...names, { id: id, data: data }])
    }

    function updateProgressInfo(id: any, data: any) {
        setProgressInfos((names) => [...names.filter((x) => x.id !== id), { id: id, data: data }])
    }

    // const createFile = ({name}) => ;
    const handlePreviewIcon = (fileObject: any, classes: any) => {
        const { type } = fileObject.file

        const base64ImageData = fileObject.data
        const contentType = type

        const byteCharacters = atob(base64ImageData.substr(`data:${contentType};base64,`.length))
        const byteArrays = []

        for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
            const slice = byteCharacters.slice(offset, offset + 1024)

            const byteNumbers = new Array(slice.length)
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i)
            }

            const byteArray = new Uint8Array(byteNumbers)

            byteArrays.push(byteArray)
        }
        const blob = new Blob(byteArrays, { type: contentType })
        const blobUrl = URL.createObjectURL(blob)
        return (
            <Typography
                fontSize={'small'}
                color="secondary"
                sx={{
                    textAlign: 'left',
                    width: 100,
                    cursor: 'pointer',
                }}
                onClick={() => window.open(blobUrl, '_blank')}
            >
                {'Preview'}
            </Typography>
        )
    }

    function uploadNow(idx: any, file: File) {
        UploadFileService.upload(
            file,
            props?.projectId ? props.projectId : id || '',
            (event: any) => {
                updateProgressInfo(idx, {
                    progress: Math.round(event.progress * 100),
                    done: false,
                    idx: idx,
                    message: `Started ${file.name}`,
                    filename: file.name,
                })
                return {
                    items: progressInfos,
                }
            },
        )
            .then((nextData) => {
                updateProgressInfo(idx, {
                    progress: 100,
                    done: true,
                    idx: idx,
                    message: `finished ${file.name}`,
                    filename: file.name,
                })
                return {
                    progressInfos: progressInfos,
                }
            })
            .then((res) => {
                console.log('then.. ', idx, progressInfos, res)
            })
            .catch((err) => {
                console.warn(err)
            })
    }

    useEffect(() => {
        if (progressInfos.length > 0) {
            const progresses: any[] = []
            progressInfos.forEach((item) => {
                progresses.push(item.data.progress)
                if (new Set(progresses).size === 1) {
                    if (new Set(progresses).has(100)) {
                        setUpload(false)
                    }
                }
            })
        }
    }, [progressInfos])

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12} xl={12}>
                    <Box sx={{ mb: 2 }}>
                        <Button
                            variant="contained"
                            size="small"
                            startIcon={<AddIcon />}
                            sx={{ mr: 1 }}
                            onClick={(e) => {
                                setUpload(true)
                            }}
                        >
                            Add files
                        </Button>
                    </Box>
                    <Dialog
                        fullWidth={true}
                        maxWidth={'xl'}
                        open={upload}
                        onClose={(event, reason) => {
                            switch (reason) {
                                case 'backdropClick':
                                    setUpload(false)
                                    break
                                case 'escapeKeyDown':
                                    setUpload(false)
                                    break
                            }
                        }}
                    >
                        <DialogTitle>Upload Files</DialogTitle>
                        <DialogContent sx={{ padding: 1, maxHeight: '100vh' }}>
                            <>
                                {/*{progressInfos.length > 0 &&*/}
                                {/*    <>*/}
                                {/*        <JsonData data={progressInfos}></JsonData>*/}
                                {/*    </>*/}
                                {/*}*/}
                                {progressInfos.length > 0 && files.length > 0 && (
                                    <>
                                        <Box></Box>
                                        {progressInfos.filter((n: any) => n.data.done).length !==
                                        progressInfos.length ? (
                                            <>
                                                <Box mt={2} mb={2} mr={2} ml={2}>
                                                    <Typography variant={'body2'} component={'div'}>
                                                        Processing{' '}
                                                        {
                                                            progressInfos.filter(
                                                                (n: any) => n.data.done,
                                                            ).length
                                                        }
                                                        /{progressInfos.length}
                                                    </Typography>
                                                    <LinearProgress
                                                        variant="determinate"
                                                        value={
                                                            (progressInfos.filter(
                                                                (n: any) => n.data.done,
                                                            ).length /
                                                                progressInfos.length) *
                                                            100
                                                        }
                                                    />
                                                </Box>
                                            </>
                                        ) : (
                                            <>
                                                {files.length > 0 && progressInfos.length > 0 && (
                                                    <Box mt={2} mr={2} ml={2}>
                                                        <Alert severity={'success'}>
                                                            Finished uploading {files.length} files
                                                        </Alert>
                                                    </Box>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}

                                <Box sx={{ m: 2 }}>
                                    <DropzoneArea
                                        maxFileSize={10 * 1000 * 1000 * 1000}
                                        clearOnUnmount={true}
                                        filesLimit={Config.UploadsLimit}
                                        showPreviewsInDropzone={true}
                                        fileObjects={files}
                                        showAlerts={true}
                                        alertSnackbarProps={{
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            },
                                            autoHideDuration: 2000,
                                        }}
                                        getFileAddedMessage={(fileName) => {
                                            return `added file ${fileName.slice(0, fileName.toString().length > 20 ? 20 : fileName.toString().length)}`
                                        }}
                                        getFileRemovedMessage={(fileName) => {
                                            return `removed file ${fileName.slice(0, fileName.toString().length > 20 ? 20 : fileName.toString().length)}`
                                        }}
                                        previewGridProps={{
                                            container: {
                                                spacing: 1,
                                                paddingTop: 2,
                                                direction: 'column',
                                            },
                                        }}
                                        dropzoneText={'Drop files here'}
                                        showFileNamesInPreview={true}
                                        useChipsForPreview={true}
                                        onChange={(files: File[]) => setFiles(files)}
                                        getPreviewIcon={handlePreviewIcon}
                                        onDelete={() => {}}
                                    />
                                </Box>
                            </>
                        </DialogContent>
                        <DialogActions sx={{ ml: 2, mr: 2, mb: 2 }}>
                            <Button
                                onClick={() => {
                                    setUpload(false)
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                color="success"
                                disabled={files.length === 0}
                                onClick={async (e) => {
                                    setProgressInfos([])
                                    for (let i = 0; i < files.length; i++) {
                                        addProgressInfo(i, {
                                            progress: 0,
                                            done: false,
                                            message: `Uploading ${files[i].name}`,
                                            filename: files[i].name,
                                        })
                                    }
                                    for (let i = 0; i < files.length; i++) {
                                        uploadNow(i, files[i])
                                    }
                                }}
                            >
                                Start Upload
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Grid>
            </Grid>
        </Box>
    )
}
