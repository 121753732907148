import React from 'react'
import { Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

function AddNewSearchFilterButton() {
    return (
        <Button
            variant="contained"
            color="secondary"
            href={'/search-filters/add'}
            endIcon={<AddIcon />}
        >
            New Search Filter
        </Button>
    )
}

export default AddNewSearchFilterButton
