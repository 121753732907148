import { GridRenderCellParams } from '@mui/x-data-grid'
import ButtonGroup from '@mui/material/ButtonGroup'
import { Box, Button, Checkbox, TextField, Tooltip, Typography } from '@mui/material'
import * as React from 'react'
import { useEffect, useState } from 'react'

import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { ReviewStatus, ReviewStatusFromString, ReviewStatusText } from '../ReviewStatus'
import ApiService from '../../../services/api.service'
import getReviewStatusButtonColor from '../getReviewStatusButtonColor'
import getReviewStatusButtonVariant from '../getReviewStatusButtonVariant'
import { DataRow } from './DataRow'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Grid from '@mui/material/Grid'
import LinkSalesforceAlert from '../../Shared/LinkSalesforceAlert'
import FormControlLabel from '@mui/material/FormControlLabel'
import Stack from '@mui/material/Stack'
import SalesforceIcon from '../../Shared/Icons/SalesforceIcon'
import ResponsiveButton from './ResponsiveButton'

export function RenderReviewCell({
    params,
    callback,
}: {
    params: GridRenderCellParams<DataRow, any>
    callback: (params: GridRenderCellParams<any, any>) => void
}) {
    const [open, setOpen] = React.useState(false)
    const [canUseSalesforce, setCanUseSalesforce] = React.useState<boolean>(false)
    const [useSalesforce, setUseSalesforce] = React.useState<boolean>(false)
    const [comment, setComment] = React.useState('')
    const [status, setStatus] = useState<ReviewStatus>(ReviewStatus.NeedsReview)

    if (params.rowNode.type === 'group') {
        return <></>
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if (params.value !== undefined && params.value !== null) {
            if (params?.value?.length === 0) {
                setStatus(ReviewStatus.NeedsReview)
            } else {
                let status = ReviewStatus.NeedsReview.toString()
                switch (typeof params.value) {
                    case 'undefined':
                        break
                    case 'object':
                        status = params.value[params.value.length - 1].status
                        break
                    case 'boolean':
                        break
                    case 'number':
                        break
                    case 'string':
                        status = params.value.toString()
                        break
                    case 'function':
                        break
                    case 'symbol':
                        break
                    case 'bigint':
                        break
                }
                try {
                    setStatus(ReviewStatusFromString(status))
                } catch (e) {
                    setStatus(ReviewStatus.NeedsReview)
                    console.warn(e, params.value)
                }
            }
            setCanUseSalesforce(window.localStorage.getItem('has_sf_integration') !== null)
        } else {
            setStatus(ReviewStatus.NeedsReview)
        }

        // eslint-disable-next-line
    }, [])

    const handleClose = () => setOpen(false)

    return (
        <Box
            sx={{
                maxWidth: 150,
                marginTop: 0,
                paddingBottom: 2,
            }}
        >
            <ResponsiveButton
                size="small"
                color={getReviewStatusButtonColor(status)}
                variant={getReviewStatusButtonVariant(status)}
                endIcon={<OpenInNewIcon />}
                sx={{
                    paddingLeft: 2.5,
                    paddingRight: 2.5,
                }}
                fullWidth
                onClick={(ev: any) => {
                    setOpen(true)
                }}
            >
                {ReviewStatusText(status)}
            </ResponsiveButton>
            {/*<ButtonGroup orientation={'horizontal'} size={'small'} fullWidth>*/}
            {/*    */}
            {/*    /!*<Button*!/*/}
            {/*    /!*    size="small"*!/*/}
            {/*    /!*    color={getReviewStatusButtonColor(status)}*!/*/}
            {/*    /!*    variant={getReviewStatusButtonVariant(status)}*!/*/}
            {/*    /!*    endIcon={<OpenInNewIcon />}*!/*/}
            {/*    /!*    onClick={(ev: any) => {*!/*/}
            {/*    /!*        setOpen(true)*!/*/}
            {/*    /!*    }}*!/*/}
            {/*    /!*>*!/*/}
            {/*    /!*    {ReviewStatusText(status)}*!/*/}
            {/*    /!*</Button>*!/*/}
            {/*</ButtonGroup>*/}
            <Dialog
                fullScreen={false}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                PaperProps={{
                    sx: {
                        height: '80vh',
                        maxHeight: '700px',
                        // height: '100%',
                        width: '100%',
                        maxWidth: '1200px',
                    },
                }}
                // sx={{
                //     '& .MuiDialog-container': {
                //         '& .MuiPaper-root': {
                //             height: '80vh',
                //             maxHeight: '700px',
                //             // height: '100%',
                //             width: '100%',
                //             maxWidth: '1200px',
                //         },
                //     },
                // }}
            >
                <DialogTitle id="responsive-dialog-title">{params.row.project_name}</DialogTitle>
                <DialogContent>
                    <Grid container sx={{ textAlign: 'left', marginBottom: 1 }}>
                        <Grid item xs={2} sx={{ fontWeight: 'bold' }}>
                            Project
                        </Grid>
                        <Grid item xs={10}>
                            <Button
                                variant={'outlined'}
                                endIcon={<OpenInNewIcon />}
                                target={'_blank'}
                                size={'small'}
                                href={`/projects/${params.row.project_id}/edit`}
                            >
                                {params.row.project_name}
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container sx={{ textAlign: 'left', marginBottom: 1 }}>
                        <Grid item xs={2} sx={{ fontWeight: 'bold' }}>
                            Search Filter
                        </Grid>
                        <Grid item xs={10}>
                            <Button
                                variant={'outlined'}
                                endIcon={<OpenInNewIcon />}
                                target={'_blank'}
                                size={'small'}
                                href={`/search-filters/${params.row.search_filter_id}/edit`}
                            >
                                {params.row.search_filter_name}
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container sx={{ textAlign: 'left', marginBottom: 1 }}>
                        <Grid item xs={2} sx={{ fontWeight: 'bold' }}>
                            Status
                        </Grid>
                        <Grid item xs={10}>
                            {ReviewStatusText(status)}
                        </Grid>
                    </Grid>

                    <TextField
                        autoFocus
                        fullWidth
                        label="Comment"
                        margin="normal"
                        name="comment"
                        multiline={true}
                        rows={3}
                        onChange={(ev: any) => setComment(ev.target.value)}
                        type="text"
                        value={comment}
                        variant="outlined"
                    />

                    <Grid container sx={{ textAlign: 'left', marginBottom: 1 }}>
                        <Grid item xs={12}>
                            {canUseSalesforce ? (
                                <>
                                    <FormControlLabel
                                        value={useSalesforce}
                                        control={
                                            <Checkbox
                                                onChange={() => {
                                                    setUseSalesforce(!useSalesforce)
                                                }}
                                            />
                                        }
                                        label={
                                            <>
                                                <Tooltip
                                                    title={
                                                        <React.Fragment>
                                                            In Salesforce, this will create an
                                                            opportunity "{params.row.project_name} -{' '}
                                                            {params.row.search_filter_name}"
                                                        </React.Fragment>
                                                    }
                                                >
                                                    <Stack
                                                        alignItems="center"
                                                        direction="row"
                                                        gap={0}
                                                    >
                                                        <Typography variant="body1">
                                                            Create an opportunity in{' '}
                                                            <b>Salesforce</b>
                                                        </Typography>
                                                        <SalesforceIcon sx={{ mr: 1, ml: 0.5 }} />
                                                    </Stack>
                                                </Tooltip>
                                            </>
                                        }
                                        labelPlacement="end"
                                    />
                                </>
                            ) : (
                                <>
                                    <LinkSalesforceAlert />
                                </>
                            )}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonGroup orientation={'horizontal'} size={'small'} fullWidth>
                        {[
                            ReviewStatus.NeedsReview,
                            ReviewStatus.NotPursuing,
                            ReviewStatus.Pursuing,
                            ReviewStatus.Quoting,
                            ReviewStatus.SentToBidders,
                            ReviewStatus.Won,
                        ]
                            .filter((s) => s !== status)
                            .map((item) => (
                                <Button
                                    key={`${item}-button`}
                                    size="small"
                                    color={getReviewStatusButtonColor(item)}
                                    variant={getReviewStatusButtonVariant(item)}
                                    sx={{ mr: 1 }}
                                    onClick={(ev: any) => {
                                        setStatus(item)
                                        ApiService.reviewProjectSearchFilter(
                                            params.row.project_id,
                                            params.row.search_filter_id,
                                            item,
                                            comment,
                                            useSalesforce,
                                        ).then((res) => {
                                            console.log(res)
                                            setOpen(false)
                                            callback(params)
                                        })
                                    }}
                                >
                                    {ReviewStatusText(item)}
                                </Button>
                            ))}
                    </ButtonGroup>
                </DialogActions>
            </Dialog>
            {/*<Modal*/}
            {/*    aria-labelledby="unstyled-modal-title"*/}
            {/*    aria-describedby="unstyled-modal-description"*/}
            {/*    open={false}*/}
            {/*    onClose={handleClose}*/}
            {/*    disableScrollLock={false}*/}
            {/*    // slots={{ backdrop: StyledBackdrop }}*/}
            {/*>*/}
            {/*    <ModalContent>*/}
            {/*        <Stack sx={{ width: '100%' }} spacing={1}>*/}
            {/*            <Typography component={'div'} variant={'h5'}>*/}
            {/*                {params.row.project_name}*/}
            {/*            </Typography>*/}
            {/*            <Divider></Divider>*/}

            {/*            <Typography sx={{ mb: 2 }} component={'div'}>*/}
            {/*                {status.toString()}*/}
            {/*            </Typography>*/}

            {/*            <JsonData data={params.row}></JsonData>*/}
            {/*            <ButtonGroup orientation={'horizontal'} size={'small'} fullWidth>*/}
            {/*                {[*/}
            {/*                    ReviewStatus.NeedsReview,*/}
            {/*                    ReviewStatus.NotPursuing,*/}
            {/*                    ReviewStatus.Pursuing,*/}
            {/*                    ReviewStatus.Quoting,*/}
            {/*                    ReviewStatus.SentToBidders,*/}
            {/*                    ReviewStatus.Won,*/}
            {/*                ].map((item) => (*/}
            {/*                    <Button*/}
            {/*                        key={`${item}-button`}*/}
            {/*                        size="small"*/}
            {/*                        color={getReviewStatusButtonColor(item)}*/}
            {/*                        variant={getReviewStatusButtonVariant(item)}*/}
            {/*                        sx={{ mr: 1 }}*/}
            {/*                        onClick={(ev: any) => {*/}
            {/*                            ApiService.reviewProjectSearchFilter(*/}
            {/*                                params.row.project_id,*/}
            {/*                                params.row.search_filter_id,*/}
            {/*                                item,*/}
            {/*                                comment,*/}
            {/*                                useSalesforce,*/}
            {/*                            ).then((res) => {*/}
            {/*                                console.log(res)*/}
            {/*                                setOpen(false)*/}
            {/*                                callback(params)*/}
            {/*                            })*/}
            {/*                        }}*/}
            {/*                    >*/}
            {/*                        {ReviewStatusText(item)}*/}
            {/*                    </Button>*/}
            {/*                ))}*/}
            {/*            </ButtonGroup>*/}
            {/*        </Stack>*/}
            {/*    </ModalContent>*/}
            {/*</Modal>*/}
        </Box>
    )
}
