import React, { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

function LogoutForm() {
    const { logout } = useAuth0()

    useEffect(() => {
        logout().then((res) => {
            console.log('logged out')
            console.log(res)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return <></>
}

export default LogoutForm
