import React from 'react'
import Grid from '@mui/material/Grid'
import { CircularProgress } from '@mui/material'

const Loading = () => (
    <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ height: '200px' }}
    >
        <Grid item xs={3}>
            <CircularProgress />
        </Grid>
    </Grid>
)

export default Loading
