import React from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { AutocompleteFieldProps } from './AutocompleteFieldProps'

const AutocompleteField: React.FC<AutocompleteFieldProps> = ({
    options,
    value,
    onChange,
    label,
}) => {
    return (
        <Autocomplete
            options={options}
            getOptionLabel={(option) => option.name}
            value={value}
            onChange={onChange}
            renderInput={(params) => <TextField {...params} label={label} variant="outlined" />}
        />
    )
}

export default AutocompleteField
