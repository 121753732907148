import * as React from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded'
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded'
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded'
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded'
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded'
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded'

const items = [
    {
        icon: <SettingsSuggestRoundedIcon />,
        title: 'Adaptable performance',
        description:
            'Eliminate the redundancy on single-project review and maximize the amount of projects each person can review.',
    },
    {
        icon: <ConstructionRoundedIcon />,
        title: 'Built to last',
        description: 'Identify and act on key projects faster.',
    },
    {
        icon: <ThumbUpAltRoundedIcon />,
        title: 'Great user experience',
        description: 'Project, Team and Company management through the Dashboard.',
    },
    {
        icon: <AutoFixHighRoundedIcon />,
        title: 'Innovative functionality',
        description: 'Key analytics on opportunity coverage and closure.',
    },
    {
        icon: <SupportAgentRoundedIcon />,
        title: 'Reliable support',
        description:
            'Count on our responsive customer support, offering assistance that goes beyond the purchase.',
    },
    {
        icon: <QueryStatsRoundedIcon />,
        title: 'Precision in every detail',
        description: 'Integration into CRM to cut down on administrative time.',
    },
]

export default function Highlights() {
    return (
        <Box
            id="highlights"
            sx={{
                pt: { xs: 4, sm: 12 },
                pb: { xs: 8, sm: 16 },
                color: 'white',
                bgcolor: '#06090a',
            }}
        >
            <Container
                sx={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: { xs: 3, sm: 6 },
                }}
            >
                <Box
                    sx={{
                        width: { sm: '100%', md: '60%' },
                        textAlign: { sm: 'left', md: 'center' },
                    }}
                >
                    <Typography component="h2" variant="h4">
                        Highlights
                    </Typography>
                    <Typography variant="body1" sx={{ color: 'grey.400' }}>
                        Once identified, Construct QuickEST facilitates the quoting and project
                        management processes by simplifying document selection and distribution to
                        relevant parties. Integration with your dashboard and CRM system ensures
                        seamless updates and organization, providing a centralized platform for
                        tracking project statuses and communication. Overall, Construct QuickEST
                        reduces redundancy, accelerates estimation processes, and enables your team
                        to efficiently identify and pursue the most valuable projects.
                    </Typography>
                </Box>
                <Grid container spacing={2.5}>
                    {items.map((item, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Stack
                                direction="column"
                                color="inherit"
                                component={Card}
                                spacing={1}
                                useFlexGap
                                sx={{
                                    p: 3,
                                    height: '100%',
                                    border: '1px solid',
                                    borderColor: 'grey.800',
                                    background: 'transparent',
                                    backgroundColor: 'grey.900',
                                }}
                            >
                                <Box sx={{ opacity: '50%' }}>{item.icon}</Box>
                                <div>
                                    <Typography fontWeight="medium" gutterBottom>
                                        {item.title}
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: 'grey.400' }}>
                                        {item.description}
                                    </Typography>
                                </div>
                            </Stack>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    )
}
