import React, { useEffect, useState } from 'react'
import { Alert, Box, Button, CircularProgress } from '@mui/material'
import { useAuth0 } from '@auth0/auth0-react'
import Loading from '../../utils/Loading'
import { useSearchParams } from 'react-router-dom'
import ApiService from '../../services/api.service'
import SalesforceIcon from '../Shared/Icons/SalesforceIcon'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import { SalesforceLoginActions } from './Salesforce/SalesforceLoginActions'
import LoginWithSalesforceButton from './Salesforce/Buttons/LoginWithSalesforceButton'

function SalesforceLoginForm() {
    const { isLoading, error, isAuthenticated, user } = useAuth0()
    const [searchParams] = useSearchParams()
    const [sfAccount, setSfAccount] = useState<any | undefined>(undefined)
    const [integration, setIntegration] = useState<any | undefined>(undefined)
    const [loadingIntegration, setLoadingIntegration] = useState<boolean>(false)

    async function load() {
        try {
            setLoadingIntegration(true)
            const checkSfIntegration = await ApiService.checkSfIntegration()
            console.log(checkSfIntegration)
            const success = checkSfIntegration.data.success
            if (success) {
                const result = await ApiService.getSfAccount()
                if (result.status === 200 && checkSfIntegration.status === 200) {
                    setIntegration(checkSfIntegration.data)
                    const rowItems = result.data
                    setSfAccount(rowItems)
                    window.localStorage.setItem('has_sf_integration', 'true')
                } else {
                    setSfAccount(null)
                    window.localStorage.removeItem('has_sf_integration')
                }
            } else {
                setSfAccount(null)
                setIntegration(null)
            }
        } catch (e) {
            console.warn(e)
            setSfAccount(null)
            window.localStorage.removeItem('has_sf_integration')
        }
        setLoadingIntegration(false)
    }

    useEffect(() => {
        if (!isLoading && isAuthenticated) {
            if (searchParams.get('state')) {
                searchParams.delete('state')
            }
            if (sfAccount === undefined) {
                load().catch(console.error)
            }
        }
    })

    if (error) {
        return <div>Oops... {error.message}</div>
    }

    if (isLoading) {
        return <Loading />
    }

    return (
        <Box mt={2}>
            {isAuthenticated && user !== null && (
                <Box>
                    {((searchParams.get('state') && searchParams.get('state') === 'success') ||
                        (sfAccount !== undefined && sfAccount != null)) &&
                    integration !== undefined &&
                    integration != null ? (
                        <>
                            <Alert severity="success">
                                <Stack alignItems="center" direction="row" gap={0}>
                                    <Typography variant="body1">
                                        Linked <b>Salesforce</b>{' '}
                                    </Typography>
                                    <SalesforceIcon sx={{ mr: 1, ml: 0.5 }} />
                                    <Typography variant="body1">
                                        {' '}
                                        account <b>{integration['user']['email']}</b>
                                    </Typography>
                                </Stack>
                            </Alert>
                            {sfAccount !== undefined && (
                                <>
                                    <SalesforceLoginActions
                                        subjectId={user?.sub}
                                        showManageIntegration={true}
                                    />
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            {loadingIntegration ? (
                                <>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <CircularProgress />
                                    </div>
                                </>
                            ) : (
                                <>
                                    <LoginWithSalesforceButton />
                                </>
                            )}
                        </>
                    )}
                </Box>
            )}
        </Box>
    )
}

export default SalesforceLoginForm
