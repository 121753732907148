// @flow
import * as React from 'react'
import CreateOrganizationForm from '../../Shared/CreateOrganizationForm'
import { Container } from '@mui/material'
import ListOrganizationsWithUsers from './ListOrganizationsWithUsers'

export const ListOrganizationsPage = () => {
    return (
        <>
            <Container sx={{ mt: 2 }} maxWidth={'xl'}>
                <CreateOrganizationForm
                    onUpdate={function () {
                        console.log('updated org form')
                    }}
                ></CreateOrganizationForm>
                <ListOrganizationsWithUsers />
            </Container>
        </>
    )
}
