import { Box, Divider, Link, Typography } from '@mui/material'
import React from 'react'
import { styled } from '@mui/system'
import { green } from '@mui/material/colors'
import { theme } from '../../../../config/theme/theme'

export const StyledTypography = styled(Typography)(({ theme }) => ({
    '&': {
        color: green,
        // ...and so on
    },
})) as typeof Typography

const bg = theme.palette.primary.main
const HeaderTypography = styled(Typography)({
    color: theme.palette.getContrastText(bg),
    backgroundColor: bg,
    padding: 8,
    borderRadius: 4,
    marginTop: 20,
    marginBottom: 10,
    fontSize: theme.typography.h5.fontSize,
})

export default function PrivacyPolicy() {
    return (
        <Box sx={{ textAlign: 'left' }}>
            <Typography color="textPrimary" variant="body1">
                Effective date: 3/24/2024
            </Typography>
            <Typography color="textPrimary" variant="body1">
                Welcome to Construct QuickEST.
            </Typography>
            <Typography color="textPrimary" variant="body1">
                Your privacy is critically important to us. This privacy policy document outlines
                the types of personal information collected by Construct QuickEST and how it is used
                and protected.
            </Typography>

            <HeaderTypography>1. Information We Collect</HeaderTypography>
            <Typography color="textPrimary" variant="body1">
                At Construct QuickEST, we collect the following types of personal information:
                <ul>
                    <li>Name</li>
                    <li>Phone Number</li>
                    <li>Email Address</li>
                </ul>
            </Typography>

            <HeaderTypography>2. How We Use Your Information</HeaderTypography>

            <Typography color="textPrimary" variant="body1">
                The personal information we collect is used for the following purposes:
                <ul>
                    <li>
                        Customer Service: To provide you with the support and assistance you need.
                    </li>
                    <li>Product-Related Purposes: To enhance your experience with our products.</li>
                </ul>
            </Typography>

            <HeaderTypography>3. How We Protect Your Information</HeaderTypography>

            <Typography color="textPrimary" variant="body1">
                We are committed to protecting the information we collect. We use encryption to
                ensure the security of your personal information.
            </Typography>

            <HeaderTypography>4. Sharing Your Personal Information</HeaderTypography>

            <Typography color="textPrimary" variant="body1">
                Construct QuickEST does not share your personal information with any third parties.
            </Typography>

            <HeaderTypography>5. Your Rights</HeaderTypography>

            <Typography color="textPrimary" variant="body1">
                As a user of Construct QuickEST, you have the right to: Access your personal
                information. Request the deletion of your personal information.
            </Typography>

            <HeaderTypography>6. Cookies and Similar Technologies</HeaderTypography>

            <Typography color="textPrimary" variant="body1">
                Construct QuickEST uses cookies and similar technologies to enhance your experience
                on our website and to understand how our website is used.
            </Typography>

            <HeaderTypography>7. Contacting Us</HeaderTypography>

            <Typography color="textPrimary" variant="body1">
                If you have any questions about this privacy policy or your personal information's
                treatment, you can reach out to us at:
            </Typography>
            <Box>
                Email: <Link>privacy@construct-quick-est.com</Link>
            </Box>
            <Divider sx={{ width: '100%', mb: 2 }}></Divider>

            <Typography color="textPrimary" variant="body1">
                We reserve the right to make changes to this privacy policy at any time. Your
                continued use of Construct QuickEST after any changes to this privacy policy will
                constitute your acceptance of such changes.
            </Typography>
        </Box>
    )
}
