import { lighten, styled } from '@mui/material/styles'
import { DataGridPro } from '@mui/x-data-grid-pro'

const StyledProjectFilterListDataGridPro = styled(DataGridPro)(({ theme }) => ({
    toolbar: {
        showQuickFilter: true,
        csvOptions: { disableToolbarButton: true },
        printOptions: { disableToolbarButton: true },
    },
    '& .level-1': {
        backgroundColor: lighten(theme.palette.primary.main, 0.7),
        '&:hover': {
            backgroundColor: lighten(theme.palette.primary.main, 0.65),
        },
        '& button': {
            color: theme.palette.getContrastText(theme.palette.primary.main),
            backgroundColor: theme.palette.primary.main,
            width: '100%',
            paddingTop: 1,
            paddingBottom: 1,
            fontSize: '1.0em',
            letterSpacing: '0.2px',
            textTransform: 'unset',
        },
    },
    '& .level-2': {
        backgroundColor: lighten(theme.palette.secondary.light, 0.7),
        '&:hover': {
            backgroundColor: lighten(theme.palette.secondary.light, 0.65),
        },
        '& button': {
            color: theme.palette.getContrastText(theme.palette.secondary.light),
            backgroundColor: theme.palette.secondary.light,
            width: '100%',
            paddingTop: 1,
            paddingBottom: 1,
            fontSize: '1.0em',
            letterSpacing: '0.2px',
            textTransform: 'unset',
        },
    },
    '& .level-0': {
        '& button': {
            paddingTop: 0.5,
            paddingBottom: 0.5,
            fontSize: '0.8em',
            letterSpacing: '0.2px',

            // letterSpacing: 0.001,
        },
    },
}))
export default StyledProjectFilterListDataGridPro
