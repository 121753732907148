import * as React from 'react'
import { useEffect, useState } from 'react'
import {
    DataGridPro,
    DataGridProProps,
    GridColDef,
    gridFilteredDescendantCountLookupSelector,
    GridRenderCellParams,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid-pro'
import Box from '@mui/material/Box'
import Button, { ButtonProps } from '@mui/material/Button'
import ApiService from '../../../services/api.service'
import { GridToolbar } from '@mui/x-data-grid'
import useAlert from '../../Alerting/useAlert'
import { Select } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import { Authority } from '../../../services/api/models/authority'

function CustomGridTreeDataGroupingCell(props: GridRenderCellParams) {
    const { id, field, rowNode } = props
    const apiRef = useGridApiContext()
    const filteredDescendantCountLookup = useGridSelector(
        apiRef,
        gridFilteredDescendantCountLookupSelector,
    )
    const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0

    const handleClick: ButtonProps['onClick'] = (event) => {
        if (rowNode.type !== 'group') {
            return
        }

        apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded)
        apiRef.current.setCellFocus(id, field)
        event.stopPropagation()
    }
    let data: any = {}
    if (filteredDescendantCount) {
        data = JSON.parse(JSON.stringify(rowNode))
    }
    return (
        <Box sx={{ ml: rowNode.depth * 4 }}>
            <div>
                {filteredDescendantCount > 0 ? (
                    <Button onClick={handleClick} tabIndex={-1} size="small">
                        {data.groupingKey} ({filteredDescendantCount})
                    </Button>
                ) : (
                    <span />
                )}
            </div>
        </Box>
    )
}

interface Row {
    hierarchy: string[]
    date_updated: Date
    name: any
    authority: any
    delete_org?: any
    user_role: any
    reset_password_user_id: any
    id: any
    subject_id: any
    org_id: any
    org?: any | undefined
}

const getTreeDataPath: DataGridProProps['getTreeDataPath'] = (row) => row.hierarchy

const groupingColDef: DataGridProProps['groupingColDef'] = {
    headerName: 'Hierarchy',
    renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} />,
}
export default function ListOrganizationsWithUsers() {
    const [loading, setLoading] = useState(false)
    const [rows, setRows] = useState<any[]>([])
    const { setAlert } = useAlert()

    const columns: GridColDef<Row>[] = [
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            valueGetter: (value: any, row) => {
                try {
                    const hierarchy = row.hierarchy
                    return hierarchy[hierarchy.length - 1]
                } catch (e) {}
                return ``
            },
        },
        {
            field: 'date_updated',
            headerName: 'Updated At',
            type: 'date',
            flex: 1,
        },
        {
            field: 'user_role',
            headerName: 'Change Role',
            flex: 1.5,
            renderCell: (params) => {
                if (params.rowNode.type === 'group' || params?.value?.length === 0) {
                    return <></>
                }
                let value = params.value

                async function handleChange(e: any) {
                    console.log(e)
                    const role = e.target.value
                    value = e.target.value
                    await ApiService.assignRole(params.row.subject_id, role)
                    load().catch(console.error)
                }

                return (
                    <React.Fragment>
                        <Select
                            labelId="selected-role"
                            size={'small'}
                            value={value}
                            onChange={handleChange}
                            autoWidth
                            label="Role"
                            sx={{ fontSize: 12, padding: 0, margin: 0 }}
                        >
                            {[
                                Authority.Admin,
                                Authority.OrgAdmin,
                                Authority.OrgUser,
                                Authority.Anonymous,
                            ].map((item) => (
                                <MenuItem value={item} key={item}>
                                    {item}
                                </MenuItem>
                            ))}
                        </Select>
                    </React.Fragment>
                )
            },
        },
        {
            field: 'reset_password_user_id',
            headerName: 'Action',
            flex: 1.5,
            // renderCell: DeleteOrg
            renderCell: (params) => {
                if (params.rowNode.type === 'group') {
                    return (
                        <>
                            <Button
                                variant={'contained'}
                                size={'small'}
                                color={'error'}
                                // onClick={async () => {
                                //     const node: any = params.rowNode
                                //     let org = await ApiService.getOrgByName(node.groupingKey)
                                //     ApiService.deleteOrganizationById(org.data.id).then((res) => {
                                //         setAlert(res?.data?.msg, 'success')
                                //         load().catch(console.error)
                                //     })
                                // }}
                            >
                                Delete
                            </Button>
                        </>
                    )
                }
                return (
                    <>
                        <Button
                            variant={'contained'}
                            size={'small'}
                            color={'info'}
                            onClick={() => {
                                ApiService.resetPasswordForUser(params.value).then((res) => {
                                    setAlert(res?.data?.msg, 'success')
                                })
                            }}
                        >
                            Reset Password
                        </Button>
                    </>
                )
            },
        },
    ]

    async function load() {
        if (loading) {
            return
        }
        setLoading(true)
        const result = await ApiService.getAllOrganizations()
        if (result.status === 200) {
            const organizations: any[] = result.data
            const data: Row[] = []
            const ids: any[] = []
            console.log(result)
            for (const org of organizations) {
                let users = org?.users || []
                if (users.length === 0) {
                    const row: Row = {
                        name: org.name,
                        hierarchy: [org.name, org.id],
                        authority: '',
                        user_role: '',
                        subject_id: '',
                        delete_org: org.id,
                        org_id: org.id,
                        org: org,
                        date_updated: new Date(org.date_updated),
                        reset_password_user_id: org.id,
                        id: org.id,
                    }
                    data.push(row)
                }
                for (const user of users) {
                    const row: Row = {
                        name: user.email,
                        hierarchy: [org.name, user.email],
                        authority: user.authority,
                        user_role: user.authority,
                        date_updated: new Date(user.date_updated),
                        reset_password_user_id: user.subject_id,
                        subject_id: user.subject_id,
                        org_id: org.id,
                        id: user.id,
                    }
                    const key = `${org.name}-${user.email}`
                    if (ids.indexOf(key) === -1) {
                        ids.push(key)
                        data.push(row)
                    }
                }
            }
            data.sort((a: any, b: any) => {
                if (a.date_updated < b.date_updated) {
                    return -1
                }
                if (a.date_updated > b.date_updated) {
                    return 1
                }
                return 0
            }).reverse()
            setRows(data)
        }
        setLoading(false)
    }

    useEffect(() => {
        load().catch(console.error)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div style={{ height: 800, width: '100%' }}>
            <DataGridPro
                treeData
                rows={rows}
                columns={columns}
                getTreeDataPath={getTreeDataPath}
                groupingColDef={groupingColDef}
                loading={loading}
                defaultGroupingExpansionDepth={3}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                    },
                }}
            />
        </div>
    )
}
