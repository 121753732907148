import {
    GridApi,
    GridExportMenuItemProps,
    gridFilteredSortedRowIdsSelector,
    gridVisibleColumnFieldsSelector,
    useGridApiContext,
} from '@mui/x-data-grid-pro'
import * as React from 'react'
import { Button } from '@mui/material'

export const exportBlob = (blob: Blob, filename: string) => {
    // Save the blob in a json file
    const url = URL.createObjectURL(blob)

    const a = document.createElement('a')
    a.href = url
    a.download = filename
    a.click()

    setTimeout(() => {
        URL.revokeObjectURL(url)
    })
}

export const getJson = (apiRef: React.MutableRefObject<GridApi>) => {
    // Select rows and columns
    const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef)
    const visibleColumnsField = gridVisibleColumnFieldsSelector(apiRef)

    // Format the data. Here we only keep the value
    const data = filteredSortedRowIds.map((id) => {
        const row: Record<string, any> = {}
        visibleColumnsField.forEach((field) => {
            row[field] = apiRef.current.getCellParams(id, field).value
        })
        return row
    })

    // Stringify with some indentation
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify#parameters
    return JSON.stringify(data, null, 2)
}

export function JsonExportMenuItem(props: GridExportMenuItemProps<{}>) {
    const apiRef = useGridApiContext()

    const { hideMenu } = props

    return (
        <Button
            onClick={() => {
                const jsonString = getJson(apiRef)
                const blob = new Blob([jsonString], {
                    type: 'text/json',
                })
                exportBlob(blob, 'construct-quick-est.json')

                // Hide the export menu after the export
                hideMenu?.()
            }}
        >
            Export JSON
        </Button>
    )
}
