import * as React from 'react'
import { useEffect, useState } from 'react'
import { GridColDef } from '@mui/x-data-grid'
import { DataGridProProps, GridRowsProp } from '@mui/x-data-grid-pro'
import { Box } from '@mui/material'
import ApiService from '../../../services/api.service'
import { ReviewStatus, ReviewStatusFromString } from '../ReviewStatus'
import { GridRowClassNameParams } from '@mui/x-data-grid/models/params'
import StyledProjectFilterListDataGridPro from '../StyledProjectFilterListDataGridPro'
import { useNavigate } from 'react-router-dom'
import useAlert from '../../Alerting/useAlert'
import { MyCustomToolbar } from './Components/MyCustomToolbar'
import { CustomGridTreeDataGroupingCell } from './Components/CustomGridTreeDataGroupingCell'
import { RenderSearchGroupCell } from './Components/RenderSearchGroupCell'
import { RenderReviewActionCell } from './Components/RenderReviewActionCell'
import { RenderSearchTermCell } from './Components/RenderSearchTermCell'

import { unique } from '../../Shared/Unique'
import { RenderReviewsCell } from './Components/RenderReviewsCell'
import { RenderReviewerCell } from './Components/RenderReviewerCell'
import { RenderSearchResultsCell } from './Components/RenderSearchResultsCell'
import Loading from '../../../utils/Loading'
import { GridRowDetailPanelContent } from './Components/GridRowDetailPanelContent'
import { occurrences } from '../../../utils/occurrences'
import { toLocalDate } from '../../Projects/ToLocalDate'
import { withAuthenticationRequired } from '@auth0/auth0-react' // const projectDropdownOnlyOperators: GridFilterOperator<any, string>[] = ;

// const projectDropdownOnlyOperators: GridFilterOperator<any, string>[] = ;

// https://mui.com/material-ui/react-table/#data-table
function ListProjectSearchFilterReviews(props: { projectId?: string | undefined }) {
    let { projectId } = props
    const columns: GridColDef[] = []
    const navigate = useNavigate()

    const [rows, setRows] = React.useState<GridRowsProp>([])
    const [loading, setLoading] = React.useState<boolean>(false)
    const [cols, setCols] = React.useState<GridColDef[]>(columns)
    const { setAlert } = useAlert()
    const [integration, setIntegration] = useState<any | undefined>(undefined)

    async function loadIntegration() {
        try {
            const checkSfIntegration = await ApiService.checkSfIntegration()
            if (checkSfIntegration.status === 200) {
                setIntegration(checkSfIntegration.data)
            } else {
                setIntegration(null)
            }
        } catch (e) {
            setIntegration(null)
        }
    }

    const groupingColDef: DataGridProProps['groupingColDef'] = {
        headerName: 'Project',
        minWidth: 200,
        width: 200,
        maxWidth: 400,
        renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} />,
    }

    async function getNewRows() {
        const searchResultsResponse = await ApiService.getAllSearchResultsV2()
        const projectsResponse = await ApiService.getProjects(['search_filter_reviews'])
        const searchFiltersResponse = await ApiService.getAllSearchFilters([
            'search_filter_reviews',
        ])
        let projectList = projectsResponse.data
        if (projectId !== undefined) {
            projectList = projectsResponse.data.filter((x) => x.id === projectId)
        }
        if (integration === undefined || integration === null) {
        }
        const newRows: any[] = []
        for (const searchFilter of searchFiltersResponse.data) {
            for (const project of projectList) {
                const reviews = project.search_filter_reviews.filter(
                    (x) => x.search_filter_id === searchFilter['id'],
                )
                const search_results = searchResultsResponse.data.results.filter(
                    (x: any) =>
                        x.search_filter_id === searchFilter['id'] && x.project['id'] === project.id,
                )
                if (search_results.length === 0) {
                    continue
                }
                const terms = unique(search_results.map((item: any) => item['term']))
                let review_status = ReviewStatus.NeedsReview
                let reviewer = null
                let review_date: any = ''
                if (reviews !== null && reviews.length > 0) {
                    let lastReview = reviews[reviews.length - 1]
                    review_status = ReviewStatusFromString(lastReview['status'])
                    review_date = lastReview['date_updated']
                    reviewer = lastReview['user_email']
                }
                newRows.push({
                    path: [
                        project.name.toString().replaceAll('/', '').replaceAll('  ', ' '),
                        searchFilter.category.trim(' ').replaceAll('/', '').replaceAll('  ', ' '),
                        searchFilter.name.trim(' ').replaceAll('/', '').replaceAll('  ', ' '),
                    ],
                    id: `${newRows.length}-${project.id}-${searchFilter.id}`,
                    project_name: project.name,
                    review_status: review_status,
                    review_date: review_date,
                    project_id: project.id,
                    review_data: {
                        search_filter_id: searchFilter['id'],
                        search_filter_name: searchFilter['name'],
                        project_name: project.name,
                        project_id: project.id,
                        reviews: reviews,
                        search_results: search_results,
                    },
                    search_filter_id: searchFilter['id'],
                    search_filter_name: searchFilter['name'],
                    search_results: search_results,
                    terms: search_results,
                    term_list: terms,
                    reviews: reviews,
                    reviewer: reviewer,
                })
            }
        }
        return newRows
    }

    async function load() {
        if (loading) {
            return
        }
        setLoading(true)
        const newRows = await getNewRows()
        setCols([
            {
                field: 'id',
                headerName: 'IDX',
                minWidth: 50,
                maxWidth: 50,
                sortable: false,
                flex: 0,
                filterable: false,
            },
            {
                field: 'search_filter_name',
                headerName: 'Search Group',
                minWidth: 150,
                maxWidth: 200,
                flex: 1,
                filterable: false,
                sortable: false,
                renderCell: (params) => (
                    <RenderSearchGroupCell
                        params={params}
                        callback={function callbackNow() {
                            console.log(params)
                            navigate(`/search-filters/${params.row['search_filter_id']}/search`, {})
                        }}
                    />
                ),
            },
            {
                field: 'review_data',
                headerName: 'Review',
                minWidth: 100,
                maxWidth: 130,
                flex: 1,
                sortable: false,
                filterable: false,
                renderCell: (params) => (
                    <RenderReviewActionCell
                        params={params}
                        callback={function callbackNow(params) {
                            setAlert('Updating review status...', 'info')
                            getNewRows().then((rows) => {
                                setRows(rows)
                                setAlert('Updated review status', 'success')
                            })
                        }}
                    />
                ),
            },
            {
                field: 'reviews',
                headerName: 'Review Status',
                minWidth: 100,
                width: 125,
                maxWidth: 250,
                flex: 0.75,
                sortable: true,
                filterable: false,
                renderCell: (params) => <RenderReviewsCell params={params} />,
                // filterOperators: reviewStatusOnlyOperators,
            },
            {
                field: 'review_date',
                headerName: 'Review Date',
                flex: 1,
                sortable: true,
                type: 'string',
                valueFormatter: (params: any) => {
                    if (params === null || params === undefined || params.length === 0) {
                        return ``
                    }
                    try {
                        return toLocalDate(params).toString()
                    } catch (e) {}
                    return ``
                },
            },
            {
                field: 'reviewer',
                headerName: 'Reviewer',
                minWidth: 150,
                width: 200,
                maxWidth: 250,
                flex: 1,
                filterable: false,
                sortable: false,
                renderCell: (params) => <RenderReviewerCell params={params} />,
            },

            {
                field: 'search_results',
                headerName: 'Download',
                minWidth: 160,
                maxWidth: 160,
                flex: 1,
                filterable: false,
                renderCell: (params) => (
                    <RenderSearchResultsCell
                        params={params}
                        callback={function callbackNow() {
                            load()
                        }}
                    />
                ),
            },
            {
                field: 'term_list',
                headerName: 'Terms',
                minWidth: 150,
                width: 200,
                flex: 1,
                filterable: true,
                sortable: true,
                renderCell: (params) => <RenderSearchTermCell params={params} />,
            },
        ])

        setRows(newRows)
        loadIntegration()
        setLoading(false)
    }

    useEffect(() => {
        // noinspection JSIgnoredPromiseFromCall
        // scrollToTop()
        // loadIntegration()
        load()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <Box
            sx={{
                flex: 1,
                margin: '20px',
                overflow: 'hidden',
                maxWidth: '95vw',
            }}
        >
            {loading ? (
                <>
                    <Loading />
                </>
            ) : (
                <>
                    <StyledProjectFilterListDataGridPro
                        treeData
                        rows={rows}
                        columns={cols}
                        defaultGroupingExpansionDepth={3}
                        getDetailPanelContent={({ row }) => <GridRowDetailPanelContent row={row} />}
                        getDetailPanelHeight={({ row }) => 'auto'}
                        getRowClassName={function (params: GridRowClassNameParams) {
                            const times = occurrences(params.id.toString(), '/', true)
                            return `level-${times}`
                        }}
                        density={'compact'}
                        disableRowSelectionOnClick={true}
                        disableColumnReorder={true}
                        getTreeDataPath={(row) => row.path}
                        groupingColDef={groupingColDef}
                        slots={{
                            toolbar: MyCustomToolbar,
                        }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                                csvOptions: { disableToolbarButton: true },
                                printOptions: { disableToolbarButton: true },
                            },
                        }}
                        loading={rows.length === 0 || loading}
                        // rowsLoadingMode={'server'}
                        initialState={{
                            // pinnedColumns: { left: ["__tree_data_group__"] },
                            columns: {
                                columnVisibilityModel: {
                                    id: false,
                                },
                            },
                        }}
                    />
                </>
            )}
        </Box>
    )
}

export default withAuthenticationRequired(ListProjectSearchFilterReviews)
