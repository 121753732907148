import React from 'react'
import SalesforceIcon from '../../../Shared/Icons/SalesforceIcon'
import { Config } from '../../../../config/config'
import { Button } from '@mui/material'
import { SalesforceUserInfoProps } from './SalesforceUserInfoProps'

function RefreshSalesforceLoginButton(
    props: SalesforceUserInfoProps = {
        subjectId: undefined,
    },
) {
    return (
        <Button
            variant={'outlined'}
            color={'info'}
            endIcon={<SalesforceIcon />}
            onClick={() => {
                if (props.subjectId) {
                    window.location.href = `${Config.ApiUrl}/api/salesforce/login?prompt=select_account&sub=${props.subjectId}&redirect_uri=${window.location.origin + window.location.pathname}`
                } else {
                    window.location.href = `${Config.ApiUrl}/api/salesforce/login?prompt=select_account&redirect_uri=${window.location.origin + window.location.pathname}`
                }
            }}
        >
            Refresh Login
        </Button>
    )
}

export default RefreshSalesforceLoginButton
